import { Button, Modal, Tab, Nav } from 'react-bootstrap';
import MarketSettingForm from './MarketSettingForm';
import { authServices } from 'Utils/auth/services';
import { useEffect, useState, useSyncExternalStore } from 'react';
import snackbarUtil from 'Utils/snackBarUtil';
import axios from 'axios';

interface UserMarketLimitProps {
    show: any;
    clickHandler: any;
    userIds: string;
    settingData: string;
    userName: string
}

interface SportSetting {
    sport_id: string;
    name: string;
    settings: any;
}

interface SportSettingsByName {
    [key: string]: SportSetting;
}

const UserMarketLimit = ({ show, clickHandler, userIds, settingData, userName }: UserMarketLimitProps) => {
    const [combineData, setCombineData] = useState<SportSettingsByName>({});
    const [combineDataParent, setCombineDataParent] = useState<SportSettingsByName>({});
    const [validationError, setValidationError] = useState<any>();
    const [tooltipMsg, setTooltipMsg] = useState<{ [key: string]: string }>({});
    const [showTooltip, setShowTooltip] = useState<{ [key: string]: boolean }>({});
    const [formValues, setFormValues] = useState<string>("Cricket");
    const [errors, setErrors] = useState<any>({});
    const [formData, setFormData] = useState<any>({
        market_advance_bet_stake: "",
        market_bet_delay: "",
        market_bookmaker_max_odds_rate: "",
        market_bookmaker_min_odds_rate: "",
        market_max_profit: "",
        market_max_stack: "",
        market_min_odds_rate: "",
        market_min_stack: "",
        session_bet_delay: "",
        session_max_profit: "",
        session_max_stack: "",
        session_min_stack: "",
        market_max_odds_rate: ""
    });

    const desiredOrder = [
        "Cricket", "Soccer", "Tennis", "Horse Racing",
        "Greyhound Racing", "Share Market", "Casino", "D Casino"
    ];


    const getUserLimit = async () => {
        try {
            const { response } = await authServices.getUserLimits({
                _id: settingData,
                user_id: userIds,
            });
            const groupedSports = getSportsByNames(response?.data?.sports_settings || []);
            const parentGroup = getSportsByNames(response?.data?.parent_sports_settings || []);
            setCombineData(groupedSports);
            setCombineDataParent(parentGroup);


        } catch (error) {
            console.error('Error fetching user limits:', error);
        }
    };
    useEffect(() => {
        if (show) {
            getUserLimit();
        }
    }, [userIds, settingData, show]);


    function getSportsByNames(sportsSettings: SportSetting[]): SportSettingsByName {
        const sortedSportsSettings = sportsSettings?.sort((a, b) => {
            return desiredOrder.indexOf(a.name) - desiredOrder.indexOf(b.name);
        });

        return sortedSportsSettings?.reduce((acc: SportSettingsByName, setting: SportSetting) => {
            acc[setting.name] = setting;
            return acc;
        }, {});

    }

    const handleClickSportName = (name: string) => {
        setFormValues(name);
        setErrors({});
        setTooltipMsg({});
        setShowTooltip({});
    }

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        const numericValue = value === "" ? "" : Number(value);

        const updatedFormData = { ...formData, [name]: numericValue };
        const updatedErrors = { ...errors };
        const newTooltipMsg = { ...tooltipMsg };
        const newShowTooltip = { ...showTooltip };

        const addError = (message: string) => updatedErrors[name] = message;
        const addTooltip = (message: string) => {
            newTooltipMsg[name] = message;
            newShowTooltip[name] = true;
        };

        if (value === "") {
            addError("Value must be greater than 0.");
        } else {
            updatedErrors[name] = "";
        }

        const checks = [
            {
                condition: name === "market_min_stack" && updatedFormData?.market_max_stack && numericValue > updatedFormData?.market_max_stack,
                errorMsg: "Min. Stake cannot be greater than Max. Stake."
            },
            {
                condition: name === "market_max_stack" && updatedFormData?.market_min_stack && numericValue < updatedFormData?.market_min_stack,
                errorMsg: "Max. Stake cannot be less than Min. Stake."
            },
            {
                condition: name === "market_min_odds_rate" && updatedFormData?.market_max_odds_rate && numericValue > updatedFormData?.market_max_odds_rate,
                errorMsg: "Min. Odds Rate cannot be greater than Max. Odds Rate."
            },
            {
                condition: name === "market_max_odds_rate" && updatedFormData?.market_min_odds_rate && numericValue < updatedFormData?.market_min_odds_rate,
                errorMsg: "Max. Odds Rate cannot be less than Min. Odds Rate."
            },
            {
                condition: name === "market_bookmaker_min_odds_rate" && updatedFormData?.market_bookmaker_max_odds_rate && numericValue > updatedFormData?.market_bookmaker_max_odds_rate,
                errorMsg: "Bookmaker Min. Odds Rate cannot be greater than Bookmaker Max. Odds Rate."
            },
            {
                condition: name === "session_min_stack" && updatedFormData?.session_max_stack && numericValue > updatedFormData?.session_max_stack,
                errorMsg: "Session Min. Stack cannot be greater than Session Max. Stack."
            },
            {
                condition: name === "session_max_stack" && updatedFormData?.session_min_stack && numericValue < updatedFormData?.session_min_stack,
                errorMsg: "Session Min. Stack cannot be greater than Session Max. Stack."
            }
        ];

        const parentChecks = [
            {
                condition: name === "market_min_stack" && numericValue < updatedFormData?.market_min_stack,
                tooltipMsg: `Market Min Stack value should not be less than parent value (${updatedFormData?.market_min_stack})`
            },
            {
                condition: name === "market_max_stack" && numericValue > updatedFormData?.market_max_stack,
                tooltipMsg: `Market Max Stack value should not be greater than parent value (${updatedFormData?.market_max_stack})`
            },
            {
                condition: name === "market_max_odds_rate" && numericValue > updatedFormData?.market_max_odds_rate,
                tooltipMsg: `Market Max Odds Rate value should not be greater than parent value (${updatedFormData?.market_max_odds_rate})`
            },
            {
                condition: name === "market_bookmaker_min_odds_rate" && Number(numericValue) < 2,
                tooltipMsg: `Market Bookmaker Min Odds Rate should be between (1-100)`
            },
            {
                condition: name === "market_bookmaker_max_odds_rate" && Number(numericValue) > 100,
                tooltipMsg: "Market Bookmaker Max Odds Rate should not exceed 100"
            },
            {
                condition: name === "market_max_profit" && numericValue > updatedFormData?.market_max_profit,
                tooltipMsg: `Market Max Profit value should not be greater than parent value (${updatedFormData?.market_max_profit})`
            },
            {
                condition: name === "session_max_stack" && numericValue > updatedFormData?.session_max_stack,
                tooltipMsg: `Session Max Stack value should not exceed parent value (${updatedFormData?.session_max_stack})`
            },
            {
                condition: name === "session_max_profit" && numericValue > updatedFormData?.session_max_profit,
                tooltipMsg: `Session Max Profit value should not exceed parent value (${updatedFormData?.session_max_profit})`
            },
            {
                condition: name === "market_bet_delay" && Number(numericValue) > 10,
                tooltipMsg: "Match Bets Delay should not exceed 10"
            },
            {
                condition: name === "session_bet_delay" && Number(numericValue) > 10,
                tooltipMsg: "Session Bets Delay should not exceed 10"
            }
        ];

        checks.forEach(({ condition, errorMsg }) => {
            if (condition) addError(errorMsg);
        });

        parentChecks.forEach(({ condition, tooltipMsg }) => {
            if (condition) addTooltip(tooltipMsg);
        });

        if (!(checks.some(({ condition }) => condition) || parentChecks.some(({ condition }) => condition))) {
            newTooltipMsg[name] = "";
            newShowTooltip[name] = false;
        }

        setFormData(updatedFormData);
        setErrors(updatedErrors);
        setTooltipMsg(newTooltipMsg);
        setShowTooltip(newShowTooltip);
    };

    const handleBlur = (name: string) => {
        const hasTooltip = Object.keys(tooltipMsg).length > 0;
        setShowTooltip((prev) => ({
            ...prev,
            [name]: hasTooltip && !!tooltipMsg[name],
        }));
    };

    const validateForm = () => {
        const newErrors: any = {};

        const keysToValidate = formValues === "Cricket"
            ? Object.keys(formData)
            : Object.keys(formData).filter(key => !key.startsWith("session"));

        keysToValidate.forEach((key) => {
            if (formData[key] === "" || formData[key] <= 0) {
                newErrors[key] = "Please provide a valid value.";
            }
        });

        setErrors(newErrors);

        return Object.keys(newErrors).length === 0;
    };
    const token  = localStorage.getItem("token");

    const getUserSettingUpdate = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_BASEURL}/userSettings/update`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify({
                    user_id: userIds,
                    sport_id: combineData[formValues]?.sport_id,
                    sports_settings: [
                        {
                            ...(formValues !== "Cricket" ? {
                                market_advance_bet_stake: formData?.market_advance_bet_stake,
                                market_bet_delay: formData?.market_bet_delay,
                                market_max_odds_rate: formData?.market_max_odds_rate,
                                market_max_profit: formData?.market_max_profit,
                                market_max_stack: formData?.market_max_stack,
                                market_min_odds_rate: formData?.market_min_odds_rate,
                                market_min_stack: formData?.market_min_stack,
                            } : formData)
                        }
                    ]
                })
            });
    
            const data = await response.json();    
            if (data.is_validation_error) {
                const newTooltipMsg: { [key: string]: string } = {};
                Object.keys(data.data).forEach((field: string) => {
                    const shortError = (data.data as { [key: string]: { short_error: string }[] })[field]?.[0]?.short_error;
                    if (shortError) {
                        newTooltipMsg[field] = `${field.split("_").join(" ")}  ${shortError}`;
                    }
                    setShowTooltip((prev) => ({
                        ...prev,
                        [field]: true,
                    }));
                });
                setTooltipMsg(newTooltipMsg);
            }
    
            if (data?.status) {
                snackbarUtil.success(data?.msg);
                clickHandler();
            }
        } catch (error) {
            console.error("Error updating user settings:", error);
        }
    };

    const handleSave = () => {
        if (validateForm()) {
            getUserSettingUpdate();

        }
    };

    const handleClose = () => {
       
        setErrors({});
        setTooltipMsg({});
        setShowTooltip({});
        clickHandler(); 
    };

    return (
        <Modal size='lg' show={show} onHide={handleClose} className="zoomIn" scrollable>
            <Modal.Header className="modal-title fw-bold d-flex justify-content-between pb-1">
                <span>User Sports Settings ({userName})</span>
                <Button variant="light btn-sm" onClick={handleClose}>
                    <i className="ri-close-line fs-xl align-middle"></i>
                </Button>
            </Modal.Header>
            <Modal.Body className='pt-2 fs-md'>
                <Tab.Container defaultActiveKey="Cricket">
                    <Nav as="ul" variant="pills" className="arrow-navtabs nav-success bg-light mb-3">
                        {
                            combineData && Object.keys(combineData)?.map((key) => (
                                <Nav.Item as="li" key={key} onClick={() => handleClickSportName(key)}>
                                    <Nav.Link eventKey={key}>{key}</Nav.Link>
                                </Nav.Item>
                            ))
                        }
                    </Nav>
                    <Tab.Content className="text-muted">

                        <Tab.Pane eventKey={formValues} key={formValues}>
                            <MarketSettingForm
                                sportName={formValues}
                                combineData={combineData[formValues]}
                                setFormData={setFormData}
                                formData={formData}
                                errors={errors}
                                validationError={validationError}
                                tooltipMsg={tooltipMsg}
                                showTooltip={showTooltip}
                                handleInputChange={handleInputChange}
                                handleBlur={handleBlur}
                            />
                        </Tab.Pane>

                    </Tab.Content>
                </Tab.Container>
            </Modal.Body>
            <Modal.Footer className='border-top'>
                <Button onClick={handleSave}>Update</Button>
                <Button variant='light' onClick={handleClose}>Cancel</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default UserMarketLimit;
