import { useSnackbar, VariantType, ProviderContext } from "notistack";
import * as React from 'react';

interface InnerSnackbarProps {
    setUseSnackbarRef: (showSnackbar: ProviderContext) => void;
}
 
export const InnerSnackbarUtilsConfigurator = () => {
    const snackbar = useSnackbar();
    
    React.useEffect(() => {
        setUseSnackbarRef(snackbar);
    }, [snackbar, setUseSnackbarRef]);

    return null;
};

let useSnackbarRef: ProviderContext | undefined;

const setUseSnackbarRef = (useSnackbarRefProp: ProviderContext) => {
    useSnackbarRef = useSnackbarRefProp;
};



const snackbarUtil = {
    success(msg: string | any) {
        this.toast(msg, "success");
    },
    warning(msg: string | any) {
        this.toast(msg, "warning");
    },
    info(msg: string | any) {
        this.toast(msg, "info");
    },
    error(msg: string | any) {
        this.toast(msg, "error");
    },
    toast(msg: string | any, variant: VariantType = "default") {
        useSnackbarRef?.enqueueSnackbar(msg, { variant });
    },
};

export default snackbarUtil;
