import Loader from "Common/Loader";
import { useEffect, useState } from "react";
import { Alert, Button, Col, Form, Modal, Row } from "react-bootstrap";
import { authServices } from "Utils/auth/services";
import snackbarUtil from "Utils/snackBarUtil";

interface RedisFlushModalProps {
  show: boolean;
  clickHandler: () => void;
}

const RedisFlushModal = ({ show, clickHandler }: RedisFlushModalProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const updateStackStatus = async () => {
    setIsLoading(true);
    const { response } = await authServices.updateRedishFlush();
    setIsLoading(false);
    if (response.status) {
      clickHandler();
      snackbarUtil.success(response?.msg);
    }  else {
      const errorMessage =
        response?.msg || "An error occurred. Please try again.";
      snackbarUtil.error(errorMessage);
    }
  };

  const handleSubmit = () => {
    updateStackStatus();
  };

  return (
    <Modal
      size="lg"
      show={show}
      onHide={clickHandler}
      className="zoomIn"
      scrollable
    >
     <Alert
            variant="danger"
            onClose={clickHandler}
            dismissible
            className="mb-0"
          >
            <Alert.Heading>Confirm!</Alert.Heading>
            <p>Are you sure you want to Flush Redis?</p>
            <hr />
            <div className="d-flex justify-content-end">
              <Button onClick={() => handleSubmit()} variant="success">
                Ok
              </Button>
              <Button onClick={clickHandler} variant="danger">
                Cancel
              </Button>
            </div>
          </Alert>
          {isLoading && <Loader />}
    </Modal>
  );
};

export default RedisFlushModal;
