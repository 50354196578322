import React, { FC, useEffect, useMemo, useState } from "react";
import SearchOption from "../Common/SearchOption";
import FullScreenDropdown from "../Common/FullScreenDropdown";
import ProfileDropdown from "../Common/ProfileDropdown";
import { Button, Modal, Tab, Nav, Table, Form } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";

// Import images
import logosm from "../assets/images/logo-sm.png";
import logodark from "../assets/images/logo-dark.png";
import logolight from "../assets/images/logo-light.png";

import { authServices } from "Utils/auth/services";
import News from "Common/News";
import NotificationDropdown from "Common/NotificationDropdown";
import snackbarUtil from "Utils/snackBarUtil";

const toogleMenuBtn = () => {
  const windowSize = document.documentElement.clientWidth;
  const layout = document.documentElement.getAttribute("data-layout");
  const sidebarSize =
    document.documentElement.getAttribute("data-sidebar-size");

  if (windowSize > 767)
    document.querySelector(".hamburger-icon")?.classList.toggle("open");

  if (layout === "horizontal") {
    document.body.classList.toggle("menu");
  }

  if (layout === "vertical") {
    if (windowSize < 1025 && windowSize > 767) {
      document.body.classList.remove("vertical-sidebar-enable");
      document.documentElement.setAttribute(
        "data-sidebar-size",
        sidebarSize === "sm" ? "" : "sm"
      );
    } else if (windowSize > 1025) {
      document.body.classList.remove("vertical-sidebar-enable");
      document.documentElement.setAttribute(
        "data-sidebar-size",
        sidebarSize === "lg" ? "sm" : "lg"
      );
    } else if (windowSize <= 767) {
      document.body.classList.add("vertical-sidebar-enable");
      document.documentElement.setAttribute("data-sidebar-size", "lg");
    }
  }

  if (layout === "twocolumn") {
    document.body.classList.toggle("twocolumn-panel");
  }
};

interface Props {
  logo: string;
}

const Header: FC<Props> = ({ logo }) => {
  const [showExposure, setExposure] = useState(false);
  const [sportsData, setSportsData] = useState<SportData[]>([]);
  const [casinoData, setCasinoData] = useState<any[]>([]);
  const [balance, setBalance] = useState<number | null>(null);
  const [exp, setExp] = useState<number | null>(null);
  const [fullExp, setFullExc] = useState<boolean>(true);

  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const updateNetworkStatus = () => {
    setIsOnline(navigator.onLine);
  };

  useEffect(() => {
    window.addEventListener("online", updateNetworkStatus);
    window.addEventListener("offline", updateNetworkStatus);
    return () => {
      window.removeEventListener("online", updateNetworkStatus);
      window.removeEventListener("offline", updateNetworkStatus);
    };
  }, []);

  useEffect(() => {
    let offlineNotificationInterval: any;

    if (!isOnline) {
      snackbarUtil.error("You are offline.");

      offlineNotificationInterval = setInterval(() => {
        snackbarUtil.error("You are still offline.");
      }, 5000);
    } else {
      clearInterval(offlineNotificationInterval);
    }
    return () => clearInterval(offlineNotificationInterval);
  }, [isOnline]);

  const user_id = localStorage.getItem("userId");

  const toggleExposureModal = () => setExposure(!showExposure);

  const fetchExposureSport = async () => {
    if (!user_id) return;
    try {
      const { response } = await authServices.getExposureSport({ user_id });
      const filteredData = response.data.filter(
        (item: SportData) => !("liabilitySum" in item)
      );
      setSportsData(filteredData);
    } catch (error) {
      console.error("Error fetching exposure sport data:", error);
    }
  };
  const fetchExposureSportV1 = async () => {
    if (!user_id) return;
    try {
      const { response } = await authServices.getExposureSportV1({ user_id });
      const filteredData = response.data.filter(
        (item: SportData) => !("liabilitySum" in item)
      );
      setSportsData(filteredData);
    } catch (error) {
      console.error("Error fetching exposure sport data:", error);
    }
  };

  const fetchBalance = async () => {
    if (!user_id) return;
    try {
      const { response } = await authServices.showBalance({
        userid: user_id,
        full_exposure: fullExp,
      });
      setBalance(response.data.balance);
      setExp(response.data.liability);
    } catch (error) {
      console.error("Error fetching balance data:", error);
    }
  };

  const fetchExposureCasino = async () => {
    if (!user_id) return;
    try {
      const { response } = await authServices.getExposureCasino({ user_id });
      setCasinoData(response.data);
    } catch (error) {
      console.error("Error fetching exposure casino data:", error);
    }
  };

  useEffect(() => {
    if (showExposure) {
      if (fullExp) {
        fetchExposureSport();
      } else {
        fetchExposureSportV1();
      }
    }
  }, [showExposure, fullExp]);

  useEffect(() => {
    fetchBalance();
    const interval = setInterval(() => {
      fetchBalance();
    }, 2000); 
  
    return () => {
      clearInterval(interval); 
    };
  }, [user_id, fullExp]);

  const nav = useNavigate();

  const adminDetails = localStorage.getItem("adminDetails");
  const username = adminDetails && JSON.parse(adminDetails || "")?.user_name;

  useEffect(() => {
    const fetchValidToken = async () => {
      try {
        const { response } = await authServices.validToken();

        if (!response?.status) {
          localStorage.removeItem("token");
          nav("/login");
          clearInterval(intervalId);
        }
      } catch (error) {
        console.error("Error fetching exposure casino data:", error);
      }
    };
    const intervalId = setInterval(fetchValidToken, 1000);
    return () => clearInterval(intervalId);
  }, []);
  const handleRoute = (
    matchid: string,
    sport_id: string,
    matchName: string,
    isActive: number
  ) => {
    nav(`/match-detail/${matchid}/${sport_id}`, { state: matchName });
    localStorage.setItem("isAct", String(isActive));
    toggleExposureModal();
  };
  const renderTableRows = (data: SportData[]) =>
    data.map((item, index) => (
      <tr key={index}>
        <td>{index + 1}</td>
        <td>{item.sport_name}</td>
        <td
          className="cursor-pointer"
          onClick={() =>
            handleRoute(item?.match_id, item?.sport_id, item?.match_name, 0)
          }
        >
          {item.match_name}
        </td>
        <td>{item.event_name}</td>
        <td style={{ color: item.liability < 0 ? "red" : "black" }}>
          {Number(item.liability)?.toFixed(2)}
        </td>
      </tr>
    ));

  const totals = useMemo(() => {
    return sportsData?.reduce(
      (acc, user) => {
        acc.liability += user.liability;
        return acc;
      },
      { liability: 0 }
    );
  }, [sportsData]);

  const totalsCasino = useMemo(() => {
    return casinoData?.reduce(
      (acc, user) => {
        acc.calculateExposure += user.calculateExposure;
        return acc;
      },
      { calculateExposure: 0 }
    );
  }, [casinoData]);

  return (
    <React.Fragment>
      <header id="page-topbar">
        <div className="layout-width">
          <div className="navbar-header">
            <div className="d-flex">
              <div className="navbar-brand-box horizontal-logo">
                <Link to="/" className="logo logo-dark">
                  <span className="logo-sm">
                    <img src={logo} alt="" height="22" />
                  </span>
                  <span className="logo-lg">
                    <img src={logo} alt="" height="25" />
                  </span>
                </Link>
                <Link to="/" className="logo logo-light">
                  <span className="logo-sm">
                    <img src={logosm} alt="123" height="22" />
                  </span>
                  <span className="logo-lg">
                    <img src={logo} alt="" height="25" />
                  </span>
                </Link>
              </div>

              <Button
                variant="link"
                size="sm"
                className="px-3 fs-lg header-item vertical-menu-btn topnav-hamburger shadow-none"
                onClick={toogleMenuBtn}
              >
                <span className="hamburger-icon">
                  <span></span>
                  <span></span>
                  <span></span>
                </span>
              </Button>

              <SearchOption />
            </div>
            <News />
            <div className="d-flex align-items-center">
              <div className="balanceBtns me-2 d-flex align-items-center">
                <Button
                  variant="subtle-dark mx-1"
                  className="btn-label d-none d-sm-block"
                >
                  <i className="ri-wallet-3-line label-icon align-middle fs-lg"></i>{" "}
                  {Number(balance)?.toFixed(2)}
                </Button>
                <Button
                  variant="subtle-danger mx-1 d-flex"
                  className="btn-label d-none d-sm-block"
                  onClick={toggleExposureModal}
                >
                  <i className="ri-increase-decrease-line label-icon align-middle fs-lg"></i>{" "}
                  {exp?.toFixed(2)}
                </Button>
                <div className="d-sm-none text-end">
                  <div className="bg-light px-1 py-1 rounded mb-1 fs-md">
                    {balance?.toFixed(2)}
                  </div>
                  <div className="d-flex align-items-center justify-content-end">
                    <div
                      className="text-danger fs-md me-2"
                      onClick={toggleExposureModal}
                    >
                      {Number(exp)?.toFixed(2)}
                    </div>
                    <Form.Check
                      checked={fullExp}
                      onChange={(e) => setFullExc(e.target.checked)}
                      type="checkbox"
                      role="switch"
                      className="d-sm-none"
                      id="tableOnOff"
                    />
                  </div>
                </div>
                <Form.Check
                  checked={fullExp}
                  onChange={(e) => setFullExc(e.target.checked)}
                  type="checkbox"
                  role="switch"
                  className="ms-2 d-none d-sm-block"
                  id="tableOnOff"
                />
                {/* <Button variant="subtle-success mx-1" className="btn-label">
                                    <i className="ri-arrow-up-down-line label-icon align-middle fs-lg"></i> 979,071.76
                                </Button> */}
              </div>

              <NotificationDropdown />
              <FullScreenDropdown />
              <ProfileDropdown />
            </div>
          </div>
        </div>
        <Modal
          size="lg"
          show={showExposure}
          onHide={toggleExposureModal}
          className="zoomIn"
          scrollable
        >
          <Modal.Header className="modal-title fw-bold d-flex justify-content-between">
            <div className="d-flex">
              <span>Exposure of {username}</span>
              <Form.Check
                checked={fullExp}
                onChange={(e) => setFullExc(e.target.checked)}
                type="checkbox"
                role="switch"
                className="ms-3"
                id="tableOnOff"
              />
            </div>

            <Button variant="light btn-sm" onClick={toggleExposureModal}>
              <i className="ri-close-line fs-xl align-middle"></i>
            </Button>
          </Modal.Header>
          <Modal.Body className="pt-1">
            <Tab.Container defaultActiveKey="sports">
              <Nav
                as="ul"
                variant="tabs"
                className="nav-tabs-custom nav-primary nav-justified mb-3"
              >
                <Nav.Item as="li">
                  <Nav.Link eventKey="sports" onClick={fetchExposureSport}>
                    Sports
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item as="li">
                  <Nav.Link eventKey="casino" onClick={fetchExposureCasino}>
                    Casino
                  </Nav.Link>
                </Nav.Item>
              </Nav>
              <Tab.Content className="text-muted">
                <Tab.Pane eventKey="sports">
                  {sportsData?.length === 0 ? (
                    <div
                      style={{
                        fontSize: "14",
                        textAlign: "center",
                      }}
                    >
                      No Data Found
                    </div>
                  ) : (
                    <Table className="table-striped table-nowrap align-middle mb-0">
                      <thead>
                        <tr>
                          <th>S. No.</th>
                          <th>Sport Name</th>
                          <th>Event Name</th>
                          <th>Market</th>
                          <th>Exposure</th>
                        </tr>
                      </thead>
                      <tbody>
                        {renderTableRows(sportsData)}
                        <tr>
                          <td colSpan={4} className="fw-bolder text-center">
                            Total
                          </td>
                          <td
                            className={`fw-bolder ${
                              totals?.liability < 0
                                ? "text-danger"
                                : "text-secondary"
                            }`}
                          >
                            {totals?.liability?.toFixed(2)}
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  )}
                </Tab.Pane>
                <Tab.Pane eventKey="casino">
                  {casinoData?.length === 0 ? (
                    <div
                      style={{
                        fontSize: "14",
                        textAlign: "center",
                      }}
                    >
                      No Data Found
                    </div>
                  ) : (
                    <Table className="table-striped table-nowrap align-middle mb-0">
                      <thead>
                        <tr>
                          <th>S. No.</th>
                          <th>Sport Name</th>
                          <th>Event Name</th>
                          <th>Market</th>
                          <th>Exposure</th>
                        </tr>
                      </thead>
                      <tbody>
                        {casinoData?.map((casino, id: number) => {
                          return (
                            <tr key={id}>
                              <td>{id + 1}</td>
                              <td>{casino?.matchName}</td>
                              <td>{casino?.marketName}</td>
                              <td>{casino?.marketType}</td>
                              <td>
                                <span
                                  className={
                                    casino?.calculateExposure < 0
                                      ? "text-danger"
                                      : "text-secondary"
                                  }
                                >
                                  {casino?.calculateExposure}
                                </span>
                              </td>
                            </tr>
                          );
                        })}
                        <tr>
                          <td colSpan={4} className="fw-bolder text-center">
                            Total
                          </td>
                          <td
                            className={`fw-bolder ${
                              totalsCasino?.calculateExposure < 0
                                ? "text-danger"
                                : "text-secondary"
                            }`}
                          >
                            {totalsCasino?.calculateExposure?.toFixed(2)}
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  )}
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </Modal.Body>
        </Modal>
      </header>
    </React.Fragment>
  );
};

export default Header;
