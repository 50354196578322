import Loader from "Common/Loader";
import HistoryModal from "Common/Modals/HistoryModal";
import { useState } from "react";
import { Button, Form, Modal, Table } from "react-bootstrap";
import { authServices } from "Utils/auth/services";
import snackbarUtil from "Utils/snackBarUtil";

interface ChipSummaryTableProps {
  type: string;
  chipSummaryData: ChipSummaryData | null;
  getChipSummaryList: (value?: { user_id?: string }) => void;
}

const ChipSummaryTable = ({
  type,
  chipSummaryData,
  getChipSummaryList,
}: ChipSummaryTableProps) => {
  const currentUserId = localStorage.getItem("userId");

  const [showList, setList] = useState(false);
  const [showHistory, setHistory] = useState(false);
  const [showSettlement, setSettlement] = useState(false);
  const [chipHistoryData, setChipHistoryData] = useState<ChipHistoryData[]>([]);
  const [chipHistoryText, setChipHistoryText] = useState<string>("");
  const [userId, setUserId] = useState<string>("");
  const [selectedUser, setSelectedUser] = useState<string>("");
  const [agentList, setAgentList] = useState<CasinoUserAgent[]>([]);
  const [passwordShow, setPasswordShow] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [formData, setFormData] = useState({
    amount: "",
    remark: "",
    password: "",
  });
  const [formErrors, setFormErrors] = useState({
    amount: "",
    remark: "",
    password: "",
  });
  const [touchedFields, setTouchedFields] = useState({
    amount: false,
    remark: false,
    password: false,
  });

  const userDetails = localStorage.getItem("adminDetails");
  const userPassword = userDetails && JSON.parse(userDetails)?.password;

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    setTouchedFields((prevState) => ({
      ...prevState,
      [name]: true,
    }));

    validateForm({ ...formData, [name]: value });
  };

  const validateForm = (data = formData) => {
    let isValid = true;
    const errors: any = {};

    if (
      !data.amount ||
      isNaN(Number(data.amount)) ||
      Number(data.amount) <= 0
    ) {
      errors.amount = "Amount is required.";
      isValid = false;
    } else {
      errors.amount = "";
    }

    if (!data.remark.trim()) {
      errors.remark = "Remark is required";
      isValid = false;
    } else {
      errors.remark = "";
    }

    if (!data.password) {
      errors.password = "Password is required";
      isValid = false;
    } else if (data.password != userPassword) {
      errors.password = "Password didn't match";
      isValid = false;
    } else {
      errors.password = "";
    }

    setFormErrors(errors);
    return isValid;
  };

  const toggleList = (name?: string) => {
    setAgentList([]);
    setList(!showList);
    setSelectedUser(name || "");
  };
  const toggleHistory = () => {
    setChipHistoryData([]);
    setHistory(!showHistory);
  };
  const toggleSettlement = (chip: string, userId?: string, name?:string) => {
    setSelectedUser(name || "");
    setFormData((prevState) => ({
      ...prevState,
      amount: chip,
    }));
    setUserId(userId || "");
    setSettlement(!showSettlement);
  };

  const getChipSummaryHistory = async (userId: string, name:string) => {
    setSelectedUser(name);
    const { response } = await authServices.getChipSummaryHistory({
      user_id: userId,
    });
    if (response?.data) {
      setChipHistoryData(response?.data);
    }
    setChipHistoryText(response?.msg);
  };

  const getChipSettlement = async () => {
    setIsLoading(true);
    const { response } = await authServices.getChipSettlement({
      user_id: userId,
      type: type === "success" ? "1" : "2",
      amount: formData.amount,
      comment: formData.remark,
    });
    setIsLoading(false);
    if (response?.status) {
      getChipSummaryList({});
      setSettlement(false);
      snackbarUtil.success(response.msg);
    } else {
      snackbarUtil.error(
        response?.msg || "An error occurred. Please try again."
      );
    }
  };

  const handleSubmit = () => {
    if (validateForm()) {
      getChipSettlement();
    } else {
      setTouchedFields({
        amount: true,
        remark: true,
        password: true,
      });
    }
  };

  const getUserAgent = async (userId: string) => {
    const { response } = await authServices.getShowAgent({
      user_id: userId,
    });
    if (response?.data) {
      setAgentList(response?.data?.agents);
    }
  };

  return (
    <>
      <div className="table-responsive">
        <Table className="table-centered align-middle table-nowrap table-striped mb-0">
          <thead className="table-light">
            <tr>
              <th>Name</th>
              <th>Account</th>
              <th>Chips</th>
              <th>
                {chipSummaryData?.parent_id && chipSummaryData?.user_id != currentUserId && (
                  <Button
                    variant={`subtle-primary`}
                    className="btn-sm"
                    onClick={() => {
                      chipSummaryData?.parent_id &&
                        getChipSummaryList({
                          user_id: chipSummaryData?.parent_id,
                        });
                    }}
                  >
                    Back
                  </Button>
                )}
              </th>
            </tr>
          </thead>
          <tbody>
            {type === "success" &&
              chipSummaryData?.plusData?.map((item) => {
                return (
                  <tr>
                    <td></td>
                    <td>{item?.description}</td>
                    <td colSpan={2} className={`text-${type === "success" ? "secondary" : "danger"}`}>
                      {item?.amount}
                    </td>
                  </tr>
                );
              })}
            {type === "success" &&
              chipSummaryData?.data_paid_to?.list?.map((item) => {
                return (
                  <tr>
                    <td
                      className="cursor-pointer"
                      onClick={() => {
                        toggleList(item?.user_name);
                        getUserAgent(item?.user_id);
                      }}
                    >
                      {item?.name}
                    </td>
                    <td
                      className="text-info cursor-pointer"
                      onClick={() => {
                        item?.user_type_id !== 1 &&
                          getChipSummaryList({ user_id: item?.user_id });
                      }}
                    >
                      <span className="badge bg-info-subtle text-info">
                        {" "}
                        {item?.user_type_id !== 1 ? "A" : "C"}
                      </span>
                      {} {item?.user_name} A/C
                    </td>
                    <td className={`text-${type === "success" ? "secondary" : "danger"}`}>
                      {Number(item?.settlement_amount).toFixed(2)}
                    </td>
                    <td className="text-end">
                      <Button
                        variant={`subtle-${type}`}
                        className="btn-sm"
                        onClick={() => {
                          toggleHistory();
                          getChipSummaryHistory(item?.user_id, item?.user_name);
                        }}
                      >
                        History
                      </Button>
                      <Button
                        variant="subtle-dark"
                        className="ms-2 btn-sm"
                        onClick={() =>
                          toggleSettlement(
                            item?.settlement_amount,
                            item?.user_id,
                            item?.user_name
                          )
                        }
                      >
                        Settlement
                      </Button>
                    </td>
                  </tr>
                );
              })}

            {type === "danger" &&
              chipSummaryData?.minusData?.map((item) => {
                return (
                  <tr>
                    <td></td>
                    <td>{item?.description}</td>
                    <td colSpan={2} className={`text-${type}`}>
                      {item?.amount}
                    </td>
                  </tr>
                );
              })}
            {type === "danger" &&
              chipSummaryData?.data_receiving_from?.list?.map((item) => {
                return (
                  <tr>
                    <td
                      className="cursor-pointer"
                      onClick={() => {
                        toggleList(item?.user_name);
                        getUserAgent(item?.user_id);
                      }}
                    >
                      {item?.name}
                    </td>
                    <td
                      className="text-info cursor-pointer"
                      onClick={() => {
                        item?.user_type_id !== 1 &&
                          getChipSummaryList({ user_id: item?.user_id });
                      }}
                    >
                      <span className="badge bg-info-subtle text-info">
                        {item?.user_type_id !== 1 ? "A" : "C"}
                      </span>{" "}
                      {item?.user_name} A/C
                    </td>
                    <td className={`text-${type}`}>
                      {Number(item?.settlement_amount).toFixed(2)}
                    </td>
                    <td className="text-end">
                      <Button
                        variant={`subtle-${type}`}
                        className="btn-sm"
                        onClick={() => {
                          toggleHistory();
                          getChipSummaryHistory(item?.user_id, item?.user_name);
                        }}
                      >
                        History
                      </Button>
                      <Button
                        variant="subtle-dark"
                        className="ms-2 btn-sm"
                        onClick={() =>
                          toggleSettlement(
                            item?.settlement_amount,
                            item?.user_id,
                            item?.user_name
                          )
                        }
                      >
                        Settlement
                      </Button>
                    </td>
                  </tr>
                );
              })}
          </tbody>
          <tfoot className="table-light">
            <tr>
              <th colSpan={2}>Total</th>
              <th colSpan={2} className={`text-${type}`}>
                {type === "danger"
                  ? chipSummaryData?.totalMinus
                  : chipSummaryData?.totalPlus}
              </th>
            </tr>
          </tfoot>
        </Table>
      </div>

      <Modal
        show={showList}
        onHide={() => toggleList()}
        className="zoomIn"
        scrollable
      >
        <Modal.Header className="modal-title fw-bold d-flex justify-content-between pb-1">
          <div className="d-flex align-items-center">
            Parent List of{" "}
            <span className="text-muted fw-normal mx-2">({selectedUser})</span>
          </div>
          <Button variant="light btn-sm" onClick={() => toggleList()}>
            <i className="ri-close-line fs-xl align-middle"></i>
          </Button>
        </Modal.Header>
        {agentList?.map((item) => {
          return (
            <Modal.Body>
              {item.name}({item.user_name})
            </Modal.Body>
          );
        })}
      </Modal>

      <HistoryModal
        show={showHistory}
        clickHandler={toggleHistory}
        chipHistoryData={chipHistoryData}
        chipHistoryText={chipHistoryText}
        selectedUser={selectedUser}
      />

      <Modal
        show={showSettlement}
        onHide={() => toggleSettlement("")}
        className="zoomIn"
        scrollable
      >
        <Modal.Header className="modal-title fw-bold d-flex justify-content-between pb-1">
          <div className="d-flex align-items-center">Settlement of {selectedUser}</div>
          <Button variant="light btn-sm" onClick={() => toggleSettlement("")}>
            <i className="ri-close-line fs-xl align-middle"></i>
          </Button>
        </Modal.Header>
        <Modal.Body>
          <div className="form-floating mb-3">
            <Form.Control
              name="amount"
              type="number"
              placeholder="Chips"
              value={formData.amount}
              onChange={handleInputChange}
              isInvalid={!!formErrors.amount && touchedFields.amount}
              onKeyDown={(e) => {
                if (
                  e.key === "-" ||
                  e.key === "e" ||
                  e.key === "+" ||
                  e.key === "E" ||
                  e.key === "."
                ) {
                  e.preventDefault();
                }
              }}
            />
            <Form.Label>Chips </Form.Label>
            {formErrors.amount && touchedFields.amount && (
              <Form.Control.Feedback type="invalid">
                {formErrors.amount}
              </Form.Control.Feedback>
            )}
          </div>
          <div className="form-floating">
            <Form.Control
              name="remark"
              as="textarea"
              rows={5}
              id="Remarks"
              placeholder="Remarks"
              onChange={handleInputChange}
              isInvalid={!!formErrors.remark && touchedFields.remark}
            />
            <Form.Label>Remark </Form.Label>
          </div>
          <div className="form-floating mt-3 position-relative">
            <Form.Control
              name="password"
              type={passwordShow ? "text" : "password"}
              placeholder="Password"
              value={formData.password}
              onChange={handleInputChange}
              isInvalid={!!formErrors.password && touchedFields.password}
            />
            <Form.Label>Password</Form.Label>
            <Button
              variant="link"
              className="position-absolute end-0 top-0 bottom-0 text-decoration-none text-muted password-addon"
              type="button"
              id="password-addon"
              onClick={() => setPasswordShow(!passwordShow)}
            >
              <i
                className={`${
                  passwordShow ? "ri-eye-off-fill" : "ri-eye-fill"
                } align-middle`}
              ></i>
            </Button>
            {formErrors.password && touchedFields.password && (
              <Form.Control.Feedback type="invalid">
                {formErrors.password}
              </Form.Control.Feedback>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer className="border-top">
          <Button variant={`subtle-${type}`} onClick={() => handleSubmit()}>
            {type === "success" ? "Debit" : "Credit"}
          </Button>
          <Button variant="subtle-dark" onClick={() => toggleSettlement("")}>
            Cancel
          </Button>
        </Modal.Footer>
        {isLoading && <Loader />}
      </Modal>
    </>
  );
};

export default ChipSummaryTable;
