import BreadCrumb from "Common/BreadCrumb";
import UniverseCasinoTable from "Common/Tables/UniverseCasinoTable";
import { useEffect, useState } from "react";
import { Container, Button, Card, Form, Modal, Tab, Nav } from "react-bootstrap";
import { settingServices } from "Utils/setting/services";
import snackbarUtil from "Utils/snackBarUtil";

const UniverseCasinoResult = () => {
    const [showManual, setManual] = useState(false);
    const [showVoid, setVoid] = useState(false);
    const [showToken, setToken] = useState(false);
    const [casinoData, setCasinoData] = useState<any[]>([]);
    const [accessTokenData, setAccessTokenData] = useState<any>();
    const [limit, setLimit] = useState(50);
    
   
    const [manualRoundId, setManualRoundId] = useState("");
    const [manualError, setManualError] = useState("");
    
   
    const [voidRoundId, setVoidRoundId] = useState("");
    const [voidEventId, setVoidEventId] = useState("");
    const [voidError, setVoidError] = useState("");

    const toggleManual = () => {
        setManual(!showManual);
        resetManualForm();
    };

    const toggleVoid = () => {
        setVoid(!showVoid);
        resetVoidForm();
    };

    const toggleToken = () => {
        getAccessToken();
        setToken(!showToken);
    };

    const resetManualForm = () => {
        setManualRoundId("");
        setManualError("");
    };

    const resetVoidForm = () => {
        setVoidRoundId("");
        setVoidEventId("");
        setVoidError("");
    };

    const getManualResult = async (roundId:string) => {
        const { response } = await settingServices.getManualResult({
            roundId,
        });
        if(response){
            if(response?.status){
                snackbarUtil.success(response?.msg);
            }else{
                snackbarUtil.error(response?.msg);
            }
        }
       
    };
    const getVoidResult = async (roundId:string, eventId:string) => {
        const { response } = await settingServices.getVoidResult({
            roundId,
            eventId,
        });
        if(response){
            if(response?.status){
                snackbarUtil.success(response?.msg);
            }else{
                snackbarUtil.error(response?.msg);
            }
        }
       

    };

    const getAccessToken = async () => {
        const { response } = await settingServices.getAccessToken();
        setAccessTokenData(response?.msg)

    };
    const getRoundList = async () => {
        const { response } = await settingServices.getRoundList();
        setCasinoData(response?.data)

    };

    useEffect(()=>{
        getRoundList();
    }, [])

    const generateAccessToken = async () => {
        const { response } = await settingServices.generateAccessToken();
        setAccessTokenData(response?.msg)

    };

    const handleManualSubmit = () => {
        if (!manualRoundId) {
            setManualError("Round ID is required.");
            return;
        }
        setManualError("");
        getManualResult(manualRoundId);
        toggleManual();
    };

    const handleVoidSubmit = () => {
        if (!voidRoundId || !voidEventId) {
            setVoidError("This Field is required");
            return;
        }
        getVoidResult(voidRoundId,voidEventId );
        setVoidError("");
        // Call your void service here
        toggleVoid();
    };


    const handleGenerate = ()=>{
        getAccessToken();
    }
    const handleCheckStatus = ()=>{
        generateAccessToken();
    }

    return (
        <div className="page-content">
            <Container fluid>
                <div className="position-relative mb-2 d-flex justify-content-between align-items-center">
                    <BreadCrumb title="Universe Casino Result" pageTitle="Dashboard" back />
                    <div>
                        <Button variant="subtle-dark" onClick={toggleManual}>
                            Manual Result Declare
                        </Button>
                        <Button variant="subtle-primary mx-3" onClick={toggleVoid}>
                            Void Result Declare
                        </Button>
                        <Button variant="subtle-info" onClick={toggleToken}>
                            Token
                        </Button>
                    </div>
                </div>

                <Card>
                    <Card.Body>
                        <UniverseCasinoTable setLimit={setLimit} casinoData={casinoData}/>
                    </Card.Body>
                </Card>
            </Container>

           
            <Modal size="sm" show={showManual}>
                <Modal.Header className="modal-title fw-bold d-flex justify-content-between pb-1">
                    <div>Manual Result Declare</div>
                    <Button variant="light btn-sm" onClick={toggleManual}>
                        <i className="ri-close-line fs-xl align-middle"></i>
                    </Button>
                </Modal.Header>
                <Modal.Body className="fs-md">
                    <Form.Label>Round Id</Form.Label>
                    <Form.Control
                        name="roundId"
                        type="text"
                        placeholder="Enter Id"
                        value={manualRoundId}
                        onChange={(e) => setManualRoundId(e.target.value)}
                        isInvalid={!!manualError}
                    />
                    <Form.Control.Feedback type="invalid">{manualError}</Form.Control.Feedback>
                </Modal.Body>
                <Modal.Footer className="border-top pt-3">
                    <Button onClick={handleManualSubmit}>Update</Button>
                    <Button variant="subtle-dark" onClick={toggleManual}>Cancel</Button>
                </Modal.Footer>
            </Modal>

            {/* Void Result Modal */}
            <Modal size="sm" show={showVoid}>
                <Modal.Header className="modal-title fw-bold d-flex justify-content-between pb-1">
                    <div>Void Result</div>
                    <Button variant="light btn-sm" onClick={toggleVoid}>
                        <i className="ri-close-line fs-xl align-middle"></i>
                    </Button>
                </Modal.Header>
                <Modal.Body className="fs-md">
                    <Form.Label>Round Id</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Enter Round Id"
                        value={voidRoundId}
                        onChange={(e) => setVoidRoundId(e.target.value)}
                        isInvalid={!!voidError}
                    />
                    <Form.Control.Feedback type="invalid">{voidError}</Form.Control.Feedback>

                    <Form.Label className="mt-3">Event Id</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Enter Event Id"
                        value={voidEventId}
                        onChange={(e) => setVoidEventId(e.target.value)}
                        isInvalid={!!voidError}
                    />
                    <Form.Control.Feedback type="invalid">{voidError}</Form.Control.Feedback>
                </Modal.Body>
                <Modal.Footer className="border-top pt-3">
                    <Button onClick={handleVoidSubmit}>Update</Button>
                    <Button variant="subtle-dark" onClick={toggleVoid}>Cancel</Button>
                </Modal.Footer>
            </Modal>

            {/* Access Token Modal */}
            <Modal show={showToken}>
                <Modal.Header className="modal-title fw-bold d-flex justify-content-between pb-1">
                    <div>Access Token</div>
                    <Button variant="light btn-sm" onClick={toggleToken}>
                        <i className="ri-close-line fs-xl align-middle"></i>
                    </Button>
                </Modal.Header>
                <Modal.Body className="fs-md pt-2">
                    <Tab.Container defaultActiveKey="generate">
                        <Nav as="ul" variant="tabs" className="nav-tabs-custom nav-primary nav-justified mb-3">
                            <Nav.Item as="li" onClick={handleGenerate}>
                                <Nav.Link eventKey="generate" >Generate</Nav.Link>
                            </Nav.Item>
                            <Nav.Item as="li" onClick={handleCheckStatus}>
                                <Nav.Link eventKey="checkStatus">Check Status</Nav.Link>
                            </Nav.Item>
                        </Nav>
                        <Tab.Content className="text-muted">
                            <Tab.Pane eventKey="generate">{accessTokenData}</Tab.Pane>
                            <Tab.Pane eventKey="checkStatus">{accessTokenData}</Tab.Pane>
                        </Tab.Content>
                    </Tab.Container>
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default UniverseCasinoResult;
