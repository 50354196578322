import { ApiResource } from "../apiService";


export const settingResourcs: {
  [x: string]: ApiResource;
} = {
 
  GET_ALL_WEBSITE: {
    URL: "/website/getAllWebsite",
    METHOD: "GET",
  },
  CHECK_WEBSITE: {
    URL: "/website/checkWebsiteName",
    METHOD: "POST",
  },
  UPDATE_WEBSITE: {
    URL: "/website/updateWebsite",
    METHOD: "POST",
  },
  DELETE_WEBSITE: {
    URL: "/website/deleteWebsiteDomain",
    METHOD: "POST",
  },
  ALLOW_UNMATCHEDBET: {
    URL: "/website/allowUnmatchedBet",
    METHOD: "POST",
  },
  UPLOAD_LOGO: {
    URL: "/content/upload/logo",
    METHOD: "POST",
  },
  GET_SOCIAL_ICON: {
    URL: "/content/get",
    METHOD: "POST",
  },
  CREATE_SOCILA_ICON: {
    URL: "/content/createSocialHandler",
    METHOD: "POST",
  },
  UPDATE_WEBSITE_TVURL: {
    URL: "/website/updateWebsiteTvUrlSetting",
    METHOD: "POST",
  },
  DOMAIN_COUNT: {
    URL: "/website/getDomainWiseCounts",
    METHOD: "POST",
  },
  UPDATE_DOMAIN_NEW_OLD:{
    URL: "/website/updateDomainNewToOld",
    METHOD: "POST",
  },
  CONTENT_GET:{
    URL: "/content/contentGet",
    METHOD: "GET",
  },
  UPLOAD_POPUP:{
    URL: "/content/uploadPopupContent",
    METHOD: "POST",
  },
  UPDATE_CASINO_RATE:{
    URL: "/website/updateCasinoConversionRate",
    METHOD: "POST",
  },
  CREATE_WEBSITE:{
    URL: "/website/createWebSiteSetting",
    METHOD: "POST",
  },
  CHECK_SITE_DATA:{
    URL: "/website/checkSiteTitleData",
    METHOD: "POST",
  },
  PENDING_MARKET:{
    URL: "/market/pending-markets",
    METHOD: "POST",
  },
  MARKET_RESULT:{
    URL: "/market/results",
    METHOD: "POST",
  },
  EVENT_LIST:{
    URL: "/event/lists",
    METHOD: "POST",
  },
  ODDS_RESULT:{
    URL: "/bet/oddsResult",
    METHOD: "POST",
  },
  ODDS_ABD:{
    URL: "/bet/oddsAbandoned",
    METHOD: "POST",
  },
  FANCY_RESULT:{
    URL: "/fancy/results",
    METHOD: "POST",
  },
  SESSION_RESULT:{
    URL: "/bet/sessionResultV2",
    METHOD: "POST",
  },
  SESSION_ABD:{
    URL: "/bet/sessionAbandoned",
    METHOD: "POST",
  },
  FANCY_POSITION:{
    URL: "fancy/getRunTimeFancyPositionV1",
    METHOD: "POST",
  },
  GET_RESULT:{
    URL: "/market/getResult",
    METHOD: "POST",
  },
  LOTUS_BETS:{
    URL: "/lotus/bets",
    METHOD: "POST",
  },
  USER_NAME:{
    URL: "/user/getUserByUserName",
    METHOD: "POST",
  },
  GET_ROUND_STATUS:{
    URL: "/lotus/getRoundStatus",
    METHOD: "POST",
  },
  SHOW_AGENT:{
    URL: "/user/showAgents",
    METHOD: "POST",
  },
  MANUAL_RESULT_DECLARE:{
    URL: "/universal-casino/manualResultDeclare",
    METHOD: "POST",
  },
  VOID_RESULT_DECLARE:{
    URL: "/universal-casino/voidResult",
    METHOD: "POST",
  },
  GET_ACCESS_TOKEN:{
    URL: "/universal-casino/generateAccessToken",
    METHOD: "POST",
  },
  GENERATE_ACCESS_TOKEN:{
    URL: "/universal-casino/checkAccessTokenStatus",
    METHOD: "GET",
  },
  GET_ROUND_LIST:{
    URL: "/universal-casino/getRoundsList",
    METHOD: "POST",
  },
  MATCH_ROLLBACK:{
    URL: "/market/results-rollback",
    METHOD: "POST",
  },
  ODDS_ROLLBACK:{
    URL: "/bet/oddsRollback",
    METHOD: "POST",
  },
  SESSION_ROLLBACK:{
    URL: "/bet/sessionRollbackV2",
    METHOD: "POST",
  },
  USER_MOBILE_NAME:{
    URL: "/user/getUserNameMobileNoAndName",
    METHOD: "POST",
  },
  // DELETED_CONTENT:{
  //   URL: "/content/contentDelete",
  //   METHOD: "DELETE",
  // },
  DELETED_CONTENT:{
    URL: "/content/delete",
    METHOD: "POST",
  },
  UPLOAD_CONTENT:{
    URL: "/content/uploadContent",
    METHOD: "POST",
  },
  UPLOAD_CONTENT_AGENT:{
    URL: "/content/upload/slider",
    METHOD: "POST",
  },
  CHECK_USER_NAME:{
    URL: "/user/checkUserName",
    METHOD: "POST",
  },
  UPDATE_EXPOSURE:{
    URL: "/user/update",
    METHOD: "POST",
  },
  EVENT_ANALYSIS:{
    URL: "/event-analysis",
    METHOD: "POST",
  },
  GET_USER_BY_USERNAME:{
    URL: "/user/getUserByUserName",
    METHOD: "POST",
  },
  LAUNCH_URL:{
    URL: "/lotus/launchUrl",
    METHOD: "POST",
  },
  CLEAR_EXP:{
    URL: "/lotus/clearExposure",
    METHOD: "POST",
  },
  MANUAL_RESULT_DEC:{
    URL: "/lotus/manualResultDeclare",
    METHOD: "POST",
  },
  CREADIT_REFERENCE_LOGS:{
    URL: "/user/creditReferenceLogs",
    METHOD: "POST",
  },
  GET_PASSWORD_CHANGE_HISTORY:{
    URL: "/user/getPasswordChangedHistory",
    METHOD: "POST",
  },
  LIST_BANNER:{
    URL: "/content/sliders-manage",
    METHOD: "GET",
  },
};
