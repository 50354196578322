import CasinoBetsTable from "Common/Tables/CasinoBetsTable";
import { Card } from "react-bootstrap";
import { useEffect, useState } from "react";
import { authServices } from "Utils/auth/services";
import Loader from "Common/Loader";

interface StatementProps {
  gameId: string;
  userid?: string;
}

const SettledCasinoBet = ({ gameId, userid }: StatementProps) => {
  const [casinoBetsData, setCasinoBetsData] = useState<CasinoBetsData[]>([]);
  const [limit, setLimit] = useState<number>(50);
  const [page, setPage] = useState<number>(1);
  const [listTotal, setListTotal] = useState<ProfitLossTotal>({
    total: 0,
    page: 0,
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const getCasinoBetsList = async (filters: any = {}) => {
    setIsLoading(true);
    const { response } = await authServices.getCasinoBets({
      bets_type: "settled",
      limit: limit,
      page: page,
      marketId: gameId,
      ...((userid && userid != "null") && { user_id: userid}),
      ...filters,
    });
    setIsLoading(false);
    if (response?.data && response?.data?.data) {
      setCasinoBetsData(response?.data?.data);
      setListTotal({
        total: response?.data?.metadata?.totalPages,
        page: response?.data?.metadata?.currentPage,
      });
    } else {
      setCasinoBetsData([]);
      setListTotal({
        total: 0,
        page: 1,
      });
    }
  };

  useEffect(() => {
    getCasinoBetsList();
  }, []);

  const handlePageFilter = (page: number, limit: number) => {
    setPage(page);
    setLimit(limit);
    let filterData: {
      page: number;
      limit: number;
    } = {
      page: page,
      limit: limit,
    };

    getCasinoBetsList(filterData);
  };

  return (
    <>
      <Card>
        <Card.Body>
          <CasinoBetsTable
            casinoBetsData={casinoBetsData}
            page={page}
            limit={limit}
            listTotal={listTotal}
            handlePageFilter={handlePageFilter}
          />
        </Card.Body>
      </Card>
      {isLoading && <Loader />}
    </>
  );
};

export default SettledCasinoBet;
