import { useEffect, useState } from 'react';
import { Button, Modal, Form, Row, Col } from 'react-bootstrap';
import { authServices } from 'Utils/auth/services';
import snackbarUtil from 'Utils/snackBarUtil';

interface EditStakeModalProps {
    show: boolean;
    clickHandler: any;
    userIds: string;
    userName: string;
}

const EditStakeModal = ({ show, clickHandler, userIds, userName }: EditStakeModalProps) => {
    const [stake, setStake] = useState<number | "">("");
    const [tempStakesArray, setTempStakesArray] = useState<number[]>([]);
    const [showDynamicList, setShowDynamicList] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [dynamicErrorMessages, setDynamicErrorMessages] = useState<string[]>([]);

    useEffect(() => {
        getUserStackValue();
    }, [userIds]);

    const getUserStackValue = async () => {
        if(!userIds) return null;
        const { response } = await authServices.getUserStackValue({
            user_id: userIds
        });

        if (response?.match_stack) {
            setTempStakesArray(response.match_stack);
            setStake(response.match_stack.length);
        }
    };

    const handleStakeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newStakeCount = e.target.value === '' ? "" : Number(e.target.value);
        setStake(newStakeCount);

        if (newStakeCount === "" || Number(newStakeCount) <= 0) {
            setTempStakesArray([]);
            setErrorMessage('Stake count must be greater than zero.');
            setDynamicErrorMessages([]);
        } else {
            setErrorMessage('');
            updateStakesArray(newStakeCount);
        }
    };

    const updateStakesArray = (newStakeCount: number) => {
        const lastValue = tempStakesArray[tempStakesArray.length - 1] || 0;
        const newStakesArray = Array.from({ length: newStakeCount }, (_, index) =>
            tempStakesArray[index] !== undefined ? tempStakesArray[index] : lastValue + (index - tempStakesArray.length + 1) * 100
        );
        setTempStakesArray(newStakesArray);
        setDynamicErrorMessages(Array(newStakeCount).fill(''));
    };

    useEffect(() => {
        if (Number(stake) > 0) {
            setShowDynamicList(true);
        }
    }, [stake]);

    const handleDynamicInputChange = (index: number, newValue: number) => {
        const value = newValue === 0 ? "" : Number(newValue);
        const updatedArray: any = [...tempStakesArray];
        updatedArray[index] = value;
        setTempStakesArray(updatedArray);

        const newErrorMessages = [...dynamicErrorMessages];
        if (value === "" || value <= 0) {
            newErrorMessages[index] = 'Stake must be greater than zero and not empty.';
        } else {
            newErrorMessages[index] = '';
        }
        setDynamicErrorMessages(newErrorMessages);
    };

    const updateStack = async () => {
        const { response } = await authServices.getUpdateStack({
            user_id: userIds,
            match_stack: tempStakesArray
        });

        if (response) {
            if (response.status) {
                snackbarUtil.success(response.msg);
                handleClose();
            } else {
                snackbarUtil.error(response.msg);
            }
        }
    };

    const handleStackSubmit = () => {
        if (stake === "" || stake <= 0) {
            setErrorMessage('Stake count must be greater than zero.');
            return;
        }
        updateStakesArray(stake);
        setShowDynamicList(true);
    };

    const handleStackUpdate = () => {
        if (tempStakesArray.length === 0 || tempStakesArray.some((v: any) => v <= 0 || v === "")) {
            setErrorMessage('All stakes must be greater than zero and not empty.');
            return;
        }
        updateStack();
    };

    const handleClose = () => {
        setStake("");
        clickHandler();
        setShowDynamicList(false);
        setTempStakesArray([]);
        setDynamicErrorMessages([]);
    };

    const increaseStakeCount = () => {
        if (stake !== "") {
            const newStakeCount = Number(stake) + 1;
            setStake(newStakeCount);
            updateStakesArray(newStakeCount);
        }
    };

    const decreaseStakeCount = () => {
        if (stake !== "" && Number(stake) > 1) {
            const newStakeCount = Number(stake) - 1;
            setStake(newStakeCount);
            updateStakesArray(newStakeCount);
        }
    };

    // <div className="input-step step-primary">
    //     <Button
    //         className="minus"
    //         onClick={() => adjustCommission('match_commission', -1)}
    //     >
    //         –
    //     </Button>
    //     <Form.Control
    //         type="number"
    //         name="match_commission"
    //         className="product-quantity"
    //         value={formData.match_commission}
    //         min="0"
    //         max="100"
    //         onChange={handleInputChange}
    //         onKeyDown={(e) => {
    //             if (
    //                 e.key === '-' ||
    //                 e.key === 'e' ||
    //                 e.key === '+' ||
    //                 e.key === 'E' ||
    //                 e.key === '.'
    //             ) {
    //                 e.preventDefault();
    //             }
    //         }}
    //     />
    //     <Button
    //         className="plus"
    //         onClick={() => adjustCommission('match_commission', 1)}
    //     >
    //         +
    //     </Button>
    // </div>

    return (
        <Modal show={show} onHide={handleClose} className="zoomIn" scrollable>
            <Modal.Header className="modal-title fw-bold d-flex justify-content-between pb-1">
                <span>Change Stake Value of <span className='text-muted'>{userName}</span></span>
                <Button variant="light btn-sm" onClick={handleClose}>
                    <i className="bi bi-x fs-xl align-middle"></i>
                </Button>
            </Modal.Header>
            <Modal.Body className='fs-md'>
                <Row>
                    <Col md={4}>
                        <div className="fs-md mb-2">No. Of Stack</div>
                        <div className="d-flex align-items-center mb-3 input-step full-width step-primary">
                            <Button
                                className="minus"
                                onClick={decreaseStakeCount}
                            >
                                –
                            </Button>

                            <Form.Control
                                type="number"

                                className="product-quantity"
                                value={stake}
                                min="0"
                                max="100"
                                onChange={handleStakeChange}
                                onKeyDown={(e) => {
                                    if (
                                        e.key === '-' ||
                                        e.key === 'e' ||
                                        e.key === '+' ||
                                        e.key === 'E' ||
                                        e.key === '.'
                                    ) {
                                        e.preventDefault();
                                    }
                                }}
                            />
                            <Button
                                className="plus"
                                onClick={increaseStakeCount}
                            >
                                +
                            </Button>

                        </div>
                    </Col>
                </Row>

                {errorMessage && <div className="text-danger">{errorMessage}</div>}

                <Row>
                {showDynamicList && tempStakesArray.map((value, index) => (
                    <Col sm={6}>
                        <div className="form-floating mb-3" key={index}>
                            <Form.Control
                                type="number"
                                value={value}
                                onChange={(e) => handleDynamicInputChange(index, Number(e.target.value))}
                            />
                            <Form.Label>Chip Value {index + 1}</Form.Label>
                            {dynamicErrorMessages[index] && <div className="text-danger">{dynamicErrorMessages[index]}</div>}
                        </div>
                    </Col>
                ))}
                </Row>
            </Modal.Body>
            <Modal.Footer className='border-top'>
                <Button onClick={handleStackUpdate}>Submit</Button>
                <Button variant='light' onClick={handleClose}>Cancel</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default EditStakeModal;
