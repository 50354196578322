import Flatpickr from "react-flatpickr";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import Select from "react-select";
import { currentDateTime, getStartTimeOfDay } from "Utils/datefilter";
import { useEffect, useMemo, useRef, useState } from "react";
import { authServices } from "Utils/auth/services";
import FancyStakeTable from "Common/Tables/FancyStakeTable";
import { useNavigationType, useParams } from "react-router-dom";
import Loader from "Common/Loader";
import { generateOptions } from "Utils/searchFilter";
import { debounce } from "lodash";

interface FancyStakeUserWiseProps {
  filter?: boolean;
  activeTab: string;
}

const FancyStakeUserWise = ({ filter, activeTab }: FancyStakeUserWiseProps) => {
  const { userid } = useParams();
  const userId =
    userid && userid != "null" ? userid : localStorage.getItem("userId");

  const currentDate = new Date();
  const sevenDaysAgo = new Date(currentDate.setDate(currentDate.getDate() - 7));

  const [startDate, setStartDate] = useState<string | Date>(
    getStartTimeOfDay(sevenDaysAgo)
  );
  const [endDate, setEndDate] = useState<string | Date>(currentDateTime());
  const [fancyStakeUserWiseData, setFancyStakeUserWiseData] = useState<
    FancyStakeList[]
  >([]);
  const [parentId, setParentId] = useState<string>("null");
  const [total, setTotal] = useState<number>(0);
  const [usersData, setUsersData] = useState<SearchUsersListData[]>([]);
  const [inputValue, setInputValue] = useState("");
  const [selectedUser, setSelectedUser] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const userOptions = useMemo(
    () => generateOptions(usersData || [], "_id", "user_name"),
    [usersData]
  );

  const navigationType = useNavigationType();
  const isInitialRender = useRef(true);
  useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false;
      return;
    }
    localStorage.setItem("fancyStackStart", startDate.toString());
    localStorage.setItem("fancyStackEnd", endDate.toString());
  }, [startDate, endDate]);

  const getFancyStakeUserWiseList = async (filters: any = {}) => {
    setIsLoading(true);
    const { response } = await authServices.getFancyTotalStackUserWise({
      user_id: userId,
      ...filters,
    });
    setIsLoading(false);
    if (response?.data) {
      setFancyStakeUserWiseData(response?.data);
      setParentId(response?.parent_id);

      let stackList = response?.data;
      var totalStack = 0;
      for (let i = 0; i < stackList.length; i++) {
        totalStack = totalStack + stackList[i].stack;
      }
      setTotal(totalStack);
    } else {
      setFancyStakeUserWiseData([]);
      setParentId("");
      setTotal(0);
    }
  };

  const getUsresList = async (userName: string) => {
    const { response } = await authServices.getUserList({
      user_name: userName,
    });
    if (response?.data) {
      setUsersData(response?.data);
    } else {
      setUsersData([]);
    }
  };

  const fetchOptions = debounce(async (input: string) => {
    if (input.length >= 3) {
      getUsresList(input);
    }
  }, 300);

  useEffect(() => {
    fetchOptions(inputValue);
  }, [inputValue]);

  useEffect(() => {
    let start =
      navigationType === "POP"
        ? localStorage.getItem("fancyStackStart")
        : startDate;
    let end =
      navigationType === "POP"
        ? localStorage.getItem("fancyStackEnd")
        : endDate;
    start && setStartDate(new Date(start));
    end && setEndDate(new Date(end));

    activeTab === "FancyStakeU" &&
      getFancyStakeUserWiseList({
        from_date: new Date(start || ''),
        to_date: new Date(end || ''),
      });
  }, [activeTab]);

  const handlefilterSubmit = () => {
    let filterData: {
      from_date: string | Date;
      to_date: string | Date;
      user_id?: string;
    } = {
      from_date: startDate,
      to_date: endDate,
    };
    if (selectedUser) filterData.user_id = selectedUser;
    getFancyStakeUserWiseList(filterData);
  };

  const handleClearSelection = () => {
    setStartDate(getStartTimeOfDay(sevenDaysAgo));
    setEndDate(currentDateTime());
    setUsersData([]);
    setSelectedUser("");

    getFancyStakeUserWiseList({
      from_date: getStartTimeOfDay(sevenDaysAgo),
      to_date: currentDateTime(),
    });
  };

  const handleUserDetail = (user: string) => {
    getFancyStakeUserWiseList({
      user_id: user && user != "null" ? user : userId,
    });
  };

  return (
    <>
      {filter ? (
        <Card>
          <Card.Body>
            <Form>
              <Row className="gy-3 align-items-end">
                <Col lg={3}>
                  <Form.Label>Start Date</Form.Label>
                  <Flatpickr
                    className="form-control"
                    pl
                    options={{
                      enableTime: true,
                      dateFormat: "Y-m-d H:i:s",
                      defaultDate: [startDate],
                      maxDate: endDate,
                    }}
                    value={startDate}
                    onChange={([date]: [Date]) => {
                      setStartDate(new Date(date));
                    }}
                  />
                </Col>
                <Col lg={3}>
                  <Form.Label>End Date</Form.Label>
                  <Flatpickr
                    className="form-control"
                    pl
                    options={{
                      enableTime: true,
                      dateFormat: "Y-m-d H:i:s",
                      defaultDate: [endDate],
                      maxDate: currentDateTime(),
                    }}
                    value={endDate}
                    onChange={([date]: [Date]) => {
                      setEndDate(new Date(date));
                    }}
                  />
                </Col>
                <Col lg={3}>
                  <Form.Label>Search UserName</Form.Label>
                  <Select
                    isClearable
                    options={userOptions}
                    value={
                      userOptions.find(
                        (option: any) => option.value === selectedUser
                      ) || null
                    }
                    className="customSelect"
                    onInputChange={(input: any) => setInputValue(input)}
                    onChange={(e: any) => {
                      if (e) {
                        setSelectedUser(e.value);
                      } else {
                        setSelectedUser("");
                      }
                    }}
                  />
                </Col>
                <Col lg={3}>
                  <Button
                    className="me-2 btn-sm"
                    style={{ height: "38px" }}
                    onClick={() => handlefilterSubmit()}
                  >
                    <i className="fs-lg align-middle ri-filter-line"></i> Submit
                  </Button>
                  <Button
                    variant="outline-primary"
                    className="btn-sm"
                    style={{ height: "38px" }}
                    onClick={() => handleClearSelection()}
                  >
                    <i className="fs-lg align-middle ri-refresh-line"></i> Clear
                  </Button>
                </Col>
              </Row>
            </Form>
          </Card.Body>
        </Card>
      ) : null}
      <Card>
        <Card.Body>
          <FancyStakeTable
            fancyStakeData={fancyStakeUserWiseData}
            total={total}
            parentId={parentId}
            handleUserDetail={handleUserDetail}
            isStatement={true}
          />
        </Card.Body>
      </Card>
      {isLoading && <Loader />}
    </>
  );
};

export default FancyStakeUserWise;
