import React, { useState, useEffect } from 'react'
import { Col, Offcanvas, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import lightMode from "assets/images/custom-theme/light-mode.png";
import darkMode from "assets/images/custom-theme/dark-mode.png";
import SimpleBar from 'simplebar-react';

//redux
import {
    changeLayout,
    changeLayoutMode,
    changeLeftsidebarSizeType,
} from "slices/thunk";

//import Constant
import {
    LAYOUT_TYPES,
    LAYOUT_MODE_TYPES,
    LEFT_SIDEBAR_SIZE_TYPES,
} from "Common/constants/layout";
import { createSelector } from 'reselect';

const RightSidebar = () => {
    const dispatch = useDispatch<any>();
    const [open, setOpen] = useState<boolean>(false);

    // open offcanvas
    const toggleLeftCanvas = () => { setOpen(!open) };

    const selectLayoutProperties = createSelector(
        (state: any) => state.Layout,
        (layout) => ({
          layoutType: layout.layoutType,
          layoutThemeType: layout.layoutThemeType,
          leftSidebarType: layout.leftSidebarType,
          layoutModeType: layout.layoutModeType,
          layoutWidthType: layout.layoutWidthType,
          layoutPositionType: layout.layoutPositionType,
          topbarThemeType: layout.topbarThemeType,
          leftsidbarSizeType: layout.leftsidbarSizeType,
          leftSidebarViewType: layout.leftSidebarViewType,
          leftSidebarImageType: layout.leftSidebarImageType,
          preloader: layout.preloader,
        })
      );
      
      const {
        layoutType,
        layoutModeType,
        leftsidbarSizeType,
        preloader,
      } = useSelector(selectLayoutProperties);

    window.onscroll = function () {
        scrollFunction();
    };

    const scrollFunction = () => {
        const element = document.getElementById("back-to-top");
        if (element) {
            if (document.body.scrollTop > 100 || document.documentElement.scrollTop > 100) {
                element.style.display = "block";
            } else {
                element.style.display = "none";
            }
        }
    };
    const toTop = () => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    };

    const pathName = useLocation().pathname;

    useEffect(() => {
        const preloader = document.getElementById("preloader");
        if (preloader) {
            preloader.style.opacity = "1";
            preloader.style.visibility = "visible";
            setTimeout(function () {
                preloader.style.opacity = "0";
                preloader.style.visibility = "hidden";
            }, 1000);
        }
    }, [preloader, pathName]);

    return (
        <React.Fragment>
            <button
                onClick={() => toTop()}
                className="btn btn-dark btn-icon" id="back-to-top">
                <i className="bi bi-caret-up fs-3xl"></i>
            </button>

            {preloader === "enable" && <div id="preloader">
                <div id="status">
                    <div className="spinner-border text-primary avatar-sm" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
            </div>}

            <div className="customizer-setting d-none d-md-block">
                <div className="btn btn-info p-2 text-uppercase rounded-end-0 shadow-lg" onClick={toggleLeftCanvas}>
                    <i className="bi bi-gear"></i>
                </div>
            </div>
            <Offcanvas className="offcanvas-end border-0" show={open} onHide={toggleLeftCanvas} placement="end">
                <Offcanvas.Header className="d-flex align-items-center bg-primary bg-gradient p-3 offcanvas-header">
                    <div className="me-2">
                        <h5 className="mb-1 text-white">Backlay Builder</h5>
                        <p className="text-white text-opacity-75 mb-0">Choose your themes &amp; layouts etc.</p>
                    </div>
                    <button
                        onClick={toggleLeftCanvas}
                        type="button"
                        className="btn-close btn-close-white ms-auto"
                    ></button>
                </Offcanvas.Header>
                <div className="offcanvas-body p-0">
                    <SimpleBar className="h-100">
                        <div className="p-4">
                            <h6 className="fs-md mb-1">Layout</h6>
                            <p className="text-muted fs-sm">Choose your layout</p>
                            <Row>
                                <div className="col-4">
                                    <div className="form-check card-radio">
                                        <input
                                            id="customizer-layout01"
                                            name="data-layout"
                                            type="radio"
                                            value={LAYOUT_TYPES.VERTICAL}
                                            checked={layoutType === LAYOUT_TYPES.VERTICAL}
                                            onChange={e => {
                                                if (e.target.checked) {
                                                    dispatch(changeLayout(e.target.value));
                                                }
                                            }}
                                            className="form-check-input"
                                        />
                                        <label className="form-check-label p-0 avatar-md w-100" htmlFor="customizer-layout01">
                                            <span className="d-flex gap-1 h-100">
                                                <span className="flex-shrink-0">
                                                    <span className="bg-light d-flex h-100 flex-column gap-1 p-1">
                                                        <span className="d-block p-1 px-2 bg-primary-subtle rounded mb-2"></span>
                                                        <span className="d-block p-1 px-2 pb-0 bg-primary-subtle"></span>
                                                        <span className="d-block p-1 px-2 pb-0 bg-primary-subtle"></span>
                                                        <span className="d-block p-1 px-2 pb-0 bg-primary-subtle"></span>
                                                    </span>
                                                </span>
                                                <span className="flex-grow-1">
                                                    <span className="d-flex h-100 flex-column">
                                                        <span className="bg-light d-block p-1"></span>
                                                        <span className="bg-light d-block p-1 mt-auto"></span>
                                                    </span>
                                                </span>
                                            </span>
                                        </label>
                                    </div>
                                    <h5 className="fs-sm text-center fw-medium mt-2">Vertical</h5>
                                </div>
                                <div className="col-4">
                                    <div className="form-check card-radio">
                                        <input
                                            id="customizer-layout02"
                                            name="data-layout"
                                            type="radio"
                                            value={LAYOUT_TYPES.HORIZONTAL}
                                            checked={layoutType === LAYOUT_TYPES.HORIZONTAL}
                                            onChange={e => {
                                                if (e.target.checked) {
                                                    dispatch(changeLayout(e.target.value));
                                                }
                                            }}
                                            className="form-check-input" />
                                        <label className="form-check-label p-0 avatar-md w-100" htmlFor="customizer-layout02">
                                            <span className="d-flex h-100 flex-column gap-1">
                                                <span className="bg-light d-flex p-1 gap-1 align-items-center">
                                                    <span className="d-block p-1 bg-primary-subtle rounded me-1"></span>
                                                    <span className="d-block p-1 pb-0 px-2 bg-primary-subtle ms-auto"></span>
                                                    <span className="d-block p-1 pb-0 px-2 bg-primary-subtle"></span>
                                                </span>
                                                <span className="bg-light d-block p-1"></span>
                                                <span className="bg-light d-block p-1 mt-auto"></span>
                                            </span>
                                        </label>
                                    </div>
                                    <h5 className="fs-sm text-center fw-medium mt-2">Horizontal</h5>
                                </div>
                                <div className="col-4">
                                    <div className="form-check card-radio">
                                        <input
                                            id="customizer-layout03"
                                            name="data-layout"
                                            type="radio"
                                            value={LAYOUT_TYPES.TWOCOLUMN}
                                            checked={layoutType === LAYOUT_TYPES.TWOCOLUMN}
                                            onChange={e => {
                                                if (e.target.checked) {
                                                    dispatch(changeLayout(e.target.value));
                                                }
                                            }}
                                            className="form-check-input" />
                                        <label className="form-check-label p-0 avatar-md w-100" htmlFor="customizer-layout03">
                                            <span className="d-flex gap-1 h-100">
                                                <span className="flex-shrink-0">
                                                    <span className="bg-light d-flex h-100 flex-column gap-1">
                                                        <span className="d-block p-1 bg-primary-subtle mb-2"></span>
                                                        <span className="d-block p-1 pb-0 bg-primary-subtle"></span>
                                                        <span className="d-block p-1 pb-0 bg-primary-subtle"></span>
                                                        <span className="d-block p-1 pb-0 bg-primary-subtle"></span>
                                                    </span>
                                                </span>
                                                <span className="flex-shrink-0">
                                                    <span className="bg-light d-flex h-100 flex-column gap-1 p-1">
                                                        <span className="d-block p-1 px-2 pb-0 bg-primary-subtle"></span>
                                                        <span className="d-block p-1 px-2 pb-0 bg-primary-subtle"></span>
                                                        <span className="d-block p-1 px-2 pb-0 bg-primary-subtle"></span>
                                                        <span className="d-block p-1 px-2 pb-0 bg-primary-subtle"></span>
                                                    </span>
                                                </span>

                                                <span className="flex-grow-1">
                                                    <span className="d-flex h-100 flex-column">
                                                        <span className="bg-light d-block p-1"></span>
                                                        <span className="bg-light d-block p-1 mt-auto"></span>
                                                    </span>
                                                </span>
                                            </span>
                                        </label>
                                    </div>
                                    <h5 className="fs-sm text-center fw-medium mt-2">Two Column</h5>
                                </div>
                            </Row>
                            <h6 className="mt-4 fs-md mb-1">Color Scheme</h6>
                            <p className="text-muted fs-sm">Choose Light or Dark Scheme.</p>
                            <div className="colorscheme-cardradio">
                                <Row className="g-3">
                                    <Col xs={6}>
                                        <div className="form-check card-radio">
                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                name="data-bs-theme"
                                                id="layout-mode-light"
                                                value={LAYOUT_MODE_TYPES.LIGHTMODE}
                                                checked={layoutModeType === LAYOUT_MODE_TYPES.LIGHTMODE}
                                                onChange={e => {
                                                    if (e.target.checked) {
                                                        dispatch(changeLayoutMode(e.target.value));
                                                    }
                                                }}
                                            />
                                            <label className="form-check-label p-0 bg-transparent" htmlFor="layout-mode-light">
                                                <img src={lightMode} alt="" className="img-fluid" />
                                            </label>
                                        </div>
                                        <h5 className="fs-sm text-center fw-medium mt-2">Light</h5>
                                    </Col>
                                    <Col xs={6}>
                                        <div className="form-check card-radio dark">
                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                name="data-bs-theme"
                                                id="layout-mode-dark"
                                                value={LAYOUT_MODE_TYPES.DARKMODE}
                                                checked={layoutModeType === LAYOUT_MODE_TYPES.DARKMODE}
                                                onChange={e => {
                                                    if (e.target.checked) {
                                                        dispatch(changeLayoutMode(e.target.value));
                                                    }
                                                }}
                                            />
                                            <label className="form-check-label p-0 bg-transparent" htmlFor="layout-mode-dark">
                                                <img src={darkMode} alt="" className="img-fluid" />
                                            </label>
                                        </div>
                                        <h5 className="fs-sm text-center fw-medium mt-2">Dark</h5>
                                    </Col>
                                </Row>
                            </div>

                            {layoutType === LAYOUT_TYPES.VERTICAL && (
                            <>
                                <div id="sidebar-size" style={{ display: "block" }}>
                                    <h6 className="mt-4 fs-md mb-1">Sidebar Size</h6>
                                    <p className="text-muted fs-sm">Choose a size of Sidebar.</p>
                                    <Row>
                                    <div className="col-4">
                                            <div className="form-check sidebar-setting card-radio">
                                                <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    name="data-sidebar-size"
                                                    id="sidebar-size-small-hover"
                                                    value={LEFT_SIDEBAR_SIZE_TYPES.DEFAULT}
                                                    checked={leftsidbarSizeType === LEFT_SIDEBAR_SIZE_TYPES.DEFAULT}
                                                    onChange={e => {
                                                        if (e.target.checked) {
                                                            dispatch(changeLeftsidebarSizeType(e.target.value));
                                                        }
                                                    }}
                                                />
                                                <label className="form-check-label p-0 avatar-md w-100" htmlFor="sidebar-size-small-hover">
                                                    <span className="d-flex gap-1 h-100">
                                                        <span className="flex-shrink-0">
                                                            <span className="bg-light d-flex h-100 flex-column gap-1">
                                                                <span className="d-block p-1 bg-primary-subtle mb-2"></span>
                                                                <span className="d-block p-1 pb-0 bg-primary-subtle"></span>
                                                                <span className="d-block p-1 pb-0 bg-primary-subtle"></span>
                                                                <span className="d-block p-1 pb-0 bg-primary-subtle"></span>
                                                            </span>
                                                        </span>
                                                        <span className="flex-grow-1">
                                                            <span className="d-flex h-100 flex-column">
                                                                <span className="bg-light d-block p-1"></span>
                                                                <span className="bg-light d-block p-1 mt-auto"></span>
                                                            </span>
                                                        </span>
                                                    </span>
                                                </label>
                                            </div>
                                            <h5 className="fs-sm text-center fw-medium mt-2">Default</h5>
                                        </div>
                                        <div className="col-4">
                                            <div className="form-check sidebar-setting card-radio">
                                                <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    name="data-sidebar-size"
                                                    id="sidebar-size-large"
                                                    value={LEFT_SIDEBAR_SIZE_TYPES.LARGE}
                                                    checked={leftsidbarSizeType === LEFT_SIDEBAR_SIZE_TYPES.LARGE}
                                                    onChange={e => {
                                                        if (e.target.checked) {
                                                            dispatch(changeLeftsidebarSizeType(e.target.value));
                                                        }
                                                    }}
                                                />
                                                <label className="form-check-label p-0 avatar-md w-100" htmlFor="sidebar-size-large">
                                                    <span className="d-flex gap-1 h-100">
                                                        <span className="flex-shrink-0">
                                                            <span className="bg-light d-flex h-100 flex-column gap-1 p-1">
                                                                <span className="d-block p-1 px-2 bg-primary-subtle rounded mb-2"></span>
                                                                <span className="d-block p-1 px-2 pb-0 bg-primary-subtle"></span>
                                                                <span className="d-block p-1 px-2 pb-0 bg-primary-subtle"></span>
                                                                <span className="d-block p-1 px-2 pb-0 bg-primary-subtle"></span>
                                                            </span>
                                                        </span>
                                                        <span className="flex-grow-1">
                                                            <span className="d-flex h-100 flex-column">
                                                                <span className="bg-light d-block p-1"></span>
                                                                <span className="bg-light d-block p-1 mt-auto"></span>
                                                            </span>
                                                        </span>
                                                    </span>
                                                </label>
                                            </div>
                                            <h5 className="fs-sm text-center fw-medium mt-2">Icon with Text</h5>
                                        </div>
                                        <div className="col-4">
                                            <div className="form-check sidebar-setting card-radio">
                                                <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    name="data-sidebar-size"
                                                    id="sidebar-size-small"
                                                    value={LEFT_SIDEBAR_SIZE_TYPES.SMALLICON}
                                                    checked={leftsidbarSizeType === LEFT_SIDEBAR_SIZE_TYPES.SMALLICON}
                                                    onChange={e => {
                                                        if (e.target.checked) {
                                                            dispatch(changeLeftsidebarSizeType(e.target.value));
                                                        }
                                                    }}
                                                />
                                                <label className="form-check-label p-0 avatar-md w-100" htmlFor="sidebar-size-small">
                                                    <span className="d-flex gap-1 h-100">
                                                        <span className="flex-shrink-0">
                                                            <span className="bg-light d-flex h-100 flex-column gap-1">
                                                                <span className="d-block p-1 bg-primary-subtle mb-2"></span>
                                                                <span className="d-block p-1 pb-0 bg-primary-subtle"></span>
                                                                <span className="d-block p-1 pb-0 bg-primary-subtle"></span>
                                                                <span className="d-block p-1 pb-0 bg-primary-subtle"></span>
                                                            </span>
                                                        </span>
                                                        <span className="flex-grow-1">
                                                            <span className="d-flex h-100 flex-column">
                                                                <span className="bg-light d-block p-1"></span>
                                                                <span className="bg-light d-block p-1 mt-auto"></span>
                                                            </span>
                                                        </span>
                                                    </span>
                                                </label>
                                            </div>
                                            <h5 className="fs-sm text-center fw-medium mt-2">Small (Icon View)</h5>
                                        </div>
                                        
                                    </Row>
                                </div>
                            </>
                            )}
                        </div>
                    </SimpleBar>
                </div>
            </Offcanvas>
        </React.Fragment>
    )
}

export default RightSidebar
