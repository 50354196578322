import { ListPagination } from "Common/ListPagination";
import TableContainer from "Common/TableContainer";
import TableEntry from "Common/TableEntry";
import moment from "moment";
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { authServices } from "Utils/auth/services";
import { settingServices } from "Utils/setting/services";

interface FrausBetTablePorps {
  data: FraudBets[];
  page: number;
  limit: number;
  listTotal: ProfitLossTotal;
  handlePageFilter: (value: number, limit: number) => void;
  setFormValuesFilter?:any;
  formValuesFilter?:any;
  setUserName: Dispatch<SetStateAction<string>>
  userName:string
  setDomainId:Dispatch<SetStateAction<string>>
  domainId:string
}

const FraudBetTable = ({
  data,
  page,
  limit,
  listTotal,
  handlePageFilter,
  setFormValuesFilter,
  formValuesFilter,
  setUserName,
  userName,
  setDomainId,
  domainId
}: FrausBetTablePorps) => {
  const [showRollback, setRollback] = useState(false);
  const [userNameData, setUserNameData] = useState<any[]>([]);
  const [domainList, setDomainList] = useState<DomainListPayload[]>([]);
 
  const [currentPage, setCurrentPage] = useState(1);
 
  const toggleRollback = () => {
    setRollback(!showRollback);
  };

  const defaultTable = useMemo(() => {
    if (!data || !Array.isArray(data)) {
      return [];
    }
    return data.map((items, index: number) => ({
      DomainName: items?.domain_name || "",
      betid: items?.bet_id || "",
      sport_name: items?.sport_name || "",
      series_name: items?.series_name || "",
      match_name: items?.match_name || "",
      market_name: items?.market_name || "",
      selection_name: items?.selection_name || "",
      betType: items?.is_back === 1 ? "Back" : "Lay",
      rate: items?.odds || "",
      stake: items?.stack || "",
      betPlaceTime: items?.createdAt || "",
      playerName: items?.user_name || "",
      iPAddress: items?.ip_address || "",
      result: items?.winner_name || "",
      comment: items?.deleted_reason || "",
      status: items?.delete_status,
    }));
  }, [data]);

  const getUserbyUserName = async (user_name: string) => {
    
    const { response } = await settingServices.getUserbyUserName({
      user_name: user_name?.trim()?.toLowerCase(),
    });
    const options = response?.data.map((user: any) => ({
      value: user?.user_name,
      label: user?.user_name,
    }));
    setUserNameData(options);
  };

  const getAllWebsite = async () => {
    const { response } = await authServices.getAllWebsite();
    const options = response?.data.map((user: any) => ({
      value: user?.domain_name,
      label: user.host_name,
    }));
    setDomainList(options);
  };

  useEffect(()=>{
    getAllWebsite();
  },[])

  const handleUser = (inputValue: string) => {
    if (inputValue?.length > 3) {
      getUserbyUserName(inputValue);
    }
  };
  
  const handleSelectUser = (selectedOption: any) => {
    if (selectedOption) {
      setUserName(selectedOption.value);
    }
  };

  const handleDomain = (selectedOption: any) => {
    setCurrentPage(1);
    const selectedValue = selectedOption ? selectedOption.value : "";
    setDomainId(selectedValue);
  };

  const columns = useMemo(
    () => [
      {
        header: "Action",
        accessorKey: "action",
        enableColumnFilter: false,
        cell: (cell: any) => {
          return (
            <Button
              className="btn-sm"
              variant={cell?.row?.original?.status != 1 ? "subtle-warning" : "danger"}
              onClick={toggleRollback}
            >
              {cell?.row?.original?.status === 1 && <i className="fs-md ri ri-delete-bin-fill"></i>}
              {cell?.row?.original?.status === 2 && <>V</>}
              {cell?.row?.original?.status === 0 && <>D</>}
            </Button>
          );
        },
      },
      {
        header: "Domain",
        accessorKey: "DomainName",
        enableColumnFilter: false,
        cell: (cell: any) => {
          return (
            <div
            >
              {cell?.row?.original?.DomainName}
            </div>
          );
        },
      },
      {
        header: "Bet Id",
        accessorKey: "betid",
        enableColumnFilter: false,
      },
      {
        header: "Sport",
        accessorKey: "sport_name",
        enableColumnFilter: false,
      },
      {
        header: "Series",
        accessorKey: "series_name",
        enableColumnFilter: false,
      },
      {
        header: "Match",
        accessorKey: "match_name",
        enableColumnFilter: false,
      },
      {
        header: "Market",
        accessorKey: "market_name",
        enableColumnFilter: false,
      },
      {
        header: "Runner",
        accessorKey: "selection_name",
        enableColumnFilter: false,
      },
      {
        header: "Bet Type",
        accessorKey: "betType",
        enableColumnFilter: false,
        cell: (cell: any) => {
          return (
            <div
            >

              {cell?.row?.original?.betType}
            </div>
          );
        },
      },
      {
        header: "Rate",
        accessorKey: "odds",
        enableColumnFilter: false,
        cell: (cell: any) => {
          return (
            <div
            >

              {cell?.row?.original?.rate}
            </div>
          );
        },
      },
      {
        header: "Stake",
        accessorKey: "stack",
        enableColumnFilter: false,
        cell: (cell: any) => {
          return (
            <div
            >

              {cell?.row?.original?.stake}
            </div>
          );
        },
      },

      {
        header: "Bet Place Time",
        accessorKey: "createdAt",
        enableColumnFilter: false,
        cell: (cell: any) => {
          return (
            <div>{moment(cell?.row?.original?.betPlaceTime).format("DD-MM-YYYY, hh:mm:ss:SSS A")}</div>
          );
        },
      },
      {
        header: "Player Name",
        accessorKey: "user_name",
        enableColumnFilter: false,
        cell: (cell: any) => {
          return (
            <div
            >

              {cell?.row?.original?.playerName}
            </div>
          );
        },
      },
      {
        header: "IP Address",
        accessorKey: "ip_address",
        enableColumnFilter: false,
        cell: (cell: any) => {
          return (
            <div
            >

              {cell?.row?.original?.iPAddress}
            </div>
          );
        },
      },
      {
        header: "Result",
        accessorKey: "winner_name",
        enableColumnFilter: false,
        cell: (cell: any) => {
          return (
            <div
            >

              {cell?.row?.original?.result}
            </div>
          );
        },
      },
      {
        header: "Comment",
        accessorKey: "deleted_reason",
        enableColumnFilter: false,
        cell: (cell: any) => {
          return (
            <div
            >

              {cell?.row?.original?.comment}
            </div>
          );
        },
      },
    ],
    [defaultTable]
  );

  return (
    <>
      <TableContainer
        columns={columns || []}
        data={defaultTable || []}
        isBordered={false}
        customPageSize={limit}
        isPagination={false}
        tableClass="table-centered align-middle table-nowrap table-striped mb-3"
        theadClass="text-muted table-light"
        SearchPlaceholder="Search Products..."
        sorting={true}
        isFilter={true}
        handleUser={handleUser}
        setFormValuesFilter={(value: any) => {
          setCurrentPage(1);
          setFormValuesFilter(value);
        }}
        formValuesFilter={formValuesFilter}
        userNameData={userNameData}
        userName={userName}
        handleSelectUser={handleSelectUser}
        setUser_name={setUserName}
        domainList={domainList}
        domainId={domainId}
        handleDomain={handleDomain}
      />

      <nav aria-label="Page navigation" className="px-3 d-flex flex-column flex-sm-row align-items-center">
        <TableEntry
          limit={limit}
          handlelimitFilter={(value: number) => handlePageFilter(1, value)}
        />
        <ListPagination
          listTotal={listTotal}
          handlePageFilter={(value: number) => handlePageFilter(value, limit)}
        />
      </nav>
    </>
  );
};

export default FraudBetTable;
