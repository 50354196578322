import { Button } from 'react-bootstrap';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Mousewheel, Keyboard } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { FC } from 'react';
import { sportListProps } from 'pages/Dashboard';

interface Props {
  sportList: sportListProps[];
  setSportId: React.Dispatch<React.SetStateAction<number>>;
  setActive: React.Dispatch<React.SetStateAction<number>>;
  active: number;
}

const sportIcon: any = {
  "4": "bx bx-cricket-ball",
  "9898": "ri-bar-chart-2-line",
  "1": "bx bx-football",
  "2": "bx bxs-tennis-ball",
  "-100": "mdi mdi-poker-chip",
  "7": "mdi mdi-horse",
  "4339": "mdi mdi-dog-side",
  "QT": "ri-copper-coin-line",
};

const Sports: FC<Props> = ({ sportList, setSportId, active, setActive }) => {
  const handleSportIds = (id: string) => {
    setSportId(Number(id));
    setActive(Number(id)); 
    localStorage.setItem("sportId", id);
  };

  return (
    <div className="mt-3 d-flex align-items-center">
      <Swiper
        cssMode={true}
        slidesPerView={"auto"}
        spaceBetween={10}
        navigation={true}
        mousewheel={true}
        keyboard={true}
        modules={[Navigation, Mousewheel, Keyboard]}
        className="dashboardSwiper px-4"
      >
        <SwiperSlide className="w-auto">
          <Button
            variant={active === 0 ? "dark" : "subtle-dark"} 
            className={`dashboardBtn ${active === 0 ? "active" : ""}`}
            onClick={() => handleSportIds("0")}
          >
            <i className="ri-checkbox-circle-line me-2 fs-xl align-middle"></i> All
          </Button>
        </SwiperSlide>
        <SwiperSlide className="w-auto">
          <Button
            variant={active === 200 ? "dark" : "subtle-dark"} 
            className={`dashboardBtn ${active === 200 ? "active" : ""}`} 
            onClick={() => handleSportIds("200")}
          >
            <i className="ri-play-circle-line me-2 fs-xl align-middle"></i> In Play
          </Button>
        </SwiperSlide>
        {sportList?.map((items, id: number) => {
          return (
            <SwiperSlide key={id} className="w-auto">
              <Button
                variant={active === Number(items.sport_id) ? "dark" : "subtle-dark"} 
                className={`dashboardBtn ${
                  active === Number(items.sport_id) ? "active" : ""
                }`}
                onClick={() => handleSportIds(items.sport_id)}
              >
                <i className={`${sportIcon[items?.sport_id]} me-2 fs-xl align-middle`}></i>
                {items?.name}
              </Button>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
};

export default Sports;
