import { useEffect, useState } from 'react';
import { Button, Modal, Row, Col, Form } from 'react-bootstrap';
import { authServices } from 'Utils/auth/services';
import snackbarUtil from 'Utils/snackBarUtil';

interface MatchSettingProps {
    show: boolean;
    setShowMarketFancy: React.Dispatch<React.SetStateAction<boolean>>;
    id: string | undefined;
    marketId: string | undefined;
    marketName: string;
    category: number;
}

const FancyMarketSetting = ({ show, setShowMarketFancy, id, marketId, marketName, category }: MatchSettingProps) => {
    const [formData, setFormData] = useState<any>({
        session_min_stack: "",
        session_max_stack: "",
        session_max_profit: "",
    });
    const [errors, setErrors] = useState<{ [key: string]: string }>({});

    const handleInputChange = (e: any) => {
        const { name, value } = e.target;
        const updatedValue = value === "" ? "" : Number(value);

        setFormData({ ...formData, [name]: updatedValue });
        validateField(name, updatedValue);
    };

    const validateField = (name: string, value: number | "") => {
        let errorMessage = "";
        const minStake = formData?.session_min_stack;
        const maxStake = formData?.session_max_stack;

        if (value === "") {
            errorMessage = "This field is required";
        } else if (name === "session_min_stack" && typeof value === "number" && maxStake && value > maxStake) {
            errorMessage = "Min value cannot be greater than max value";
        } else if (name === "session_max_stack" && typeof value === "number" && minStake && value < minStake) {
            errorMessage = "Max value cannot be less than min value";
        }

        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: errorMessage || "",
        }));
    };

    const getUpdateLimits = async (data: any) => {
        if (!marketId) return;
        const payload = {
            session_min_stack: data?.session_min_stack,
            session_max_stack: data?.session_max_stack,
            session_max_profit: data?.session_max_profit,
        };

        try {
            const { response } = await authServices.updateLimits({
                [marketId]: id || "",
                values: payload,
                category,
            });
            if (response) {
                if (response.status) {
                    snackbarUtil.success(response.msg);
                    setShowMarketFancy(false);
                } else {
                    snackbarUtil.error(response.msg);
                }
            }
        } catch (error) {
            snackbarUtil.error("Error updating limits");
        }
    };

    const handleSave = () => {
        let hasErrors = false;

        // Validate each field on save
        Object.keys(formData).forEach((field: any) => {
            validateField(field, formData[field]);
            if (!formData[field] || errors[field]) {
                hasErrors = true;
            }
        });

        if (!hasErrors) {
            getUpdateLimits(formData);
        }
    };

    const handleClose = () => {
        setShowMarketFancy(false);
        setFormData({
            session_min_stack: "",
            session_max_stack: "",
            session_max_profit: ""
        });
        setErrors({});
    }

    return (
        <Modal size="xl" show={show} onHide={handleClose} className="zoomIn" scrollable>
            <Modal.Header className="modal-title fw-bold d-flex justify-content-between pb-3 pb-md-0">
                <div>{marketName}</div>
                <Button variant="light btn-sm" onClick={handleClose}>
                    <i className="ri-close-line fs-xl align-middle"></i>
                </Button>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Row className="g-3">
                        <h5 className="text-primary mb-0">Session Setting</h5>
                        <Col lg={3}>
                            <div className="form-floating">
                                <Form.Control
                                    name="session_min_stack"
                                    type="number"
                                    placeholder="Session Min Stake"
                                    value={formData?.session_min_stack}
                                    onChange={handleInputChange}
                                    isInvalid={!!errors?.session_min_stack}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.session_min_stack}
                                </Form.Control.Feedback>
                                <Form.Label>Session Min Stake</Form.Label>
                            </div>
                        </Col>
                        <Col lg={3}>
                            <div className="form-floating">
                                <Form.Control
                                    name="session_max_stack"
                                    type="number"
                                    placeholder="Session Max Stake"
                                    value={formData?.session_max_stack}
                                    onChange={handleInputChange}
                                    isInvalid={!!errors?.session_max_stack}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.session_max_stack}
                                </Form.Control.Feedback>
                                <Form.Label>Session Max Stake</Form.Label>
                            </div>
                        </Col>
                        <Col lg={3}>
                            <div className="form-floating">
                                <Form.Control
                                    name="session_max_profit"
                                    type="number"
                                    placeholder="Session Max Profit"
                                    value={formData?.session_max_profit}
                                    onChange={handleInputChange}
                                    isInvalid={!!errors?.session_max_profit}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.session_max_profit}
                                </Form.Control.Feedback>
                                <Form.Label>Session Max Profit</Form.Label>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </Modal.Body>
            <Modal.Footer className="justify-content-center border-top">
                <Button className="mx-2" onClick={handleSave}>
                    Save
                </Button>
                <Button variant="light" className="mx-2" onClick={handleClose}>
                    Cancel
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default FancyMarketSetting;
