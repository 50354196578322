import BreadCrumb from "Common/BreadCrumb";
import ToggleSwitch from "Common/ToggleSwitch";
import {  useEffect, useState } from "react";
import { Container,  Form, Button,  Modal, Dropdown, Accordion, useAccordionButton, OverlayTrigger, Tooltip, Popover, ListGroup, Collapse, Col } from "react-bootstrap";
import {  useParams } from "react-router-dom";
import { authServices } from "Utils/auth/services";
import snackbarUtil from "Utils/snackBarUtil";


const BlockMarket = () => {
    const [showTvScore, setTvScore] = useState(false);
    const [sportSeries, setSportSeries] = useState<{ [key: string]: seriesRes[] }>({});
    const [sportMatches, setSportMatches] = useState<{ [key: string]: MatchesRes[] }>({});
    const [sportName, setSportName] = useState<sportList[]>([]);
    const [sportIds, setSportids] = useState<string>("");
    const [activeStoprt, setActiveSport] = useState(true);
    const [activeMatche, setActiveMatche] = useState(false);
    const [marketData, setMarketData] = useState<MatchesRes[]>([]);
    const [matchid, setMatchid] = useState<string>("");
    const [showNews, setShowNews] = useState<boolean>(false);
    const [newsValues, setNewsValues] = useState<string>("");
    const [seriesSearchTerm, setSeriesSearchTerm] = useState("");
    const [matchSearchTerm, setMatchSearchTerm] = useState("");
    const [tvData, setTvData] = useState<SocreTVRes>({
        match_scoreboard_url: "",
        non_premimum_match_tv_url: "",
        premimum_match_tv_url: ""
    });
    const [errors, setErrors] = useState({
        premimum_match_tv_url: '',
        non_premimum_match_tv_url: '',
        match_scoreboard_url: ''
    });
    const { id, userId: userName } = useParams();
    const getUpdateEventNews = async (data: any) => {
        const { response } = await authServices?.updateEvent({
            event: data?.eventName,
            filter: {
                [data?.sportName]: data?.sportId
            },
            update: {
                news: newsValues,
            }
        })
        if (response) {
            if (response?.status) {
                snackbarUtil.success(response?.data);
                getMarketData(data?.series_id);
            } else {
                snackbarUtil.error(response?.msg);
            }
        }

    }
    const [newsPaylod, setNewsPayload] = useState({
        sportName: "",
        eventName: "",
        sportId: "",
        series_id: ""
    })
    const toggleNews = (sportName: string, eventName: string, sportId: string, series_id: string) => {
        setShowNews(true);
        setNewsPayload((prev) => ({
            ...prev,
            sportName,
            eventName,
            sportId,
            series_id
        }))
    }
    const handleUpdateNews = () => {
        getUpdateEventNews(newsPaylod)
    }
    const getSport = async () => {
        let payload: any = {};
        if (id) {
            payload.user_id = id;
        }
        const { response } = await authServices.getSportListData(payload);
        setSportName(response?.data);
    };
    const getSeriesData = async (sportId: string) => {
        let payload: any = {};
        payload.sport_id = sportId;
        if (id) {
            payload.user_id = id;
        }
        try {
            const { response } = await authServices.getSeriesData(payload);
            setSportSeries(prev => ({ ...prev, [sportId]: response?.data || [] }));
            setSportids(sportId)
        } catch (error) {
            console.error("Error fetching series data:", error);
        }
    }
    const handleSportSeriesData = (sportId: string, is_active: number) => {
        getSeriesData(sportId);
    };
    const getMatchesData = async (sport_id: string, series_id: string) => {
        let payload: any = {};
        payload.sport_id = sport_id;
        payload.series_id = series_id;
        try {
            const { response } = await authServices.getMatchesData(payload);
            setSportMatches(prev => ({ ...prev, [series_id]: response?.data || [] }));
        } catch (error) {
            console.error("Error fetching matches:", error);
        }
    };
    const userDetails = localStorage.getItem("adminDetails");
    const userId = userDetails && JSON.parse(userDetails)
    const handleMatches = (seriesId: string, sportId: string) => {
        getMatchesData(sportId, seriesId);
    };

    useEffect(() => {
        getSport();
    }, []);
    const getMarketData = async (match_id: string) => {
        let payload: any = {};
        payload.match_id = match_id;
        if (id) {
            payload.user_id = id;
        }
        const { response } = await authServices.getMarketData(payload);

        setMarketData(response?.data)
    }
    const handleMarketData = (matchid: string) => {
        getMarketData(matchid)
    }

    const handelCreate = (match_id: string, series_id: string, sport_id: string) => {
        setActiveMatche(!activeMatche);
        setSportids(sport_id);
        getEventBlock("Match", "match_id", match_id, series_id);
    }
    const handelCreateSeries = (match_id: string, series_id: string, sport_id: string) => {
        setActiveMatche(!activeMatche);
        setSportids(sport_id);
        getEventBlock("Series", "series_id", match_id, series_id);
    }
    const handelCreateMarket = (match_id: string, series_id: string, sport_id: string) => {
        setActiveMatche(!activeMatche);
        setSportids(sport_id);
        getEventBlock("Market", "market_id", match_id, sport_id);
    }

    let macthName;

    const getTvScore = async (match_id: string) => {
        const { response } = await authServices.getTvScore({
            match_id,
        })
        setTvData((prev) => ({
            ...prev,
            ...response?.data
        }));
    }
    const getUpdateScore = async () => {
        const { response } = await authServices.getUpdateUrl({
            match_id: matchid,
            update: tvData,
        })
        if (response) {
            if (response?.status) {
                snackbarUtil.success(response?.data);
                setTvScore(!showTvScore);
            } else {
                snackbarUtil.error(response?.data);
            }
        }

    }
    const handleTvScore = (id: string, name: string) => {
        macthName = name;
        setMatchid(id)
        getTvScore(id);
        setTvScore(!showTvScore);
    }
    const handleInputChange = (e: any) => {
        const { name, value } = e.target;

        setTvData((prevData) => ({
            ...prevData,
            [name]: value
        }));
    };

    const handleUpdateTv = () => {
        let newErrors = { premimum_match_tv_url: '', non_premimum_match_tv_url: '', match_scoreboard_url: '' };
        if (!tvData?.premimum_match_tv_url) {
            newErrors.premimum_match_tv_url = "Premium TV URL is required.";
        }
        if (!tvData?.non_premimum_match_tv_url) {
            newErrors.non_premimum_match_tv_url = "Normal TV URL is required.";
        }
        if (!tvData?.match_scoreboard_url) {
            newErrors.match_scoreboard_url = "Score URL is required.";
        }
        setErrors(newErrors);
        if (newErrors.premimum_match_tv_url || newErrors.non_premimum_match_tv_url || newErrors.match_scoreboard_url) {
            return;
        }
        getUpdateScore();
    };

    const getEventBlock = async (event: string, mrktName: string, mid: string, sid?: string) => {
        const payload = {
            event,
            filter: {
                [mrktName]: mid,
            },

        };
        try {
            const { response } = await authServices.getEventBlock(payload);
            if (response) {
                if (response.status) {
                    snackbarUtil.success(response.msg);
                    getSport();
                    if(event === "Series"){
                        getSeriesData(sportIds);
                    }else if(event === "Match" && sid){
                        getMatchesData(sportIds, sid);
                    }else if(event === "Market" && sid)(
                        getMarketData(sid)
                    )   
                } else {
                    snackbarUtil.error(response?.msg);
                }
            }
        } catch (error) {
            console.error("Error fetching event block data:", error);
        }
    };
    const handleActiveSport = (sport: { sport_id: string; is_active: number }, e: any) => {
        const newStatus = sport?.is_active === 1 ? 0 : 1;
        setActiveSport(e.target.checked)
        getEventBlock("Sport", "sport_id", sport?.sport_id);
    };
    

    return (
        <div className="page-content">
            <Container fluid>
                <div className="position-relative mb-2">
                    <BreadCrumb title={id ? `Block Market (${userName})` : "Import Market"} pageTitle="Dashboard" back />
                </div>
                <Accordion defaultActiveKey='0' className="custom-accordion-border accordion-border-box accordion-dark">
                    {
                        sportName?.map((sport) => {
                            return (
                                <Accordion.Item key={sport?.name} eventKey={sport?.name} className="position-relative">
                                    <Accordion.Header onClick={() => handleSportSeriesData(sport?.sport_id, sport?.is_active)}>{sport?.name}</Accordion.Header>
                                    <Accordion.Body>
                                        <div className="mb-2 d-flex align-items-center">

                                            <ToggleSwitch On='On' Off='Off' className="me-2" id={`match-${sport?.name}`} onChange={(e: any) => handleActiveSport(sport, e)} checked={sport?.is_active} />


                                            <div className="ms-3 w-25">
                                                <div className="form-icon">
                                                    <Form.Control value={seriesSearchTerm}
                                                onChange={(e) => setSeriesSearchTerm(e.target.value)} type="text" className="form-control form-control-icon rounded-pill" placeholder="Search Series.." />
                                                    <i className="ri-search-line"></i>
                                                </div>
                                            </div>
                                        </div>
                                        <Accordion className="custom-accordion-border accordion-border-box accordion-dark">
                                            {
                                                sportSeries[sport.sport_id]?.filter(series => series?.name?.toLowerCase().includes(seriesSearchTerm.toLowerCase())).map((series) => {
                                                    return (
                                                        <Accordion.Item key={series?.series_id} eventKey={series?.name} className="position-relative">
                                                            <Accordion.Header onClick={() => handleMatches(series?.series_id, series?.sport_id)}>{series?.name} <span className="mx-2 text-muted">|</span> <span className="">{series?.series_id}</span>
                                                            </Accordion.Header>
                                                            <Accordion.Body>
                                                                <div className="mb-2 d-flex align-items-center">
                                                                    <ToggleSwitch On="On" Off="Off" id={`series-${series?.name}`} onChange={() => handelCreateSeries(series?.series_id, series?.series_id, series?.sport_id)} checked={series?.is_active} />
                                                                    <div className="ms-2 w-25">
                                                                        <div className="form-icon">
                                                                            <Form.Control  value={matchSearchTerm}
                                                                onChange={(e) => setMatchSearchTerm(e.target.value)}  type="text" className="form-control form-control-icon rounded-pill" placeholder="Search Match.." />
                                                                            <i className="ri-search-line"></i>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <Accordion className="custom-accordion-border accordion-border-box accordion-dark">
                                                                    {
                                                                        sportMatches[series.series_id]?.filter(match => match.name.toLowerCase().includes(matchSearchTerm.toLowerCase())).map((matches)  => {
                                                                            return (
                                                                                <Accordion.Item eventKey={matches?.match_id} className="position-relative">
                                                                                    <Accordion.Header onClick={() => handleMarketData(matches?.match_id)}>{matches?.name} <span className="mx-2 text-muted">|</span> <span className="">{matches?.match_id}</span> </Accordion.Header>
                                                                                    <Accordion.Body>
                                                                                        <div className="d-flex align-items-center mb-2">
                                                                                            <ToggleSwitch On="On" Off="Off" id={matches?.match_name}
                                                                                                onChange={() => handelCreate(matches?.match_id, series?.series_id, matches?.sport_id)}
                                                                                                checked={matches?.is_active} />
                                                                                            <Dropdown drop="start" className="ms-3">
                                                                                                <Dropdown.Toggle variant="primary" className="e-caret-hide btn-sm">
                                                                                                    <i className="fs-lg ri-more-2-fill"></i>
                                                                                                </Dropdown.Toggle>
                                                                                                <Dropdown.Menu className="relative">
                                                                                                    <Dropdown.Item className="border-bottom py-2" onClick={() => handleTvScore(matches?.match_id, matches?.match_name)}>Update TV & Score</Dropdown.Item>
                                                                                                </Dropdown.Menu>

                                                                                            </Dropdown>

                                                                                        </div>
                                                                                        {
                                                                                            marketData?.map((market) => {
                                                                                                return (
                                                                                                    <ListGroup className="list-group-fill-success">
                                                                                                        <ListGroup.Item as='a' className="list-group-item-action">
                                                                                                            <div className="d-flex align-items-center">
                                                                                                                <strong className="me-3">{market?.name} <span className="mx-2">|</span> <span className="text-muted">({market?.market_id})</span> </strong>
                                                                                                                <ToggleSwitch On="On" Off="Off" id={`series-${market?.name}`} onChange={() => handelCreateMarket(market?.market_id, series?.series_id, market?.match_id)} checked={market?.is_active} />


                                                                                                                <Dropdown drop="start" className="ms-auto">
                                                                                                                    <Dropdown.Toggle variant="primary" className="e-caret-hide btn-sm">
                                                                                                                        <i className="fs-lg ri-more-2-fill"></i>
                                                                                                                    </Dropdown.Toggle>

                                                                                                                    <Dropdown.Menu>

                                                                                                                        <Dropdown.Item className="py-2" onClick={() => toggleNews("market_id", "market", market?.market_id, market?.match_id)}>Update News</Dropdown.Item>
                                                                                                                    </Dropdown.Menu>
                                                                                                                </Dropdown>
                                                                                                                
                                                                                                            </div>
                                                                                                        </ListGroup.Item>
                                                                                                    </ListGroup>
                                                                                                )
                                                                                            })
                                                                                        }
                                                                                    </Accordion.Body>
                                                                                </Accordion.Item>
                                                                            )
                                                                        })
                                                                    }
                                                                </Accordion>
                                                            </Accordion.Body>
                                                        </Accordion.Item>
                                                    )
                                                })
                                            }
                                        </Accordion>
                                    </Accordion.Body>
                                </Accordion.Item>
                            )
                        })
                    }
                </Accordion>
            </Container>
            <Modal show={showTvScore}>
                <Modal.Header className="modal-title fw-bold d-flex justify-content-between pb-1">
                    <div>Update TV & Score</div>
                    <Button variant="light btn-sm" onClick={() => setTvScore(!showTvScore)}>
                        <i className="ri-close-line fs-xl align-middle"></i>
                    </Button>
                </Modal.Header>
                <Modal.Body>
                    <div className="form-floating">
                        <Form.Control
                            name="premimum_match_tv_url"
                            as="textarea"
                            rows={2}
                            style={{ height: 'auto' }}
                            id="PremiumTV"
                            value={tvData?.premimum_match_tv_url}
                            placeholder="Premium TV"
                            onChange={handleInputChange}
                            isInvalid={!!errors.premimum_match_tv_url}
                        />
                        {errors.premimum_match_tv_url && <div className="text-danger">{errors.premimum_match_tv_url}</div>}
                        <Form.Label htmlFor="Content">Premium TV</Form.Label>
                    </div>
                    <div className="form-floating mt-3">
                        <Form.Control
                            name="non_premimum_match_tv_url"
                            as="textarea"
                            rows={2}
                            style={{ height: 'auto' }}
                            id="NormalTV"
                            placeholder="Normal TV"
                            value={tvData?.non_premimum_match_tv_url}
                            onChange={handleInputChange}
                            isInvalid={!!errors.non_premimum_match_tv_url}
                        />
                        {errors.non_premimum_match_tv_url && <div className="text-danger">{errors.non_premimum_match_tv_url}</div>}
                        <Form.Label htmlFor="Content">Normal TV</Form.Label>
                    </div>
                    <div className="form-floating mt-3">
                        <Form.Control
                            name="match_scoreboard_url"
                            as="textarea"
                            rows={2}
                            style={{ height: 'auto' }}
                            id="Score"
                            placeholder="Score"
                            value={tvData?.match_scoreboard_url}
                            onChange={handleInputChange}
                            isInvalid={!!errors.match_scoreboard_url}
                        />
                        {errors.match_scoreboard_url && <div className="text-danger">{errors.match_scoreboard_url}</div>}  {/* Error message */}
                        <Form.Label htmlFor="Content">Score</Form.Label>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="subtle-primary" onClick={handleUpdateTv}>Update</Button>
                    <Button variant="subtle-dark" onClick={() => setTvScore(!showTvScore)}>Cancel</Button>
                </Modal.Footer>
            </Modal>
            <Modal show={showNews} onHide={() => setShowNews(false)} className="zoomIn" scrollable>
                <Modal.Header className="modal-title fw-bold d-flex justify-content-between pb-1">
                    <span>Update News</span>
                    <Button variant="light btn-sm" onClick={() => setShowNews(false)}>
                        <i className="ri-close-line fs-xl align-middle"></i>
                    </Button>
                </Modal.Header>
                <Modal.Body className='fs-md'>
                    <div className="form-floating">
                        <Form.Control
                            onChange={(e) => setNewsValues(e.target.value)} value={newsValues} as="textarea" rows={5} id="Content" style={{ height: 'auto' }} placeholder='Content' />
                        <Form.Label htmlFor="Content">Content</Form.Label>

                    </div>
                </Modal.Body>
                <Modal.Footer className='border-top'>
                    <Button variant='light' onClick={() => setShowNews(false)}>Cancel</Button>
                    <Button className='ms-2' onClick={handleUpdateNews}>Update</Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default BlockMarket;