import { useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import snackbarUtil from 'Utils/snackBarUtil';

interface Props {
    toggleBanner: () => void;
    showBanner: boolean;
    userId: any;
    bannerDataUpdate: any;
    getUploadContentAgent: (title: string, slug: string, category: boolean | string, submissionData1: any) => Promise<void>;
}

const UpdateBanner = ({ toggleBanner, showBanner, userId, bannerDataUpdate, getUploadContentAgent }: Props) => {
    const [formData, setFormData] = useState<any>({
        image: null,
        category: false,
    });

    const [errors, setErrors] = useState<any>({
        image: false,
        device: false,
    });

    const handleChange = (field: string, value: any) => {
        setFormData({
            ...formData,
            [field]: value,
        });

        setErrors({
            ...errors,
            [field]: false,
        });
    };

    const validateForm = () => {
        const newErrors = {
            image: !formData.image,
            device: userId === 8 && !formData.device,
        };

        setErrors(newErrors);

        return !Object.values(newErrors).includes(true);
    };

    const handleSubmit = () => {
        if (validateForm()) {
            const submissionData = new FormData();
            submissionData.append('image', formData.image);

            if (userId === 8) {
                getUploadContentAgent(
                    bannerDataUpdate?.title,
                    bannerDataUpdate?.slug,
                    "1",
                    submissionData
                );
            } else {
                getUploadContentAgent(
                    bannerDataUpdate?.title,
                    bannerDataUpdate?.slug,
                    formData?.category,
                    submissionData
                );
            }

        }
    };

    return (
        <Modal show={showBanner} onHide={toggleBanner} className="zoomIn" scrollable>
            <Modal.Header className="modal-title fw-bold pb-2">
                <div>Update Banner - {bannerDataUpdate?.title}</div>
                <Button variant="light btn-sm" onClick={toggleBanner}>
                    <i className="ri-close-line fs-xl align-middle"></i>
                </Button>
            </Modal.Header>
            <Modal.Body className="pt-1">
                <div className="mt-2">
                    <Form.Label>Title</Form.Label>
                    <Form.Control name="title" type="text" value={bannerDataUpdate?.title} disabled />
                </div>

                <div className="mt-2">
                    <Form.Label>Slug</Form.Label>
                    <Form.Control name="slug" type="text" value={bannerDataUpdate?.slug} disabled />
                </div>

                {userId === 8 && (
                    <div className="mt-3">
                        <Form.Label>Select Device</Form.Label>
                        <div className="d-flex">
                            <Form.Check
                                type="radio"
                                name="device"
                                id="desktop"
                                label="Desktop"
                                checked={formData.device === 'desktop'}
                                onChange={() => handleChange('device', 'desktop')}
                            />
                            <Form.Check
                                type="radio"
                                name="device"
                                id="mobile"
                                label="Mobile"
                                checked={formData.device === 'mobile'}
                                onChange={() => handleChange('device', 'mobile')}
                                className="ms-3"
                            />
                        </div>
                        {errors.device && <div className="invalid-feedback d-block">Please select a device.</div>}
                    </div>
                )}

                <div className="mt-2">
                    <Form.Label>Image</Form.Label>
                    <Form.Control
                        name="image"
                        type="file"
                        accept="image/*"
                        onChange={(e: any) => {
                            const file = e.target.files[0];
                            if (file) {
                                const fileType = file.type.split('/')[0];
                                if (fileType !== 'image') {
                                    snackbarUtil.error('Please select a valid image file.');
                                    setFormData({
                                        ...formData,
                                        image: null,
                                    });
                                } else {
                                    handleChange('image', file);
                                }
                            }
                        }}
                        isInvalid={errors.image}
                    />
                    {errors.image && (
                        <Form.Control.Feedback type="invalid">Please upload an image.</Form.Control.Feedback>
                    )}
                </div>

                {userId === 0 && (
                    <div className="mt-3 d-flex align-items-center">
                        <Form.Label>Category</Form.Label>
                        <Form.Check
                            type="checkbox"
                            id="Category"
                            checked={formData.category}
                            onChange={(e) => handleChange('category', e.target.checked)}
                            className="ms-2"
                        />
                    </div>
                )}
            </Modal.Body>
            <Modal.Footer className="border-top">
                <Button onClick={handleSubmit}>Update</Button>
                <Button variant="subtle-dark" onClick={toggleBanner}>
                    Cancel
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default UpdateBanner;
