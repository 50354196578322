import BreadCrumb from "Common/BreadCrumb";
import { Container } from "react-bootstrap";
import LoginHostry from "./LoginHostry";

const UserLoginHistory = () => {
  const userDetails = localStorage.getItem("adminDetails");
  const username = userDetails && JSON.parse(userDetails)?.user_name;

  return (
    <div className="page-content">
      <Container fluid>
        <div
          className="position-relative mb-2 d-flex justify-content-between align-items-center"
          style={{ minHeight: "43px" }}
        >
          <BreadCrumb
            title={`Login History (${username})`}
            pageTitle="Dashboard"
            back
          />
        </div>

        <LoginHostry username={username} activeTab={"LoginHistory"} />
      </Container>
    </div>
  );
};

export default UserLoginHistory;
