import MethodEditModal from "Common/Modals/MethodEditModal";
import TableContainer from "Common/TableContainer";
import { useMemo, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { authServices } from "Utils/auth/services";
import snackbarUtil from "Utils/snackBarUtil";

interface Props {
  depositMethod: DepositMethod[];
  getDepositMethod: () => void;
}

const DepositMethodTable = ({ depositMethod, getDepositMethod }: Props) => {
  const userId = localStorage.getItem("userId");

  const [showImage, setImage] = useState<boolean>(false);
  const [imageLink, setImageLink] = useState<string>("");
  const [showDelete, setDelete] = useState<boolean>(false);
  const [deleteMessage, setDeleteMessage] = useState<string>("");
  const [deleteMethodId, setDeleteMethodId] = useState<string>("");
  const [passwordShow, setPasswordShow] = useState<boolean>(false);
  const [formData, setFormData] = useState<string>("");
  const [formErrors, setFormErrors] = useState<string>("");
  const [touchedFields, setTouchedFields] = useState<boolean>(false);
  const [showRestore, setRestore] = useState<boolean>(false);
  const [showEdit, setShowEdit] = useState<boolean>(false);
  const [editDetail, setEditDetail] = useState<DepositMethod | null>(null);

  const toggleImage = (image?: string) => {
    setImage(!showImage);
    setImageLink(image || "");
  };

  const toggleEdit = (data: DepositMethod) => {
    setShowEdit(!showEdit);
    setEditDetail(data);
  };

  const toggleDelete = (message?: string, id?: string) => {
    setDelete(!showDelete);
    setDeleteMessage(message || "");
    setDeleteMethodId(id || "");
    setFormData("");
  };

  const toggleRestore = (id?: string) => {
    setRestore(!showRestore);
    setDeleteMethodId(id || "");
    setFormData("");
  };

  const changeStatus = async (status: boolean, id: string) => {
    const { response } = await authServices.updateDepositMethod({
      user_id: userId || "",
      status: status,
      id: id,
    });
    if (response?.status) {
      snackbarUtil.success(response.msg);
      getDepositMethod();
    } else {
      snackbarUtil.error(
        response?.msg || "An error occurred. Please try again."
      );
    }
  };

  const getDeleteExpiry = async (isBank: boolean, id: string) => {
    const { response } = await authServices.getMethodDeleteExpiry({
      is_bank_method: isBank,
    });
    if (response?.status) {
      snackbarUtil.success(response.msg);
      toggleDelete(response.msg, id);
    } else {
      snackbarUtil.error(
        response?.msg || "An error occurred. Please try again."
      );
    }
  };

  const deletepaymentMethod = async (isRestore?: boolean) => {
    const { response } = await authServices.deleteBankMethod({
      user_id: userId || "",
      password: formData,
      bank_detail_id: deleteMethodId,
      ...(isRestore && { is_restore: isRestore }),
    });
    if (response?.status) {
      snackbarUtil.success(response.msg);
      getDepositMethod();
      showDelete ? toggleDelete() : toggleRestore();
    } else {
      snackbarUtil.error(
        response?.msg || "An error occurred. Please try again."
      );
    }
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { value } = e.target;
    setFormData(value);
    setTouchedFields(true);
    validateForm(value);
  };

  const validateForm = (data: string) => {
    let isValid = true;
    let errors: string = "";

    if (!data) {
      errors = "Password is required";
      isValid = false;
    } else {
      errors = "";
    }

    setFormErrors(errors);
    return isValid;
  };

  const handleSubmit = () => {
    if (validateForm(formData)) {
      showDelete ? deletepaymentMethod() : deletepaymentMethod(true);
    } else {
      setTouchedFields(true);
    }
  };

  const deposit = useMemo(() => {
    if (!depositMethod || !Array.isArray(depositMethod)) {
      return [];
    }
    return depositMethod.map((items, index: number) => ({
      id: items?._id,
      method: items?.method_name,
      bankHolderName: items?.bank_holder_name,
      bankName: items?.bank_name,
      ifscCode: items?.ifsc_code,
      accountNo: items?.account_no,
      upi: items?.others,
      qr: items?.self_host
        ? process.env.REACT_APP_API_BASEURL + "/" + items?.payment_qr
        : items?.payment_qr,
      status: items?.status,
      deleted: items?.deleted,
      allData: items,
    }));
  }, [depositMethod]);

  const columns = useMemo(
    () => [
      {
        header: "Method",
        accessorKey: "method",
        enableColumnFilter: false,
      },
      {
        header: "Bank Holder Name",
        accessorKey: "bankHolderName",
        enableColumnFilter: false,
      },
      {
        header: "Bank Name",
        accessorKey: "bankName",
        enableColumnFilter: false,
      },
      {
        header: "IFSC Code",
        accessorKey: "ifscCode",
        enableColumnFilter: false,
      },
      {
        header: "Account No",
        accessorKey: "accountNo",
        enableColumnFilter: false,
      },
      {
        header: "UPI/PAYPTM ETC",
        accessorKey: "upi",
        enableColumnFilter: false,
      },
      {
        header: "QR",
        accessorKey: "qr",
        enableColumnFilter: false,
        cell: (cell: any) => {
          return (
            <div>
              <Button
                className="btn-sm"
                variant="subtle-primary"
                onClick={() =>
                  cell?.row?.original?.qr &&
                  toggleImage(cell?.row?.original?.qr)
                }
              >
                <i className="fs-md align-middle ri-image-line"></i>
              </Button>
            </div>
          );
        },
      },
      {
        header: "Status",
        accessorKey: "status",
        enableColumnFilter: false,
        cell: (cell: any) => {
          return (
            <span
              className={`text-${
                cell?.row?.original?.status ? "success" : "danger"
              }`}
            >
              {cell?.row?.original?.status ? "Active" : "Inactive"}
            </span>
          );
        },
      },
      {
        header: "Action",
        accessorKey: "action",
        enableColumnFilter: false,
        cell: (cell: any) => {
          return (
            <div>
              <Button
                className="btn-sm"
                variant="subtle-secondary"
                onClick={() =>
                  changeStatus(
                    !cell?.row?.original?.status,
                    cell?.row?.original?.id
                  )
                }
              >
                <i
                  className={`fs-md align-middle ${
                    cell?.row?.original?.status
                      ? "ri-spam-2-fill"
                      : "ri-check-double-fill"
                  }`}
                ></i>
              </Button>
              <Button className="btn-sm ms-2" variant="subtle-dark" onClick={()=> toggleEdit(cell?.row?.original?.allData)}>
                <i className="fs-md align-middle ri-edit-2-fill"></i>
              </Button>
              {cell?.row?.original?.deleted ? (
                <Button
                  className="btn-sm ms-2"
                  variant="subtle-danger"
                  onClick={() => toggleRestore(cell?.row?.original?.id)}
                >
                  <i className="fs-md align-middle ri-restart-fill"></i>
                </Button>
              ) : (
                <Button
                  className="btn-sm ms-2"
                  variant="subtle-danger"
                  onClick={() =>
                    getDeleteExpiry(
                      cell?.row?.original?.method === "Bank",
                      cell?.row?.original?.id
                    )
                  }
                >
                  <i className="fs-md align-middle ri-delete-bin-5-fill"></i>
                </Button>
              )}
            </div>
          );
        },
      },
    ],
    []
  );

  return (
    <>
      <TableContainer
        columns={columns || []}
        data={deposit || []}
        isBordered={false}
        customPageSize={deposit?.length || 0}
        isPagination={false}
        tableClass="table-centered align-middle table-nowrap table-striped mb-3"
        theadClass="text-muted table-light"
        SearchPlaceholder="Search Products..."
      />

      <Modal
        show={showImage}
        onHide={toggleImage}
        className="zoomIn"
        scrollable
      >
        <Modal.Header className="modal-title fw-bold pb-2">
          <div>Payment QR</div>
          <Button variant="light btn-sm" onClick={() => toggleImage()}>
            <i className="ri-close-line fs-xl align-middle"></i>
          </Button>
        </Modal.Header>
        <Modal.Body className="pt-1">
          <img src={imageLink} alt="Image" className="w-100" />
        </Modal.Body>
        <Modal.Footer className="border-top">
          <Button variant="subtle-dark" onClick={() => toggleImage()}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showDelete || showRestore}
        onHide={showDelete ? toggleDelete : toggleRestore}
        className="zoomIn"
        scrollable
      >
        <Modal.Header className="modal-title fw-bold d-flex justify-content-between pb-1">
          <span>{showDelete ? "Delete" : "Restore"} Payment Method</span>
          <Button
            variant="light btn-sm"
            onClick={() => (showDelete ? toggleDelete() : toggleRestore())}
          >
            <i className="ri-close-line fs-xl align-middle"></i>
          </Button>
        </Modal.Header>
        <Modal.Body className="fs-md">
          {showDelete && <p className="text-danger mt-3">{deleteMessage}</p>}
          <div className="form-floating mt-3 position-relative">
            <Form.Control
              name="password"
              type={passwordShow ? "text" : "password"}
              placeholder="Password"
              value={formData}
              onChange={handleInputChange}
              isInvalid={!!formErrors && touchedFields}
              autoComplete="new-password"
              id="new-password"
            />
            <Form.Label>Password</Form.Label>
            <Button
              variant="link"
              className="position-absolute end-0 top-0 bottom-0 text-decoration-none text-muted password-addon"
              type="button"
              id="password-addon"
              onClick={() => setPasswordShow(!passwordShow)}
            >
              <i
                className={`${
                  !passwordShow ? "ri-eye-off-fill" : "ri-eye-fill"
                } align-middle`}
              ></i>
            </Button>
            {formErrors && touchedFields && (
              <Form.Control.Feedback type="invalid">
                {formErrors}
              </Form.Control.Feedback>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer className="text-end border-top">
          <Button variant="subtle-danger" onClick={handleSubmit}>
            {showDelete ? "Delete" : "Restore"}
          </Button>
        </Modal.Footer>
      </Modal>

      <MethodEditModal
        showMethod={showEdit}
        clickHandler={setShowEdit}
        category={
          editDetail?.method_name === "Bank" ? "BANK" : ''
        }
        selectedMethod={editDetail?._id || ''}
        allData={editDetail}
        getDepositMethod={getDepositMethod}
      />
    </>
  );
};

export default DepositMethodTable;
