export const levelItem = [
   
    {
        label: "White Label",
        value: 8
    },
    {
        label: "Sub Admin",
        value: 7
    },
    {
        label: "Hyper",
        value: 6
    },
    {
        label: "Senior Super",
        value: 5
    },
    {
        label: "Super",
        value: 4
    },
    {
        label: "Master",
        value: 3
    },
    {
        label: "Dealer",
        value: 2
    },
    {
        label: "Client",
        value: 1
    },
]