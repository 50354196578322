import BreadCrumb from "Common/BreadCrumb";
import FraudBetTable from "Common/Tables/FraudBetTable";
import { useEffect, useMemo, useState } from "react";
import { Container, Button, Card, Form, Row, Col } from "react-bootstrap";
import Select from "react-select";
import Flatpickr from "react-flatpickr";
import { useLocation } from "react-router-dom";
import { authServices } from "Utils/auth/services";
import { currentDateTime, getStartTimeOfDay } from "Utils/datefilter";
import OpenBetsTable from "Common/Tables/OpenBetsTable";
import {
  clearSearchPayload,
  generateOptions,
  updateSearchPayload,
} from "Utils/searchFilter";
import Loader from "Common/Loader";

const DeletedBet = () => {
  let location = useLocation();
  const currentDate = new Date();
  const sevenDaysAgo = new Date(currentDate.setDate(currentDate.getDate() - 7));

  const [startDate, setStartDate] = useState<string | Date>(
    getStartTimeOfDay(sevenDaysAgo)
  );
  const [endDate, setEndDate] = useState<string | Date>(currentDateTime());
  const [showFilter, setFilter] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [fraudBetType, setFraudBetType] = useState<number>(0);
  const [fraudBetsData, setFraudBetsData] = useState<FraudBets[]>([]);
  const [betsData, setBetsData] = useState<OpenBetsData[]>([]);
  const [searchList, setSearchList] = useState<SportSearchList>();
  const [searchPayload, setSearchPayload] = useState<SportsSearchPayloadData>(
    {}
  );
  const [limit, setLimit] = useState<number>(50);
  const [page, setPage] = useState<number>(1);
  const [listTotal, setListTotal] = useState<ProfitLossTotal>({
    total: 0,
    page: 0,
  });

  const getHeading = () => {
    switch (location.pathname) {
      case "/fraud-bet":
        return "Fraud Bet";
      case "/delete-bet":
        return "Delete Bet";
      case "/void-bet":
        return "Void Bet";
      default:
        return "Fraud Bet";
    }
  };

  const eventOptions = useMemo(
    () =>
      generateOptions(
        searchList?.events_m_f || [],
        "event_id",
        "event_name",
        "type"
      ),
    [searchList?.events_m_f]
  );
  const matchOptions = useMemo(
    () => generateOptions(searchList?.matches || [], "match_id", "match_name"),
    [searchList?.matches]
  );
  const seriesOptions = useMemo(
    () => generateOptions(searchList?.series || [], "series_id", "series_name"),
    [searchList?.series]
  );
  const sportsOptions = useMemo(
    () => generateOptions(searchList?.sports || [], "sport_id", "sport_name"),
    [searchList?.sports]
  );

  const getFraudBets = async (filters: any = {}) => {
    const { response } = await authServices.getFraudBets({
      limit: limit,
      page: page,
      ...filters,
    });
    setIsLoading(false);
    if (response?.status) {
      setFraudBetsData(response?.data?.data || []);
      setListTotal({
        total: response?.data?.metadata[0]?.total
          ? response?.data?.metadata[0]?.total / (filters?.limit || limit)
          : 1,
        page: response?.data?.metadata[0]?.page || page,
      });
    }
  };

  const getBetsList = async (filters: any = {}) => {
    console.log(filters, "filtersfilters")
    const { response } = await authServices.getBets({
      limit: limit,
      page: page,
      from_date: startDate,
      to_date: endDate,
      ...filters,
    });
    setIsLoading(false);
    if (response?.data && response?.data?.data) {
      setBetsData(response?.data?.data);
      setListTotal({
        total: response?.data?.metadata[0]?.total / (filters?.limit || limit),
        page: response?.data?.metadata[0]?.page,
      });
    } else {
      setBetsData([]);
      setListTotal({
        total: 1,
        page: 1,
      });
    }
  };

  const getSportSearchList = async (filters: any = {}) => {
    const { response } = await authServices.getSportsSearchList({
      type: "openBets",
      ...filters,
    });
    if (response?.data && response?.data.length > 0) {
      setSearchList(response?.data[0]);
    }
  };

  useEffect(() => {
    setPage(1);
    setLimit(50);

    if (location.pathname === "/fraud-bet") {
      getFraudBets({
        page: 1,
        search: {
          is_fraud_bet: fraudBetType,
        },
      });
    } else {
      getBetsList({
        ...{
          page: 1,
          search: {
            delete_status: location.pathname === "/delete-bet" ? 1 : 2,
          },
        },
      });

      location.pathname === "/void-bet" && getSportSearchList();
    }
  }, [location.pathname]);

  const handleBetType = (value: number) => {
    setFraudBetType(value);
    setPage(1);
    getFraudBets({
      page: 1,
      search: {
        is_fraud_bet: value,
      },
    });
  };

  const handlePageFilter = (page: number, limit: number) => {
    setIsLoading(true);
    setPage(page);
    setLimit(limit);

    if (location.pathname === "/fraud-bet") {
      getFraudBets({
        page: page,
        limit: limit,
        search: {
          is_fraud_bet: fraudBetType,
        },
      });
    } else {
      getBetsList({
        page: page,
        limit: limit,
        ...{
          search: {
            delete_status: location.pathname === "/delete-bet" ? 1 : 2,
          },
        },
      });
    }
  };

  const handleSearchFilter = (type: SearchPayloadType, id: string) => {
    setIsLoading(true);
    const payload: SportsSearchPayloadData = updateSearchPayload(
      type,
      id,
      searchPayload
    );

    getSportSearchList({ search: payload });
    setSearchPayload(payload);
    setPage(1);
    payload.delete_status = 2;
    getBetsList({
      page: 1,
      search: payload,
    });
  };

  const handleClearSelection = (type: SearchPayloadType) => {
    const payload = clearSearchPayload(type, searchPayload);
    setIsLoading(true);
    getSportSearchList({ search: payload });
    setSearchPayload(payload);
    setPage(1);

    payload.delete_status = 2;
    getBetsList({
      page: 1,
      search: payload,
    });
  };

  const handlefilterSubmit = () => {
    setIsLoading(true);
    let filterData: SportsSearchPayloadData = searchPayload;
    filterData.delete_status = 2;
    setPage(1);

    let search = filterData;

    if (location.pathname === "/delete-bet") {
      search = {
        delete_status: 1,
      };
    }

    getBetsList({
      ...{
        page: 1,
        search: search,
      },
    });
  };

  const handleAllClearSelection = (type: SearchPayloadType) => {
    setIsLoading(true);
    const payload = clearSearchPayload(type, searchPayload);

    getSportSearchList({ search: payload });
    setSearchPayload(payload);
    setStartDate(getStartTimeOfDay(new Date()));
    setEndDate(currentDateTime());
    setPage(1);

    getBetsList({
      from_date: getStartTimeOfDay(new Date()),
      to_date: currentDateTime(),
      page: 1,
      ...{
        search: {
          delete_status: location.pathname === "/delete-bet" ? 1 : 2,
        },
      },
    });
  };

  return (
    <div className="page-content">
      <Container fluid>
        <div className="position-relative mb-2 d-flex justify-content-between align-items-center">
          <BreadCrumb title={getHeading()} pageTitle="Dashboard" back />
          <div>
            {location.pathname == "/fraud-bet" ? (
              <div className="d-flex">
                <div className="form-check me-3">
                  <Form.Check
                    type="radio"
                    id="all"
                    name="fraudBet"
                    checked={fraudBetType === 0}
                    onChange={() => handleBetType(0)}
                  />
                  <Form.Label className="form-check-label" htmlFor="all">
                    All
                  </Form.Label>
                </div>
                <div className="form-check me-3">
                  <Form.Check
                    type="radio"
                    id="sameIP"
                    name="fraudBet"
                    checked={fraudBetType === 1}
                    onChange={() => handleBetType(1)}
                  />
                  <Form.Label className="form-check-label" htmlFor="sameIP">
                    Same IP
                  </Form.Label>
                </div>
                <div className="form-check">
                  <Form.Check
                    type="radio"
                    id="trading"
                    name="fraudBet"
                    checked={fraudBetType === 2}
                    onChange={() => handleBetType(2)}
                  />
                  <Form.Label className="form-check-label" htmlFor="trading">
                    Trading
                  </Form.Label>
                </div>
              </div>
            ) : (
              <Button
                variant="subtle-dark"
                onClick={() => setFilter(!showFilter)}
              >
                <i className="fs-lg align-middle ri-filter-3-line"></i> Filter
              </Button>
            )}
          </div>
        </div>

        {showFilter ? (
          <Card>
            <Card.Body>
              <Form>
                <Row className="gy-3 align-items-end">
                  <Col lg={3}>
                    <Form.Label>Start Date</Form.Label>
                    <Flatpickr
                      className="form-control"
                      pl
                      options={{
                        enableTime: true,
                        dateFormat: "Y-m-d H:i:s",
                        defaultDate: [startDate],
                        maxDate: endDate,
                      }}
                      value={startDate}
                      onChange={([date]: [Date]) => {
                        setStartDate(new Date(date));
                      }}
                    />
                  </Col>
                  <Col lg={3}>
                    <Form.Label>End Date</Form.Label>
                    <Flatpickr
                      className="form-control"
                      pl
                      options={{
                        enableTime: true,
                        dateFormat: "Y-m-d H:i:s",
                        defaultDate: [endDate],
                        maxDate: currentDateTime()
                      }}
                      value={endDate}
                      onChange={([date]: [Date]) => {
                        setEndDate(new Date(date));
                      }}
                    />
                  </Col>

                  {location.pathname === "/void-bet" && (
                    <>
                      {" "}
                      <Col lg={3}>
                        <Form.Label>Search Sport Name</Form.Label>
                        <Select
                          isClearable
                          options={sportsOptions}
                          value={
                            sportsOptions.find(
                              (option: any) =>
                                option.value === searchPayload?.sport_id
                            ) || null
                          }
                          className="customSelect"
                          onChange={(e: any) => {
                            if (e) {
                              handleSearchFilter("sport_id", e.value);
                            } else {
                              handleClearSelection("sport_id");
                            }
                          }}
                        />
                      </Col>
                      <Col lg={3}>
                        <Form.Label>Search Series Name</Form.Label>
                        <Select
                          isClearable
                          options={seriesOptions}
                          value={
                            seriesOptions.find(
                              (option: any) =>
                                option.value === searchPayload?.series_id
                            ) || null
                          }
                          className="customSelect"
                          onChange={(e: any) => {
                            if (e) {
                              handleSearchFilter("series_id", e.value);
                            } else {
                              handleClearSelection("series_id");
                            }
                          }}
                        />
                      </Col>
                      <Col lg={3}>
                        <Form.Label>Search Match Name</Form.Label>
                        <Select
                          isClearable
                          options={matchOptions}
                          value={
                            matchOptions.find(
                              (option: any) =>
                                option.value === searchPayload?.match_id
                            ) || null
                          }
                          className="customSelect"
                          onChange={(e: any) => {
                            if (e) {
                              handleSearchFilter("match_id", e.value);
                            } else {
                              handleClearSelection("match_id");
                            }
                          }}
                        />
                      </Col>
                      <Col lg={3}>
                        <Form.Label>Search Market Name</Form.Label>
                        <Select
                          isClearable
                          options={eventOptions}
                          value={
                            eventOptions.find(
                              (option: any) =>
                                option.value === searchPayload?.fancy_id ||
                                option.value === searchPayload?.market_id
                            ) || null
                          }
                          className="customSelect"
                          onChange={(e: any) => {
                            if (e) {
                              handleSearchFilter(
                                e.type == "1" ? "market_id" : "fancy_id",
                                e.value
                              );
                            } else {
                              handleClearSelection("market_id");
                            }
                          }}
                        />
                      </Col>
                    </>
                  )}
                  <Col lg={3}>
                    <Button
                      className="me-2 btn-sm"
                      style={{ height: "38px" }}
                      onClick={() => handlefilterSubmit()}
                    >
                      <i className="fs-lg align-middle ri-filter-line"></i>{" "}
                      Submit
                    </Button>
                    <Button
                      variant="outline-primary"
                      className="me-2 btn-sm"
                      style={{ height: "38px" }}
                      onClick={() => handleAllClearSelection("sport_id")}
                    >
                      <i className="fs-lg align-middle ri-refresh-line"></i>{" "}
                      Clear
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Card.Body>
          </Card>
        ) : null}

        <Card>
          <Card.Body>
            {/* {location.pathname === "/fraud-bet" ? (
              <FraudBetTable
                data={fraudBetsData}
                page={page}
                limit={limit}
                listTotal={listTotal}
                handlePageFilter={handlePageFilter}
              />
            ) : ( */}
              <OpenBetsTable
                openBetsData={betsData}
                page={page}
                limit={limit}
                listTotal={listTotal}
                handlePageFilter={handlePageFilter}
                getBetsList={getBetsList}
              />
            {/* )} */}
          </Card.Body>
        </Card>
        {isLoading && <Loader />}
      </Container>
    </div>
  );
};

export default DeletedBet;
