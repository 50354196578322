import { useEffect, useRef, useState } from "react";
import { Container } from "react-bootstrap";

const Dashboard = () => {
    // State to control the visibility of the filter div
  const [showFilter, setShowFilter] = useState(false);

  // Ref to keep track of the filter div
  const filterRef = useRef<HTMLDivElement>(null);

  // Toggle filter visibility on button click
  const toggleFilter = () => {
    setShowFilter((prev) => !prev);
  };

  // Close filter when clicking outside of it
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      // Type assertion to use 'event.target' as an 'HTMLElement'
      if (filterRef.current && !filterRef.current.contains(event.target as Node)) {
        setShowFilter(false);
      }
    };

    // Attach event listener to the document
    document.addEventListener("mousedown", handleClickOutside);

    // Clean up event listener on component unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  
    return (
        <div className="page-content">
            <Container fluid>
                <h2>Styles</h2>

                <div>
                    <button onClick={toggleFilter}>Toggle Filter</button>
                    
                    {showFilter && (
                        <div ref={filterRef} className="filterDiv">
                        <p>This is the filter content.</p>
                        </div>
                    )}
                </div>
            </Container>
        </div>
    );
};

export default Dashboard;