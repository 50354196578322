import { Dispatch, FC, SetStateAction, useState } from 'react';
import { Table, Form, Button } from 'react-bootstrap';

interface SportsShare {
  name: string;
  percentage: {
    parent_share: number;
    share: number;
    user_share: number;
    parent_partnership_share?: number;
  }[];
}

interface SportsSharePayload {
  percentage: {
    parent_share: number;
    share: number;
    user_share: number;
    parent_partnership_share?: number;
  }[];
}

interface Props {
  userData: any;
  sportShare: any;
  setSportShare: Dispatch<SetStateAction<any>>;
  type: string;
  validateRow: (index: number, value: any) => void
  errors:any
  setSportError:any,
  ukShareValue:string,
  setUkShareValue:any
  setUserPayload: any

}

const SharingTable: FC<Props> = ({setUserPayload,setUkShareValue,ukShareValue,  userData, sportShare, setSportShare, type, validateRow, errors, setSportError }) => {
  const userDetails = localStorage.getItem("adminDetails");
  const useridType = userDetails && JSON.parse(userDetails)?.user_type_id;

  const updateShareValue = (value: any, index: number) => {
    const updatedShares = [...sportShare];
  
    updatedShares.forEach((share, shareIndex) => {
        const lastShareIndex = share.percentage.length - 1;
        const secondLastShareIndex = share.percentage.length - 2;
  
        if (type === 'uk') {
          share.percentage[1].parent_share = Number(value);
          share.percentage[1].share = Number(value);
          
        } else {
          const parentShare = userData[index]?.percentage[secondLastShareIndex]?.parent_share || 0;
          share.percentage[secondLastShareIndex].user_share = value;
          share.percentage[lastShareIndex].share = value;
          share.percentage[secondLastShareIndex].share = userData[index]?.percentage[sportShare[index]?.percentage?.length -2 ]?.parent_share - value;
          share.percentage[lastShareIndex].user_share = value;
          share.percentage[secondLastShareIndex].parent_share = userData[index]?.percentage[sportShare[index]?.percentage?.length -2 ]?.parent_share;
          share.percentage[lastShareIndex].parent_share = value;
          share.percentage[lastShareIndex].parent_partnership_share = parentShare - Number(value);
          share.percentage[secondLastShareIndex].parent_partnership_share =( useridType === 0 || userData?.user_type_id == 0)?0:userData[index]?.percentage[sportShare[index]?.percentage?.length - 3 ]?.share;
        }
    });
    setSportShare(updatedShares);
  };

  

  const countUp = (index: number) => {
    const currentValue = sportShare[index]?.percentage[1].share;
    if (currentValue < 100) {
      updateShareValue(currentValue + 1, index);
    }
  };

  const countDown = (index: number) => {
    const currentValue = sportShare[index]?.percentage[1].share;
    if (currentValue > 0) {
      updateShareValue(currentValue - 1, index);
    }
  };

  const [ukShareValue1,setUkShareValue1] = useState<number>(0)

  const handleInputChange = (value: string, index: number) => {
    setUkShareValue1(Number(value));
    if (value === '') {
      updateShareValue('', index); 
    } else {
      const numericValue = Number(value);
      if (!isNaN(numericValue)) {
        updateShareValue(numericValue, index);
      }
    }
  };

  const handleUkShareChange = (value: any) => {  
    const numericValue = Number(value);
    if (isNaN(numericValue) || numericValue < 0 || numericValue > 100) {
        setSportError((prev:any) => ({ ...prev, 0: 'UK share must be between 0 and 100.' }));
    } else {
        setUkShareValue(value);
        const updatedShares = [...sportShare];
        updatedShares.forEach((share) => {
            share.percentage[0].share = Number(value);
            share.percentage[1].share = 0;
            share.percentage[0].user_share = Number(value);
            share.percentage[0].parent_share = 0;
            share.percentage[1].parent_share = Number(value);
        });
        setUserPayload((prev:any) => ({
          ...prev,
          partnership: Number(value),
      }));

        setSportShare(updatedShares);
        setSportError((prev:any) => ({ ...prev, 0: null }));
    }
};

const handleKeyDown = (e: any, type: 'uk' | 'session', currentValue: number) => {
  const invalidKeys = ['-', 'e', 'E', '+', '.'];

 
  if (invalidKeys.includes(e.key)) {
    e.preventDefault();
  }

  
  if (type === "session" && (currentValue.toString().length >= 3 && e.key !== 'Backspace')) {
    e.preventDefault();
  }
  if (type === "uk" && (ukShareValue.length >= 3 && e.key !== 'Backspace')) {
    e.preventDefault();
  }
};

  return (
    <div className='table-responsive'>
      {type === 'uk' ?(
        <>
        <div className='input-step step-primary'>
          <Button className='minus'>
            -
          </Button>
          <Form.Control
            type='number'
            name='share'
            className='product-quantity'
            value={ukShareValue}
            min='0'
            max='100'
            onKeyDown={(e) => handleKeyDown(e, "uk", Number(ukShareValue))}
            onChange={(e) => handleUkShareChange(e.target.value)}
          />
          <span className='py-1 px-2'>%</span>
          <Button className='plus'>
            +
          </Button>
        </div>
        {errors[0] && <div className='text-danger'>{errors[0]}</div>}
        </>
      ) :(
        <Table className='table-striped'>
          <thead className='table-light'>
            <tr>
              <th>Game</th>
              <th>Share</th>
              <th className='text-end text-nowrap'>Parent Share</th>
            </tr>
          </thead>
          <tbody>
            {userData?.map((item: any, index: number) => (
              <tr key={index}>
                <td className='w-50'>{item?.name}</td>
                <td className='py-1'>
                  <div className='input-step step-primary'>
                    <Button className='minus' onClick={() => countDown(index)}>
                      -
                    </Button>
                    <Form.Control
                      type='number'
                      name='share'
                      className='product-quantity'
                      value={ukShareValue1}
                      readOnly={index !== 0}
                      // onChange={(e) => handleInputChange(e.target.value, index)}
                      onChange={(e) => handleInputChange(e.target.value, index)}
                      onKeyDown={(e) => handleKeyDown(e, "session", sportShare[index]?.percentage[1].share)}
                    />
                    <span className='py-1 px-2'>%</span>
                    <Button className='plus' onClick={() => countUp(index)}>
                      +
                    </Button>
                  </div>
                  
                </td>
                <td className='text-end'>
                  {userData[index]?.percentage[sportShare[index]?.percentage?.length - 2 ]?.parent_share - Number(ukShareValue1)}%
                </td>
              </tr>
            ))}
          </tbody>
          {errors[0] && <div className='text-danger'>{errors[0]}</div>}
        </Table>
      )}
    </div>
  );
};

export default SharingTable;
