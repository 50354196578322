interface ToggleSwitchProps {
	On?: string;
	Off?: string;
	id?: string;
	checked?: any;
	onChange: any;
	className?: string;
	label?: string;
}

const ToggleSwitch = ({On, Off, id, checked, onChange, className, label} : ToggleSwitchProps) => {
	const css = `
	.toggleSwitch {
		position: relative;
		display: inline-block;
		width: 40px;
		height: 18px;
		input[type="checkbox"] {
			display: none;
		}
		.switch {
			position: absolute;
			cursor: pointer;
			background-color: #ffffff;
			border: solid 1px #c6c6c6;
			border-radius: 25px;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			transition: background-color 0.2s ease;
			&::before {
				position: absolute;
				content: "";
				left: 2px;
				top: 2px;
				width: 12px;
				height: 12px;
				background-color: #c7c7c7;
				border-radius: 50%;
				transition: transform 0.3s ease;
			}
			.on {
				color: #fff;
				position: absolute;
				left: 5px;
				font-size: 11px;
				font-weight: 400;
			}
			.off {
				position: absolute;
				right: 5px;
				font-size: 11px;
				font-weight: 400;
			}
		}
		input[type="checkbox"]:checked + .switch::before {
			transform: translateX(23px);
			background-color: #ffffff;
		}
		input[type="checkbox"]:checked + .switch {
			background-color: #3662ea;
			border: solid 1px #3662ea;
		}
	}
	.switch .on, .toggleSwitch input[type="checkbox"]:checked + .switch .off {
		display: none;
	}
	.toggleSwitch input[type="checkbox"]:checked + .switch .on, switch .off {
		display: block;
	}
  `
  return (
	<>
		<style>
			{css}
		</style>
		<label htmlFor={id} className={`toggleSwitch ${className}`}> {label}
			<input type="checkbox" id={id} checked={checked} onChange={onChange} />
			<span className="switch">
				<span className="on">{On}</span>
				<span className="off">{Off}</span>
			</span>
		</label>
	</>
  );
};

export default ToggleSwitch;