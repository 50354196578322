import React, { useEffect, useState } from 'react';
import { Card, Col, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { authServices } from 'Utils/auth/services';

//import images



const TransactionCode = (props: any) => {
    const [transtionPass, setTranstionPass] = useState<string>("")
    const navigate = useNavigate();

    useEffect(() => {
        const setTransData = async () => {
            try {
                const { response, error } = await authServices.setTransPass();
                if (error) {
                    console.error('Error:', error);
                } else {
                    setTranstionPass(response?.data?.transaction_password);
                }
            } catch (err) {
                console.error('Unexpected Error:', err);
            }
        };

        setTransData();
    }, []);

    const goToDashboard = () => {
        localStorage.removeItem("token");
        localStorage.removeItem("adminDetails");
        localStorage.removeItem("adminRefreshToken");
        localStorage.removeItem("operatorId");
        localStorage.removeItem("userId");
        navigate('/login');
    }
    return (
        <React.Fragment>
            <Col xxl="7" className="mx-auto">
                <Card className="mb-0 border-0 shadow-none mb-0">
                    <Card.Body className="text-center text-secondary pt-lg-5 m-lg-4">
                        <h2 className='textSuccess'>Success! Your password has been updated successfully.</h2>
                        <h3 className='mt-3'>Your transaction password is <span className='text-primary'>{transtionPass}</span>.</h3>
                        <p className='fs-lg'>Please remember this transaction password, from now on all transcation of the website can be done only with this password and keep one thing in mind, do not share this password with anyone.</p>
                        <p className='fs-lg'>Thank you, Team {window.location.hostname}</p>

                        <h2 className='textSuccess mt-5'>Success! आपका पासवर्ड बदला जा चुका है।</h2>
                        <h3 className='mt-3'>आपका लेनदेन पासवर्ड <span className='text-primary'>{transtionPass}</span> है।</h3>
                        <p className='fs-lg'>कृपया इस लेन-देन के पासवर्ड को याद रखें, अब से वेबसाइट के सभी हस्तांतरण केवल इस पासवर्ड से किए जा सकते हैं और एक बात का ध्यान रखें, इस पासवर्ड को किसी के साथ साझा न करें।</p>
                        <p className='fs-lg'>धन्यवाद, टीम {window.location.hostname}</p>

                        <div className="mt-4">
                            <Button className="btn btn-primary" type="submit" onClick={goToDashboard}><i className='ri-logout-circle-line align-middle fs-xl me-1'></i> Back to Login</Button>
                        </div>
                    </Card.Body>
                </Card>
            </Col>
        </React.Fragment>
    );
};

export default TransactionCode;