import { Dispatch } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import snackbarUtil from 'Utils/snackBarUtil';


interface Props{
    toggleBanner: () => void
    showBanner: boolean
    formData: any
    handleChange: (field: string, value: any) => void
    errors: any
    userId: any
    setFormData: Dispatch<any>
    handleSubmit: () => Promise<void>
}


const AddBanner = ({toggleBanner, showBanner,formData,  handleChange,errors, userId, setFormData, handleSubmit }:Props) => {
    

    return (
        <Modal show={showBanner} onHide={toggleBanner} className="zoomIn" scrollable>
                <Modal.Header className="modal-title fw-bold pb-2">
                    <div>Upload Banner</div>
                    <Button variant="light btn-sm" onClick={toggleBanner}>
                        <i className="ri-close-line fs-xl align-middle"></i>
                    </Button>
                </Modal.Header>
                <Modal.Body className="pt-1">
                    <div className="mt-2">
                        <Form.Label>Title</Form.Label>
                        <Form.Control
                            name="title"
                            type="text"
                            value={formData.title}
                            onChange={(e) => handleChange('title', e.target.value)}
                            isInvalid={errors.title}
                        />
                        {errors.title && (
                            <Form.Control.Feedback type="invalid">
                                Title is required.
                            </Form.Control.Feedback>
                        )}
                    </div>

                    <div className="mt-2">
                        <Form.Label>Slug</Form.Label>
                        <Form.Control
                            name="slug"
                            type="text"
                            value={formData.slug}
                            onChange={(e) => handleChange('slug', e.target.value)}
                            isInvalid={errors.slug}
                        />
                        {errors.slug && (
                            <Form.Control.Feedback type="invalid">
                                Slug is required.
                            </Form.Control.Feedback>
                        )}
                    </div>

                    {userId === 8 && (
                        <div className="mt-3">
                            <Form.Label>Select Device</Form.Label>
                            <div className="d-flex">
                                <Form.Check
                                    type="radio"
                                    name="device"
                                    id="desktop"
                                    label="Desktop"
                                    checked={formData.device === "desktop"}
                                    onChange={() => handleChange('device', "desktop")}
                                />
                                <Form.Check
                                    type="radio"
                                    name="device"
                                    id="mobile"
                                    label="Mobile"
                                    checked={formData.device === "mobile"}
                                    onChange={() => handleChange('device', "mobile")}
                                    isInvalid={errors.device}
                                    className="ms-3"
                                />
                            </div>
                            {errors.device && (
                                <div className="invalid-feedback d-block">Please select a device.</div>
                            )}
                        </div>
                    )}

                    <div className="mt-2">
                        <Form.Label>Image</Form.Label>
                        <Form.Control
                            name="image"
                            type="file"
                            accept="image/*"
                            onChange={(e: any) => {
                                const file = e.target.files[0];
                                if (file) {
                                    const fileType = file.type.split("/")[0];
                                    if (fileType !== "image") {
                                        snackbarUtil.error("Please select a valid image file.");
                                        setFormData({
                                            ...formData,
                                            image: null,
                                        });
                                    } else {
                                        handleChange('image', file);
                                    }
                                }
                            }}
                            isInvalid={errors.image}
                        />
                        {errors.image && (
                            <Form.Control.Feedback type="invalid">
                                Please upload an image.
                            </Form.Control.Feedback>
                        )}
                    </div>

                    {userId === 0 && (
                        <div className="mt-3 d-flex align-items-center">
                            <Form.Label>Category</Form.Label>
                            <Form.Check
                                type="checkbox"
                                id="Category"
                                checked={formData.category}
                                onChange={(e) => handleChange('category', e.target.checked)}
                                className="ms-2"
                            />
                        </div>
                    )}
                </Modal.Body>
                <Modal.Footer className="border-top">
                    <Button onClick={handleSubmit}>Upload</Button>
                    <Button variant="subtle-dark" onClick={toggleBanner}>Cancel</Button>
                </Modal.Footer>
            </Modal>
    );
};

export default AddBanner;
