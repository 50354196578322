interface BlinkDotProps {
	className?: string;
}

const BlinkDot = ({className} : BlinkDotProps) => {
    const css = `
        .wrapper {
            position: relative;
            width: 25px;
            height: 25px;            
            display: flex;
            align-items: center;
            justify-content: center;
            .circle {
                width: 7px;
                height: 7px;
                border-radius: 50%;
                position: absolute;
                z-index: 9;
                background: rgb(17, 219, 118);
            }
            .circlePulse {
                width: 25px;
                height: 25px;
                border-radius: 50%;
                position: absolute;
                background: rgba(0, 255, 127, 0.5);
                -webkit-animation: pulse 1.4s ease-out;
                -moz-animation: pulse 1.4s ease-out;
                animation: pulse 1.4s ease-out;
                -webkit-animation-iteration-count: infinite;
                -moz-animation-iteration-count: infinite;
                animation-iteration-count: infinite;
            }
        }

        @keyframes pulse {
        0% {
            -webkit-transform:scale(0);
            opacity:0;
        }
        25% {
            -webkit-transform:scale(0.3);
            opacity:1;
        }
        50% {
            -webkit-transform:scale(0.6);
            opacity:.6;
        }
        75% {
            -webkit-transform:scale(.9);
            opacity:.3;
        }
        100% {
            -webkit-transform:scale(1);
            opacity:0;
        }
        }
    
    `
    return (
        <>
            <style>{css}</style>
            <div className={`wrapper ${className}`}>
                <div className="circle"></div>
                <div className="circlePulse"></div>
            </div>
        </>
    );
};

export default BlinkDot;