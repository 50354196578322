import { Button, Modal } from 'react-bootstrap';

interface TandCModalProps {
	show: any;
	clickHandler: any;
}

const TandCModal = ({show, clickHandler} : TandCModalProps) => {
    return (
        <Modal size="lg" show={show} onHide={clickHandler} className="zoomIn" scrollable>
            <Modal.Header className="modal-title fw-bold d-flex justify-content-between pb-1">
                <span>Terms & Conditions</span>
                <Button variant="light btn-sm" onClick={clickHandler}>
                    <i className="ri-close-line fs-xl align-middle"></i>
                </Button>
            </Modal.Header>
            <Modal.Body className='pt-1 fs-md'>
                <p><strong>AAJ SE TIED MATCH ME FANCY KA LEN DEN KARNGE.</strong></p>
                
                <ol className='ps-3'>
                    <li className='mb-2'>If you not accept this agreement do not place any bet.</li>
                    <li className='mb-2'>Cheating bets deleted automatically or manual. No Claim.</li>
                    <li className='mb-2'>Admin decision is final and no claim on it.</li>
                    <li className='mb-2'>Batsman Runs (In-Play) Over/Under (back/lay) runs bets will stand after batsman has faced one ball or is given out before first ball is faced. Score counts if batsman is Not-Out including if innings is declared. In case of rain, match abandoned etc. settled bets will be valid.</li>
                    <li className='mb-2'>Current/Next Innings Runs Odd/Even Extras and Penalty runs will be included for settlement purposes.</li>
                    <li className='mb-2'>Runs at Fall of 1st Wicket At least one delivery must be bowled, if no wickets fall bets will be void unless settlement is already determined.</li>
                    <li className='mb-2'>Runs at Fall of Next Wicket The total innings runs scored by a team before the fall of the specified wicket determines the result of the market. If a team declares or reaches their target then the total amassed will be the result of the market. Bets will be void should no more play take place following the intervention of rain, or any other delay, as the ability to reach previous quotes offered will have been removed . In case of rain, match abandoned etc. settled bets will be valid.</li>
                    <li className='mb-2'>We do not accept manual bet.</li>
                    <li className='mb-2'>In case of anyone found using two different IDs logged in same IP address his winning in both account will be cancelled.</li>
                    <li className='mb-2'>In case of cheating and betting in unfair rates we will cancel the bet even after settling.</li>
                    <li className='mb-2'>Local fancy are based on Haar - Jeet.</li>
                    <li className='mb-2'>Incomplete session will be cancelled but complete session will be settled.</li>
                    <li className='mb-2'>In case of match abandoned, cancelled, no result etc. completed sessions will be settled.</li>
                    <li className='mb-2'>Lambi Paari : In 20-20 match entire twenty overs should be bowled, in case of rain or any delay if even one over is deducted the bets will be cancelled. In One Day match entire 50 overs should be bowled, in case of rain or any delay if even one over is deducted the bets will be cancelled.</li>
                    <li className='mb-2'>Advance Session,Lambi 1`st Inning Valid Only</li>
                    <li className='mb-2'>Total Match Four ,Total Match Siixes,Total Match Runs,Total Match Wides,Total Match Wicket. If Over Gets Less Then All BET Will Cancel This Rule For Limited Over Matches Like 20 Overs And 50 Overs Game</li>
                    <li className='mb-2'>1st Over Total Runs Prices will be offered for the total runs scored during the 1st over of the match. Extras and penalty runs will be included. The over must be completed for bets to stand unless settlement is already determined.</li>
                    <li className='mb-2'>Limited Overs Matches - Individual Batsmen Runs or Partnerships In a limited overs match where an individual batsman or partnership runs are traded in-play and the innings is curtailed or subject to any reduction in overs, then these markets will be settled at the midpoint of the last available quote before the overs were reduced. If the innings resumes at a later time, a new market may be formed. If a client wants a position in the new market they are required to place a new trade. If there are any subsequent reductions in overs, exactly the same rules will continue to apply i.e. the market is settled at the midpoint of the last available quote before the overs were reduced and a new market may be formed.</li>
                    <li className='mb-2'>Test Matches - Individual Batsmen Runs / Partnerships All bets, open or closed, on an individual batsman or partnership runs shall be void if 50 full overs are not bowled unless one team has won, is dismissed or declares prior to that point. Bets on partnership totals make up when the next wicket falls. If a batsman in the relevant partnership retires hurt, the partnership is treated as continuing between the remaining batsman and the batsman who comes to the wicket. A partnership is also treated as being ended by the end of an innings.</li>
                    <li className='mb-2'>Trading bets are not allowed.</li>
                    <li className='mb-2'>Company reserves the right to suspend/void any id/bets if the same is found to be illegitimate. For example incase of vpn/robot-use/multiple entry from same IP/ multiple bets at same time (Punching) and others. Note : only winning bets will be voided, For example: If we find such entries (above mentioned) from any id and their bets are (100000 lay in a 6 over session for the rate 40 and 100000 back for the rate of 48) and the actual score is 38, bets of 40 lay will be voided and the bets for 48 back will be considered valid.</li>
                </ol>
            </Modal.Body>
        </Modal>
    );
};

export default TandCModal;