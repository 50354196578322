import BreadCrumb from "Common/BreadCrumb";
import { Col, Container, Row, Card, Form, Button } from "react-bootstrap";
import PersonalDetails from "./PersonalDetails";
import Permissions from "Common/Permissions";
import SharingTable from "Common/Tables/SharingTable";
import CommissionSetting from "Common/CommissionSetting";
import { authServices } from "Utils/auth/services";
import { FC, useEffect, useState } from "react";
import snackbarUtil from "Utils/snackBarUtil";
import { useNavigate, useParams } from "react-router-dom";
import { settingServices } from "Utils/setting/services";

interface Props {
    type: string,
    demo?: boolean,
    name: string
}

type PayloadType = {
    partnership: number;
    domain: string;
    domain_name: string;
    name: string;
    user_name: string;
    password: string;
    user_type_id: string;
    parent_id: string;
    point: number;
    exposure_limit: number;
    belongs_to_credit_reference: number;
    credit_reference: number;
    belongs_to?: string;
    title: string;
    session_commission: number;
    match_commission: number;
};

const AddAgent: FC<Props> = ({ type, demo, name }) => {
    const [userData, setUserData] = useState<DataRes>();
    const [errors, setErrors] = useState<{ [key: string]: string }>({});
    const [sportError, setSportError] = useState<any>({});
    const [demoUser, setDemoUser] = useState(true);
    const [isUsernameAvailable, setIsUsernameAvailable] = useState(true);
    const [ukShareValue, setUkShareValue] = useState<any>("");
    const [isError, setIsError] = useState(false)
    const [userPayload, setUserPayload] = useState({
        partnership: 0,
        domain: "",
        domain_name: "",
        name: "",
        user_name: "",
        password: "",
        user_type_id: "",
        parent_id: "",
        point: 1,
        exposure_limit: -1,
        belongs_to_credit_reference: 0,
        credit_reference: 0,
        belongs_to: "CHIP_SUMMARY",
        title: "White Label",
        session_commission: 0,
        match_commission: 0
    });
    const [sportShare, setSportShare] = useState<SportsSharePayload[]>(
        [{
        sport: "",
        sport_id: "",
        name: "",
        percentage: [
            {
                parent_id: "",
                parent_partnership_share: 0,
                parent_share: 0,
                share: 0,
                user_id: "",
                user_name: "",
                user_share: 0,
                user_type_id: 0,
            },
            {
                parent_id: "",
                parent_partnership_share: 0,
                parent_share: 0,
                share: 0,
                user_share: 0,
            },
        ],
    }]);
    const [sportShareClient, setSportShareClient] = useState<any>();
    const [sportsPermission, setSportsPermission] = useState<SportsPermission[]>([]);
    const user_id = localStorage.getItem("userId");
    const user_Detail = localStorage.getItem("adminDetails");
    const userTypeId = JSON.parse(user_Detail || '')?.user_type_id;
    const { id, type: userTypeParam, typeId } = useParams();
    let userTypes = userTypeParam ? userTypeParam : JSON.parse(user_Detail || '')?.user_type_id;
    const nav = useNavigate();
    const getUserDetails = async () => {
        const { response } = await authServices.getAddUserDetails({
            is_sub_superadmin: false,
            user_id: id ? id : user_id || "",
            user_type_id: typeId?Number(typeId): Number(userTypes) || 0
        });
        setUserData(response?.data);
        const transformedData = response?.data?.sports_share.map((sport: any) => {
            const percentages = sport?.percentage?.map((percentage: any) => ({
                parent_id: percentage.parent_id,
                parent_partnership_share: percentage.parent_partnership_share,
                parent_share: percentage.parent_share,
                share: percentage.share,
                user_id: percentage.user_id,
                user_name: percentage.user_name,
                user_share: percentage.user_share,
                user_type_id: percentage.user_type_id,
            }));
            const newPercentageObject = {
                parent_id: id?id:user_id,
                parent_partnership_share: 0,
                parent_share: 0,
                share: 0,
                user_share: 0,
            };
            return {
                sport: sport.sport,
                sport_id: sport.sport_id,
                name: sport.name,
                percentage: [...percentages, newPercentageObject],
            };
        });

        const transformedDataClient = response?.data?.sports_share.map((sport: any) => {
            const percentages = sport?.percentage?.map((percentage: any) => ({
                parent_id: percentage.parent_id,
                parent_partnership_share: percentage.parent_partnership_share,
                parent_share: percentage.parent_share,
                share: percentage.share,
                user_id: percentage.user_id,
                user_name: percentage.user_name,
                user_share: percentage.user_share,
                user_type_id: percentage.user_type_id,
            }));

            return {
                sport: sport.sport,
                sport_id: sport.sport_id,
                name: sport.name,
                percentage: [...percentages],
            };
        });

        setSportShare(transformedData);
        setSportShareClient(transformedDataClient);
    };

    useEffect(() => {
        getUserDetails();
    }, [user_id, userTypes, id]);

    useEffect(() => {
        if (userData) {
            setUserPayload(prev => ({
                ...prev,
                session_commission: userData.session_commission ?? prev.session_commission,
                match_commission: userData.match_commission ?? prev.match_commission,
            }));
        }
    }, [userData, setUserPayload, demoUser, userData]);

    useEffect(() => {
        if (userData && demo) {
            setUserPayload(prev => ({
                ...prev,
                is_demo: demoUser,
                domain: userData?.domain?._id,
                domain_name: userData?.domain?.domain_name,
            }));
        }
    }, [userData, setUserPayload, demoUser, userData]);

    useEffect(() => {
        if (userData && userTypeId < 9) {
            setUserPayload(prev => ({
                ...prev,
                domain: userData?.domain?._id,
                domain_name: userData?.domain?.domain_name,
            }));
        }
    }, [userData, userTypeId]);

    const getRegister = async () => {
        const { belongs_to, ...restPayload } = userPayload;

        let payload: PayloadType = type === "user" ? { ...restPayload } : { ...userPayload };

        if (userTypeId > 0 || Number(userData?.user_type_id) > 0) {
            const { belongs_to, ...rest } = payload;
            payload = rest;
        }

        const { response, error } = await authServices.getUserRegister({
            ...payload,
            sports_share: type === "user" ? sportShareClient : sportShare,
            sports_permission: sportsPermission,
        });
        if (response) {
            if (response?.status) {


                snackbarUtil.success(response?.msg || 'User Register Success');
                if (userPayload?.belongs_to === "CHIP_SUMMARY" || type === "uk" || type === "b2c" || type === "user") {
                    nav('/user-list');
                } else {
                    nav("/agent-user-list");
                }
                setIsError(false);
            } else {
                snackbarUtil.error(response?.msg || 'Error while User Register');
            }
        }
    };
    const notOnlyNumbersRegex = /^(?!\d+$)/;
    const onlyAlphabetRegex = /^[A-Za-z\s]+$/;



    const validateForm = (): boolean => {
        let formErrors: { [key: string]: string } = {};
        if (!userPayload.user_name) {
            formErrors.user_name = "Username is required";
        } else if (!notOnlyNumbersRegex.test(userPayload.user_name)) {
            formErrors.user_name = "Username cannot be only numbers";
        }

        if (!userPayload.name) {
            formErrors.name = "Agent Name is required";
        } else if (!onlyAlphabetRegex.test(userPayload.name)) {
            formErrors.name = "Agent Name must contain only alphabetic characters";
        }

        if (!userPayload.password) {
            formErrors.password = "Password is required";
        } else if (userPayload.password.length < 8) {
            formErrors.password = "Password must be at least 8 characters long.";
        } else {
            const allowedCharsRegex = /^[a-zA-Z0-9@_#$-]+$/;
            if (!allowedCharsRegex.test(userPayload.password)) {
                formErrors.password = "Password can only contain letters, numbers, and @, _, #, &, -.";
            }
            if (!/[a-zA-Z]/.test(userPayload.password)) {
                formErrors.password = "Password must contain at least one letter.";
            }
            if (!/[0-9]/.test(userPayload.password)) {
                formErrors.password = "Password must contain at least one number.";
            }
        }

        if (!userPayload.domain_name && type !== "user" && userTypeId === 0) formErrors.domain_name = "Domain is required";
        if (!userPayload.user_type_id) formErrors.user_type_id = "Client Level is required";
        if (userPayload.point < 0) formErrors.user_type_id = "Client Level cannot be negative";
        if (userPayload.belongs_to === "CHIP_SUMMARY" && parseInt(userPayload.user_type_id) < 2) {
            formErrors.user_type_id = "Child level cannot be less than 2";
        }

        if (!userPayload.match_commission && userPayload.match_commission !== 0) {
            formErrors.match_commission = "Match commission is required";
        } else if (userPayload.match_commission > 100) {
            formErrors.match_commission = "Match commission cannot exceed 100";
        }

        if (!userPayload.session_commission && userPayload.session_commission !== 0) {
            formErrors.session_commission = "Session commission is required";
        } else if (userPayload.session_commission > 100) {
            formErrors.session_commission = "Session commission cannot exceed 100";
        }

        if ((type === "uk" || type === "b2c") && !userPayload.credit_reference) {
            formErrors.credit_reference = "Credit reference is required";
        }

        setErrors(formErrors);
        return Object.keys(formErrors).length === 0;
    };

    const validateRow = (): boolean => {
        let rowErrors: { [key: number]: string | null } = {};
        let isValid = true;

        sportShare.forEach((share, index) => {
            const shareValue = share.percentage[share.percentage?.length - 2]?.share;

            if (typeof shareValue !== 'number' || isNaN(shareValue)) {
                rowErrors[index] = 'Share value must not be Empty.';
                isValid = false;
            } else if (shareValue < 0) {
                rowErrors[index] = 'Share value cannot be less than 0.';
                isValid = false;
            } else if (shareValue > 100) {
                rowErrors[index] = 'Share value cannot exceed 100.';
                isValid = false;
            } else {
                rowErrors[index] = null;
            }
        });

        if (type === 'uk') {
            if (ukShareValue === '') {
                rowErrors[0] = 'Share must not be empty.';
                isValid = false;
            } else {
                const numericValue = Number(ukShareValue);
                if (isNaN(numericValue) || numericValue < 0 || numericValue > 100) {
                    rowErrors[0] = 'Share must be between 0 and 100.';
                    isValid = false;
                } else {
                    rowErrors[0] = null;
                }
            }
        }

        setSportError(rowErrors);
        return isValid;
    };

    const getCheckUserName = async (user_name: string): Promise<boolean> => {
        const { response } = await settingServices.getCheckUserName({ user_name });
        if (response?.status && response?.msg?.includes("Username is already exists")) {
            setErrors(prev => ({ ...prev, user_name: response?.msg }));
            setIsUsernameAvailable(false);
            setIsError(true);
            return false;
        } else {
            setIsUsernameAvailable(true);
            setIsError(false);
            setErrors(prev => ({ ...prev, user_name: "" }));
        }
        return true;
    };

    const pathname = window.location.pathname;


    useEffect(() => {
        setUserPayload({
            partnership: 0,
            domain: "",
            domain_name: "",
            name: "",
            user_name: "",
            password: "",
            user_type_id: "",
            parent_id: "",
            point: 1,
            exposure_limit: -1,
            belongs_to_credit_reference: 0,
            credit_reference: 0,
            belongs_to: "CHIP_SUMMARY",
            title: "White Label",
            session_commission: 0,
            match_commission: 0
        });
        setSportShare([{
            sport: "",
            sport_id: "",
            name: "",
            percentage: [
                {
                    parent_id: "",
                    parent_partnership_share: 0,
                    parent_share: 0,
                    share: 0,
                    user_id: "",
                    user_name: "",
                    user_share: 0,
                    user_type_id: 0,
                },
                {
                    parent_id: "",
                    parent_partnership_share: 0,
                    parent_share: 0,
                    share: 0,
                    user_share: 0,
                },
            ],
        }]);

    }, [pathname])


    const handleCreateUser = async () => {
        setIsError(false);
        setErrors({});

        const isFormValid = validateForm();
        const isRowsValid = type === "uk" ? true : validateRow();

        if (isFormValid && isRowsValid) {
            const isUsernameValid = await getCheckUserName(userPayload.user_name);

            if (isUsernameValid) {
                await getRegister();
            } else {
                console.warn("Form submission aborted due to username availability.");
            }
        } else {
            console.warn("Form submission aborted due to validation errors.");
        }
    };

    useEffect(() => {
        if (id) {
            setUserPayload((prevState) => ({
                ...prevState,
                parent_id: id || "",
            }));
        } else {
            setUserPayload((prevState) => ({
                ...prevState,
                parent_id: user_id || "",
            }));
        }
    }, [user_id, id]);

    useEffect(() => {
        if (type === "uk") {
            setUserPayload((prevState) => ({
                ...prevState,
                belongs_to: "UKRAINE",
            }));
        } else if (type === "user") {
            setUserPayload((prevState) => ({
                ...prevState,
                user_type_id: "1",
                title: "user",
                belongs_to: "",
                domain_name: userData?.domain?.domain_name,
                domain: userData?.domain?._id
            }));
        } else if (type === "b2c") {
            setUserPayload((prevState) => ({
                ...prevState,
                belongs_to: "B2C_MANAGER",
                title: "Super Manager",
                user_type_id: "4"
            }));
        }
    }, [type, userData]);


    return (
        <div className="page-content">
            <Container fluid>
                <BreadCrumb title={`Add ${name} Details`} pageTitle="" />
                <Row>
                    <Col lg={6}>
                        <PersonalDetails getCheckUserName={getCheckUserName} demoUser={demoUser} setDemoUser={setDemoUser} demo={demo} userType={userTypes} type={type} errors={errors} setErrors={setErrors} userPayload={userPayload} userData={userData} setUserPayload={setUserPayload} />
                        <CommissionSetting
                            errors={errors}
                            userPayload={userPayload}
                            setUserPayload={setUserPayload}
                            userData={userData}
                        />
                    </Col>
                    <Col lg={6}>
                        {(type !== "user" && userTypes !== "2") && (
                            <Card>
                                <Card.Header><h5 className='text-primary mb-0'>Sharing</h5></Card.Header>
                                <Card.Body>
                                    <SharingTable
                                        errors={sportError}
                                        validateRow={validateRow}
                                        type={type}
                                        sportShare={sportShare}
                                        setSportShare={setSportShare}
                                        userData={userData?.sports_share}
                                        setSportError={setSportError}
                                        setUkShareValue={setUkShareValue}
                                        ukShareValue={ukShareValue}
                                        setUserPayload={setUserPayload}
                                    />
                                </Card.Body>
                            </Card>
                        )}

                        <Card>
                            <Card.Body>
                                <Permissions
                                    sportsPermission={sportsPermission}
                                    setSportsPermission={setSportsPermission}
                                    userData={userData?.sports_permission}
                                />
                            </Card.Body>
                            <Card.Footer className="text-end">
                                <Button onClick={handleCreateUser}>{`Add ${name}`}</Button>
                            </Card.Footer>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default AddAgent;
