import { ListPagination } from "Common/ListPagination";
import TableEntry from "Common/TableEntry";
import moment from "moment";
import { useState } from "react";
import { Button, Modal, Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

interface FancyPLTableProps {
  fancyPLData: FancyPLList[];
  page: number;
  limit: number;
  listTotal: ProfitLossTotal;
  total: number;
  handlePageFilter: (value: number, limit: number) => void;
}

const FancyPLTable = ({
  fancyPLData,
  page,
  limit,
  listTotal,
  total,
  handlePageFilter,
}: FancyPLTableProps) => {
  const navigate = useNavigate();
  const [showList, setList] = useState(false);
  const [selectedUser, setSelectedUser] = useState<string>("");
  const [agentList, setAgentList] = useState<CasinoUserAgent[]>([]);
  const [sortConfig, setSortConfig] = useState<{
    key: string;
    direction: "asc" | "desc";
  } | null>(null);

  const toggleList = (name?: string) => {
    setAgentList([]);
    setList(!showList);
    setSelectedUser(name || "");
  };

  const handleSort = (key: string) => {
    setSortConfig((prev) => {
      if (prev && prev.key === key && prev.direction === "asc") {
        return { key, direction: "desc" };
      }
      return { key, direction: "asc" };
    });
  };

  const sortedData = [...fancyPLData].sort((a, b) => {
    if (!sortConfig) return 0;

    const aValue = a[sortConfig.key as keyof typeof a];
    const bValue = b[sortConfig.key as keyof typeof b];

    // Check if the values are numbers or strings
    const isNumeric = !isNaN(Number(aValue)) && !isNaN(Number(bValue));

    if (isNumeric) {
      // Numeric comparison
      if (sortConfig.direction === "asc") {
        return Number(aValue) - Number(bValue);
      } else {
        return Number(bValue) - Number(aValue);
      }
    } else {
      // String comparison (case-insensitive)
      const aString = String(aValue).toLowerCase();
      const bString = String(bValue).toLowerCase();
      if (sortConfig.direction === "asc") {
        return aString.localeCompare(bString);
      } else {
        return bString.localeCompare(aString);
      }
    }
  });

  return (
    <>
      <div className="table-responsive">
        <Table className="table-centered align-middle table-nowrap table-striped">
          <thead className="table-light">
            <tr>
              <th>
                <div className="d-flex">
                  No.{" "}
                  <i
                    className={`ms-1 ri-arrow-up-down-line ${
                      sortConfig?.key != "index" && "text-muted"
                    }  fs-md align-middle cursor-pointer select-none  ${
                      sortConfig?.key === "index" ? "sticky-active" : "sticky"
                    }`}
                    onClick={() => handleSort("index")}
                  ></i>
                </div>
              </th>
              <th>
                <div className="d-flex">
                  Date{" "}
                  <i
                    className={`ms-1 ri-arrow-up-down-line ${
                      sortConfig?.key != "date_time" && "text-muted"
                    }  fs-md align-middle cursor-pointer select-none  ${
                      sortConfig?.key === "date_time"
                        ? "sticky-active"
                        : "sticky"
                    }`}
                    onClick={() => handleSort("date_time")}
                  ></i>
                </div>
              </th>
              <th>Sports | Series</th>
              <th>Market | Match</th>
              <th>
                <div className="d-flex">
                  Stake{" "}
                  <i
                    className={`ms-1 ri-arrow-up-down-line ${
                      sortConfig?.key != "stack" && "text-muted"
                    }  fs-md align-middle cursor-pointer select-none  ${
                      sortConfig?.key === "stack" ? "sticky-active" : "sticky"
                    }`}
                    onClick={() => handleSort("stack")}
                  ></i>
                </div>
              </th>
              <th>View Bets</th>
            </tr>
          </thead>
          <tbody>
            {sortedData?.map((item, index) => {
              return (
                <tr>
                  <td>{(page - 1) * limit + index + 1}</td>
                  <td>
                    <p className="mb-0">
                      {moment(item?.date_time).format("DD-MM-YY")}
                    </p>
                    <p className="mb-0">
                      {moment(item?.date_time).format(" hh:mm:ss:SSS A")}
                    </p>
                  </td>
                  <td>
                    <strong className="mb-0 text-success">Cricket</strong>
                    <p className="mb-0 text-muted">{item?.series_name}</p>
                  </td>
                  <td>
                    <strong className="cursor-pointer">
                      {item?.event_name}
                    </strong>
                    <p className="mb-0 text-muted">{item?.match_name}</p>
                  </td>
                  <td className="text-info cursor-pointer">{item?.stack?.toFixed(2)}</td>
                  <td>
                    <Button
                      variant="success"
                      className="btn-sm"
                      onClick={() =>
                        navigate(`/fancy-stack/${item?.match_id}/${item?._id}`)
                      }
                    >
                      User Stake
                    </Button>
                  </td>
                </tr>
              );
            })}
          </tbody>
          <tfoot>
            <tr>
              <th colSpan={3}></th>
              <th>Total</th>
              <th
                colSpan={2}
                className={`text-${total < 0 ? "danger" : "secondary"}`}
              >
                {total.toFixed(2) || 0}
              </th>
            </tr>
          </tfoot>
        </Table>

        <nav
          aria-label="Page navigation"
          className="px-3 d-flex flex-column flex-sm-row align-items-center"
        >
          <TableEntry
            limit={limit}
            handlelimitFilter={(value: number) => handlePageFilter(page, value)}
          />
          <ListPagination
            listTotal={listTotal}
            handlePageFilter={(value: number) => handlePageFilter(value, limit)}
          />
        </nav>
      </div>
      <Modal
        show={showList}
        onHide={() => toggleList()}
        className="zoomIn"
        scrollable
      >
        <Modal.Header className="modal-title fw-bold d-flex justify-content-between pb-1">
          <div className="d-flex align-items-center">
            Parent List of{" "}
            <span className="text-muted fw-normal mx-2">({selectedUser})</span>
          </div>
          <Button variant="light btn-sm" onClick={() => toggleList()}>
            <i className="ri-close-line fs-xl align-middle"></i>
          </Button>
        </Modal.Header>
        {agentList?.map((item) => {
          return (
            <Modal.Body>
              {item.name}({item.user_name})
            </Modal.Body>
          );
        })}
      </Modal>
    </>
  );
};

export default FancyPLTable;
