import moment from "moment";
import { Match } from "pages/Dashboard";
import { act, FC, useEffect, useState } from "react";
import { Button, Card, Tab, Nav, ListGroup } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { authServices } from "Utils/auth/services";

interface DataRes {
  is_active: number;
  country_code: string;
}

interface Props {
  active: number;
  sportData: Match[] | undefined;
}

const headName: Record<number, string> = {
  7: "Horse Racing",
  4339: "Greyhound Racing",
};

const HrGrTable: FC<Props> = ({ active, sportData }) => {
  const [tabsData, setTabsData] = useState<DataRes[]>([]);
  const [tabsValue, setTabsValues] = useState<string>("");
  const [horseRacingData, setHorseracingData] = useState<HorseRacingRes[]>([]);
  const getHorseTabs = async () => {
    const { response } = await authServices.getHorseRacing({
      sport_id: active.toString(),
    });

    setTabsData(response?.data);
  };
  const getMarketByCountryCode = async (country_code: string) => {
    const { response } = await authServices.getMarketByCountryCode({
      sport_id: active.toString(),
      country_code,
    });

    setHorseracingData(response?.data);
  };

  useEffect(() => {
    getHorseTabs();
  }, [active]);

  const handleTabsChange = (code: string) => {
    setTabsValues(code);
    getMarketByCountryCode(code);
  };

  const nav = useNavigate();

  const handleRoute = (
    matchid: string,
    sport_id: number,
    enableFancy: number,
    matchName: string,
    isActive: number,
    market_id: string
  ) => {
    nav(
      `/match-detail/${matchid}/${sport_id}/${enableFancy}?market_id=${market_id}`,
      { state: matchName }
    );
    localStorage.setItem("isAct", String(isActive));
  };
  const handleRoute2 = (
    matchid: string,
    sport_id: number,
    enableFancy: number,
    matchName: string,
    isActive: number
  ) => {
    nav(`/match-detail/${matchid}/${sport_id}/${enableFancy}`, {
      state: matchName,
    });
    localStorage.setItem("isAct", String(isActive));
  };

  return (
    <Card>
      <Card.Header>
        <h5>{headName[active]}</h5>
      </Card.Header>
      <Card.Body>
        <Tab.Container defaultActiveKey="All">
          <div className="overflow-auto bg-light mb-3">
            <Nav
              as="ul"
              variant="pills"
              className="nav-dark flex-nowrap text-center"
            >
              <Nav.Item as="li">
                <Nav.Link
                  eventKey="All"
                  style={{ minWidth: "75px" }}
                  className="fs-md"
                >
                  All
                </Nav.Link>
              </Nav.Item>
              {tabsData?.map((items) => {
                return (
                  <Nav.Item
                    as="li"
                    onClick={() => handleTabsChange(items?.country_code)}
                  >
                    <Nav.Link
                      eventKey={items?.country_code}
                      style={{ minWidth: "75px" }}
                      className="fs-md"
                    >
                      {items?.country_code}
                    </Nav.Link>
                  </Nav.Item>
                );
              })}
            </Nav>
          </div>
          <Tab.Content className="text-muted">
            <Tab.Pane eventKey="All">
              <ListGroup className="list-group-fill-success">
                {sportData?.map((items) => {
                  return (
                    <ListGroup.Item
                      onClick={() =>
                        handleRoute2(
                          items?.match_id,
                          active,
                          0,
                          items?.match_name,
                          items?.is_active
                        )
                      }
                      className="list-group-item-action cursor-pointer"
                    >
                      <strong className="text-primary">
                        {items?.match_name} ({items?.bet_count})
                      </strong>{" "}
                      <span className="text-muted ms-2">
                        {moment(items?.match_date).format(
                          "DD-MM-YY hh:mm:ss A"
                        )}
                      </span>
                      {items?.inplay && (
                        <span className="inplayBlink ms-2">INPLAY</span>
                      )}
                    </ListGroup.Item>
                  );
                })}
              </ListGroup>
            </Tab.Pane>
            <Tab.Pane eventKey={tabsValue}>
              <ListGroup className="list-group-fill-success">
                {horseRacingData?.map((items) => {
                  return (
                    <ListGroup.Item className="d-flex align-items-sm-center flex-column flex-sm-row gap-2">
                      <div className="me-3 w-100" style={{ maxWidth: "145px" }}>
                        <strong>{items?.venue}</strong>
                      </div>
                      <div className="d-flex gap-2 flex-wrap">
                        {items?.markets?.map((market) => {
                          return (
                            <Button
                              onClick={() =>
                                handleRoute(
                                  market?.match_id,
                                  active,
                                  0,
                                  market?.venue,
                                  market?.is_active,
                                  market?.market_id
                                )
                              }
                              style={{ minWidth: "65px" }}
                              variant={"light"}
                              className={`bg-gradient ${
                                market?.inplay ? "active_inplay" : ""
                              }`}
                            >
                              {moment(market?.market_start_time).format(
                                "hh:mm"
                              )}
                            </Button>
                          );
                        })}
                      </div>
                    </ListGroup.Item>
                  );
                })}
              </ListGroup>
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </Card.Body>
    </Card>
  );
};

export default HrGrTable;
