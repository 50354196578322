import { useState } from 'react';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';

interface CasinoConversionModalProps {
    show: any;
    clickHandler: any;
    casinoConvetRate: number | undefined;
    updateCasinoRate: (casino_conversion_rate: number, domain_id: string) => Promise<void>;
    nameDomian: string;
    DomainName: any
}

const CasinoConversionModal = ({ show, clickHandler, casinoConvetRate, updateCasinoRate, nameDomian, DomainName }: CasinoConversionModalProps) => {
    const [newConversionRate, setNewConversionRate] = useState<number | null>(null); 
    const [error, setError] = useState<string>(""); 
    const handleNewRateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = parseFloat(e.target.value);
        setNewConversionRate(value);
        if (error) setError(""); 
    };


    const handleUpdate = async () => {
        if (newConversionRate === null || isNaN(newConversionRate) || newConversionRate <= 0) {
            setError("Please enter a valid positive conversion rate."); 
            return;
        }
        await updateCasinoRate(newConversionRate, nameDomian);
        setNewConversionRate(null);
        clickHandler();
    };
    const handleClose = async () => {
        clickHandler()
        setNewConversionRate(null);
    };

    return (
        <Modal show={show} onHide={handleClose} className="zoomIn" scrollable>
            <Modal.Header className="modal-title fw-bold d-flex justify-content-between pb-1">
                <div>Update Casino Conversion Rate of {DomainName}</div>
                <Button variant="light btn-sm" onClick={handleClose}>
                    <i className="ri-close-line fs-xl align-middle"></i>
                </Button>
            </Modal.Header>
            <Modal.Body className='fs-md'>
                <Row className="align-items-center g-3">
                    <Col lg={12}>
                        <Form.Group>
                            <Form.Label>Old Conversion Rate</Form.Label>
                            <Form.Control type="number" value={casinoConvetRate} readOnly />
                        </Form.Group>
                    </Col>
                    <Col lg={12}>
                        <Form.Group>
                            <Form.Label>New Conversion Rate</Form.Label>
                            <Form.Control
                                type="number"
                                placeholder='Enter New Conversion Rate'
                                value={newConversionRate || ""}
                                onChange={handleNewRateChange}
                            />
                            {error && <div className="text-danger mt-1">{error}</div>}  {/* Display validation error */}
                        </Form.Group>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer className='border-top'>
                <Button onClick={handleUpdate}>Update</Button>
                <Button variant='light' onClick={handleClose}>Cancel</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default CasinoConversionModal;
