import React, { useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import navdata from "../Layout/LayoutMenuData";

//SimpleBar
import SimpleBar from "simplebar-react";

import { authServices } from 'Utils/auth/services';
import moment from 'moment';
import { ListGroup } from 'react-bootstrap';

const SearchOption = () => {
    const navData = navdata().props.children;
    const [searchTerm, setSearchTerm] = useState('');
    const [filterData, setFilterData] = useState<any[]>([]);
    const [sportData, setSportData] = useState<any[]>([]);
    const [dropdownVisible, setDropdownVisible] = useState(false);
    const dropdownRef = useRef<any>(null);

    const fetchSportData = async () => {
        try {
            const { response } = await authServices.getSportData({ combine: true });
            const data = response?.data || [];
            setSportData(data);
            setFilterData(data); 
        } catch (error) {
            console.error("Error fetching sport data:", error);
        }
    };

    const handleFocus = () => {
        setDropdownVisible(true);
        
            fetchSportData();
        
    };

    useEffect(() => {
        const searchOptions = document.getElementById("search-close-options") as HTMLElement;

        const handleSearchInput = () => {
            if (searchTerm.length > 0) {
                setDropdownVisible(true);
                searchOptions.classList.remove("d-none");
            } else {
                searchOptions.classList.add("d-none");
            }
        };

        handleSearchInput();

        return () => {
            document.body.removeEventListener("click", handleSearchInput);
        };
    }, [searchTerm]);

    const onKeyDownPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "Enter") {
            e.preventDefault();
            setSearchTerm((e.target as HTMLInputElement).value);
        }
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newSearchTerm = event.target.value;
        setSearchTerm(newSearchTerm);


        if (newSearchTerm === '') {
            setFilterData(sportData);
        } else {

            const filteredMenuItems: any = sportData.filter((menuItem) => {
                const lowercaseLabel = menuItem.match_name ? menuItem.match_name.toLowerCase() : '';
                const matchInMainItem = lowercaseLabel.includes(newSearchTerm.toLowerCase());
                if (matchInMainItem) {
                    return {
                        ...menuItem,
                        subItems: menuItem.match_name,
                    };
                }
                return null;
            }).filter(Boolean);

            setFilterData(filteredMenuItems);
        }
    };
    const nav = useNavigate();
    const handleRoute=(matchid:string, sport_id:string, enableFancy:number, matchName:string, isActive:number)=>{
        nav( `/match-detail/${matchid}/${sport_id}/${enableFancy}`, { state:matchName});
        localStorage.setItem("isAct", String(isActive) );

        setDropdownVisible(false);
    }

    useEffect(() => {
        const searchOptions = document.getElementById("search-close-options") as HTMLElement;

        const handleSearchInput = () => {
            if (searchTerm.length > 0) {
                setDropdownVisible(true);
                searchOptions.classList.remove("d-none");
            } else {
                searchOptions.classList.add("d-none");
            }
        };

        handleSearchInput();

     
        const handleClickOutside = (event: MouseEvent) => {
            if (
                dropdownRef.current && 
                !dropdownRef.current.contains(event.target as Node) 
            ) {
                setDropdownVisible(false);
                setSearchTerm('');
                setFilterData(sportData);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [searchTerm]);

    const handleCloseDropdown = () => {
        setSearchTerm('');
        setFilterData(sportData);
    };

    return (
        <React.Fragment>
            <form className="app-search d-none d-xl-flex position-relative" ref={dropdownRef}>
                <div className="position-relative">
                    <input
                        type="text"
                        className="form-control"
                        placeholder="Search..."
                        id="search-options"
                        value={searchTerm}
                        onKeyDown={onKeyDownPress}
                        onFocus={handleFocus}
                        onChange={handleChange}
                    />
                    <span className="mdi mdi-magnify search-widget-icon"></span>
                    <span  onClick={handleCloseDropdown} className="mdi mdi-close-circle search-widget-icon search-widget-icon-close d-none" id="search-close-options"></span>
                </div>
                {dropdownVisible && (
                    <div className='dropdown-menu dropdown-menu-xl show start-0 p-0' style={{ top: '55px' }}>
                        <ListGroup className='overflow-auto' style={{ maxHeight: "320px"}}>
                            {filterData.length > 0 ? (
                                filterData.map((menuItem: any, index: number) => (
                                    <ListGroup.Item key={index}  onClick={()=>handleRoute(menuItem?.match_id, menuItem?.sport_id, menuItem?.enable_fancy, menuItem.match_name, menuItem?.is_active)} className="overflow-hidden py-2 px-3 text-truncate dropdown-item cursor-pointer" style={{minHeight: '60px'}}>
                                        <span className='inplayBlink'>{menuItem.inplay?"InPlay":""}</span> 
                                        {menuItem.inplay && <span className='mx-2 text-muted'>|</span>}  <span>{moment(menuItem?.match_date).format("DD-MM-YYYY hh:mm:ss A")} IST</span> | <span>{menuItem?.series_name}</span>
                                        <h5 className='mb-0 text-truncate'>{menuItem.match_name}</h5>
                                    </ListGroup.Item>
                                ))
                            ) : (
                                <p className="dropdown-item text-muted p-3 text-center mb-0">No results found</p>
                            )}
                        </ListGroup>
                    </div>
                )}
                {/* {dropdownVisible && (
                    <div className="dropdown-menu dropdown-menu-lg show" id="search-dropdown">
                        <SimpleBar style={{ maxHeight: "320px" }}>
                            {filterData.length > 0 ? (
                                filterData.map((menuItem: any, index: number) => (
                                    <div key={index}  onClick={()=>handleRoute(menuItem?.match_id, menuItem?.sport_id, menuItem?.enable_fancy, menuItem.match_name, menuItem?.is_active)} className="dropdown-item notify-item">
                                        <span className='inplayBlink'>{menuItem.inplay}</span>
                                        {menuItem.inplay === "INPLAY" && <span className='mx-2 text-muted'>|</span>}  <span>{moment(menuItem?.match_date).format("DD-MM-YYYY hh:mm:ss A")} IST</span> | <span>{menuItem?.series_name}</span>
                                        <h5>{menuItem.match_name}</h5>
                                    </div>
                                ))
                            ) : (
                                <p className="dropdown-item text-muted">No results found</p>
                            )}
                        </SimpleBar>
                    </div>
                )} */}
            </form>
        </React.Fragment>
    );
};

export default SearchOption;
