import { Dispatch, SetStateAction, useState, useRef } from 'react';
import { Button, Modal, Form, Overlay, Tooltip } from 'react-bootstrap';
import { authServices } from 'Utils/auth/services';
import snackbarUtil from 'Utils/snackBarUtil';

interface ResetPasswordModalProps {
    show: any;
    clickHandler: any;
    userIds: string;
    setshowResetPasswordModal: Dispatch<SetStateAction<boolean>>;
    userDetails: any;
    userName: string;
}

interface ResData {
    password: string;
    status: boolean;
}

const ResetPasswordModal = ({ show, clickHandler, userIds, setshowResetPasswordModal, userDetails, userName }: ResetPasswordModalProps) => {
    const [showPassword, setPassword] = useState(false);
    const [showLogPassword, setLogPassword] = useState(false);
    const [passVal, setPasswordVal] = useState<string>("");
    const [loggedInPassVal, setLoggedInPassVal] = useState<string>("");
    const [validated, setValidated] = useState(false);
    const [validationMessage, setValidationMessage] = useState<string>("");
    const [loginPasswordError, setLoginPasswordError] = useState<string>("");


    const [showTooltip, setShowTooltip] = useState(false);
    const targetRef = useRef(null);

    const validatePassword = (password: string) => {
        if (password.length < 8) {
            return "Password must be at least 8 characters long.";
        }
        const allowedCharsRegex = /^[a-zA-Z0-9@_#$-]+$/;
        if (!allowedCharsRegex.test(password)) {
            return "Password can only contain letters, numbers, and @, _, #, &, -";
        }
        if (!/[a-zA-Z]/.test(password)) {
            return "Password must contain at least one letter.";
        }
        if (!/[0-9]/.test(password)) {
            return "Password must contain at least one number.";
        }

        return "";
    };

    const handlePassValue = (e: any) => {
        const newPassVal = e.target.value;
        setPasswordVal(newPassVal);

        const validationError = validatePassword(newPassVal);
        if (validationError) {
            setValidated(true);
            setValidationMessage(validationError);
        } else {
            setValidated(false);
            setValidationMessage("");
        }
    };

    const handleLoggedInPassValue = (e: any) => {
        const newLoggedInPass = e.target.value;
        setLoggedInPassVal(newLoggedInPass);

        if (newLoggedInPass !== userDetails?.password) {
            setLoginPasswordError("Incorrect logged-in password.");
        } else {
            setLoginPasswordError("");
        }
    };

    const getShowPass = async (passVal: string) => {
        const { response } = await authServices.getUpdateChildPass({
            childUserId: userIds || "",
            newPassword: passVal,
        });
        if (response?.status) {
            snackbarUtil.success(response?.msg);
            setPasswordVal("");
            setLoggedInPassVal("");
            setshowResetPasswordModal(false);
        } else {
            snackbarUtil.error(response?.msg);
        }
    };

    const handleHide = () => {
        clickHandler();
        setPasswordVal("");
        setLoggedInPassVal("");
        setPassword(false);
        setValidated(false);
        setValidationMessage("");
        setLoginPasswordError("");
    };

    const handlePasswordShow = () => {
        const validationError = validatePassword(passVal);
        if (validationError) {
            setValidated(true);
            setValidationMessage(validationError);
            return;
        }
        if (loggedInPassVal !== userDetails?.password) {
            setLoginPasswordError("Incorrect logged-in password.");
            return;
        }

        getShowPass(passVal);
    };

    return (
        <Modal show={show} onHide={handleHide} className="zoomIn" scrollable>
            <Modal.Header className="modal-title fw-bold d-flex justify-content-between pb-1">
                <span>Change Password of {userName}</span>
                <Button variant="light btn-sm" onClick={handleHide}>
                    <i className="ri-close-line fs-xl align-middle"></i>
                </Button>
            </Modal.Header>
            <Modal.Body className="fs-md">
                <Form noValidate>
                    <div className="form-floating">
                        <Form.Control
                            ref={targetRef}
                            isInvalid={validated}
                            name="password"
                            type={showPassword ? "text" : "password"}
                            placeholder="New Password"
                            value={passVal}
                            onChange={handlePassValue}
                            onFocus={() => setShowTooltip(true)}
                            onBlur={() => setShowTooltip(false)}
                            required
                        />
                        <Form.Label>Enter New Password</Form.Label>
                        <Button
                            variant="link"
                            className="position-absolute end-0 top-0 bottom-0 text-decoration-none text-muted password-addon"
                            type="button"
                            id="password-addon"
                            onClick={() => setPassword(!showPassword)}
                        >
                            <i className={`${showPassword ? "ri-eye-fill" : "ri-eye-off-fill"} align-middle`}></i>
                        </Button>
                        <Form.Control.Feedback type="invalid">
                            {validationMessage || "Please enter a valid password."}
                        </Form.Control.Feedback>
                    </div>

                    <Overlay target={targetRef.current} show={showTooltip} placement="top">
                        {(props) => (
                            <Tooltip id="password-suggestion-tooltip" {...props}>
                                e.g., demo1234
                            </Tooltip>
                        )}
                    </Overlay>

                    <div className="form-floating mt-3">
                        <Form.Control
                            isInvalid={!!loginPasswordError}
                            name="login_password"
                            type={showLogPassword ? "text" : "password"}
                            placeholder="Logged In Password"
                            value={loggedInPassVal}
                            onChange={handleLoggedInPassValue}
                            required
                        />
                        <Form.Label>Enter Logged In Password</Form.Label>
                        <Button
                            variant="link"
                            className="position-absolute end-0 top-0 bottom-0 text-decoration-none text-muted password-addon"
                            type="button"
                            id="password-addon"
                            onClick={() => setLogPassword(!showLogPassword)}
                        >
                            <i className={`${showLogPassword ? "ri-eye-fill" : "ri-eye-off-fill"} align-middle`}></i>
                        </Button>
                        <Form.Control.Feedback type="invalid">
                            {loginPasswordError || "Please enter your logged-in password."}
                        </Form.Control.Feedback>
                    </div>
                </Form>
                <div className="text-end mt-3">
                    <Button
                        variant="subtle-primary"
                        onClick={handlePasswordShow}
                    >
                        Reset Password
                    </Button>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default ResetPasswordModal;
