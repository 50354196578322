import Loader from "Common/Loader";
import ChipSummaryTable from "Common/Tables/ChipSummaryTable";
import { useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { authServices } from "Utils/auth/services";

interface ChipSummaryProps {
  activeTab: string;
}

const ChipSummary = ({ activeTab }: ChipSummaryProps) => {
  const [chipSummaryData, setChipSummaryData] =
    useState<ChipSummaryData | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const getChipSummaryList = async (filters: any = {}) => {
    setIsLoading(true);
    const { response } = await authServices.getChipSummaryList(filters);
    setIsLoading(false);
    if (response?.data) {
      setChipSummaryData(response?.data);
    }
  };

  useEffect(() => {
    activeTab === "ChipSummary" && getChipSummaryList({});
  }, [activeTab]);

  return (
    <>
      <Row>
        <Col lg={6}>
          <Card className="ribbon-box border shadow-none mb-lg-0">
            <Card.Header>
              <div className="ribbon ribbon-success ribbon-shape">
                Plus Account
              </div>
              <h5 className="fs-md text-end">{chipSummaryData?.user}</h5>
            </Card.Header>
            <Card.Body>
              <ChipSummaryTable
                type="success"
                chipSummaryData={chipSummaryData}
                getChipSummaryList={getChipSummaryList}
              />
            </Card.Body>
          </Card>
        </Col>
        <Col lg={6}>
          <Card className="ribbon-box border shadow-none mb-lg-0">
            <Card.Header>
              <div className="ribbon ribbon-danger ribbon-shape">
                Minus Account
              </div>
              <h5 className="fs-md text-end">{chipSummaryData?.user}</h5>
            </Card.Header>
            <Card.Body>
              <ChipSummaryTable
                type="danger"
                chipSummaryData={chipSummaryData}
                getChipSummaryList={getChipSummaryList}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
      {isLoading && <Loader />}
    </>
  );
};

export default ChipSummary;
