import { useEffect, useState } from "react";
import { Button, Modal, Form } from "react-bootstrap";
import { authServices } from "Utils/auth/services";
import snackbarUtil from "Utils/snackBarUtil";

interface MethodEditModalProps {
  showMethod: boolean;
  clickHandler: (status: boolean) => void;
  category: string;
  selectedMethod: string;
  allData: DepositMethod | null;
  getDepositMethod: () => void;
}

const MethodEditModal = ({
  showMethod,
  clickHandler,
  category,
  selectedMethod,
  allData,
  getDepositMethod,
}: MethodEditModalProps) => {
  const userId = localStorage.getItem("userId");

  const [formData, setFormData] = useState({
    bankHolderName: "",
    bankName: "",
    ifscCode: "",
    accountNo: "",
    upi: "",
    image: null,
  });
  const [formErrors, setFormErrors] = useState({
    bankHolderName: "",
    bankName: "",
    ifscCode: "",
    accountNo: "",
    upi: "",
    image: "",
  });

  const [touchedFields, setTouchedFields] = useState({
    bankHolderName: false,
    bankName: false,
    ifscCode: false,
    accountNo: false,
    upi: false,
    image: false,
  });

  useEffect(() => {
    if (showMethod) {
      setFormData({
        bankHolderName: allData?.bank_holder_name || '',
        bankName: allData?.bank_name || '',
        ifscCode: allData?.ifsc_code || '',
        accountNo: allData?.account_no || '',
        upi: allData?.others || '',
        image: null,
      });
    }
  }, [showMethod]);

  const toggleMethod = () => {
    clickHandler(!showMethod);
    setFormData({
      bankHolderName: "",
      bankName: "",
      ifscCode: "",
      accountNo: "",
      upi: "",
      image: null,
    });
    setFormErrors({
      bankHolderName: "",
      bankName: "",
      ifscCode: "",
      accountNo: "",
      upi: "",
      image: "",
    });
    setTouchedFields({
      bankHolderName: false,
      bankName: false,
      ifscCode: false,
      accountNo: false,
      upi: false,
      image: false,
    });
  };

  const validateForm = (data = formData) => {
    let isValid = true;
    const errors: any = {};

    if (category === "BANK") {
      if (!data.bankHolderName.trim()) {
        errors.bankHolderName = "Bank Holder Name is required.";
        isValid = false;
      } else {
        errors.bankHolderName = "";
      }

      if (!data.bankName.trim()) {
        errors.bankName = "Bank Name is required";
        isValid = false;
      } else {
        errors.bankName = "";
      }

      if (!data.accountNo.trim() || data.accountNo.trim().length < 10) {
        errors.accountNo = "Account No is required";
        isValid = false;
      } else {
        errors.accountNo = "";
      }

      if (!data.ifscCode.trim() || data.ifscCode.trim().length != 11) {
        errors.ifscCode = "IFSC Code is required";
        isValid = false;
      } else {
        errors.ifscCode = "";
      }
    } else {
      if ((!data.upi.trim() || data.upi.trim().length < 6) && !data.image) {
        errors.upi = "UPI/PAYTM/GOOGLEPAY...etc or Image is required";
        errors.image = "UPI/PAYTM/GOOGLEPAY...etc or Image is required";
        isValid = false;
      } else {
        errors.upi = "";
        errors.image = "";
      }
    }

    setFormErrors(errors);
    return isValid;
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    let dataValue;

    if (e.target instanceof HTMLInputElement && e.target.type === "file") {
      dataValue = e.target.files?.[0] || null;
    } else {
      dataValue = value;
    }

    setFormData((prevState) => ({
      ...prevState,
      [name]: dataValue,
    }));

    setTouchedFields((prevState) => ({
      ...prevState,
      [name]: true,
    }));

    validateForm({ ...formData, [name]: value });
  };

  const createMethod = async () => {
    const data = new FormData();

    data.append("_id", selectedMethod);

    if (category === "BANK") {
      data.append("bank_name", formData.bankName);
      data.append("bank_holder_name", formData.bankHolderName);
      data.append("ifsc_code", formData.ifscCode);
      data.append("account_no", formData.accountNo);
    } else {
      formData.upi && data.append("others", formData.upi);
      formData.image && data.append("payment_qr", formData.image);
    }

    const { response } = await authServices.editDepositMethod(data);
    if (response?.status) {
      snackbarUtil.success(response.msg);
      getDepositMethod();
      toggleMethod();
    } else {
      snackbarUtil.error(
        response?.msg || "An error occurred. Please try again."
      );
    }
  };

  const handleSubmit = () => {
    if (validateForm()) {
      createMethod();
    } else {
      setTouchedFields({
        bankHolderName: true,
        bankName: true,
        ifscCode: true,
        accountNo: true,
        upi: true,
        image: true,
      });
    }
  };

  return (
    <Modal
      show={showMethod}
      onHide={toggleMethod}
      className="zoomIn"
      scrollable
    >
      <Modal.Header className="modal-title fw-bold d-flex justify-content-between pb-1">
        <span>Edit Payment Details</span>
        <Button variant="light btn-sm" onClick={toggleMethod}>
          <i className="ri-close-line fs-xl align-middle"></i>
        </Button>
      </Modal.Header>
      <Modal.Body>
        {category === "BANK" ? (
          <>
            <div className="mt-3 mx-auto">
              <div className="input-group">
                <div className="input-group-text">Bank Holder Name:</div>
                <Form.Control
                  type="text"
                  placeholder="Enter Bank Holder Name"
                  name="bankHolderName"
                  value={formData.bankHolderName}
                  onChange={handleInputChange}
                  isInvalid={
                    !!formErrors.bankHolderName && touchedFields.bankHolderName
                  }
                />
                {formErrors.bankHolderName && touchedFields.bankHolderName && (
                  <Form.Control.Feedback type="invalid">
                    {formErrors.bankHolderName}
                  </Form.Control.Feedback>
                )}
              </div>
            </div>

            <div className="mt-3 mx-auto">
              <div className="input-group">
                <div className="input-group-text">Bank Name:</div>
                <Form.Control
                  type="text"
                  placeholder="Enter Bank Name"
                  name="bankName"
                  value={formData.bankName}
                  onChange={handleInputChange}
                  isInvalid={!!formErrors.bankName && touchedFields.bankName}
                />
                {formErrors.bankName && touchedFields.bankName && (
                  <Form.Control.Feedback type="invalid">
                    {formErrors.bankName}
                  </Form.Control.Feedback>
                )}
              </div>
            </div>

            <div className="mt-3 mx-auto">
              <div className="input-group">
                <div className="input-group-text">IFSC Code:</div>
                <Form.Control
                  type="text"
                  placeholder="Enter ifsc code"
                  name="ifscCode"
                  value={formData.ifscCode}
                  onChange={handleInputChange}
                  isInvalid={!!formErrors.ifscCode && touchedFields.ifscCode}
                />
                {formErrors.ifscCode && touchedFields.ifscCode && (
                  <Form.Control.Feedback type="invalid">
                    {formErrors.ifscCode}
                  </Form.Control.Feedback>
                )}
              </div>
            </div>

            <div className="mt-3 mx-auto">
              <div className="input-group">
                <div className="input-group-text">Account No:</div>
                <Form.Control
                  type="number"
                  placeholder="Enter acc no"
                  name="accountNo"
                  value={formData.accountNo}
                  onChange={handleInputChange}
                  isInvalid={!!formErrors.accountNo && touchedFields.accountNo}
                />
                {formErrors.accountNo && touchedFields.accountNo && (
                  <Form.Control.Feedback type="invalid">
                    {formErrors.accountNo}
                  </Form.Control.Feedback>
                )}
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="mt-3 mx-auto">
              <div className="input-group">
                <div className="input-group-text">
                  UPI/PAYTM/GOOGLEPAY...etc :
                </div>
                <Form.Control
                  type="text"
                  placeholder="Enter UPI/PAYTM/GOOGLEPAY...etc :"
                  name="upi"
                  value={formData.upi}
                  onChange={handleInputChange}
                  isInvalid={!!formErrors.upi && touchedFields.upi}
                />
                {formErrors.upi && touchedFields.upi && (
                  <Form.Control.Feedback type="invalid">
                    {formErrors.upi}
                  </Form.Control.Feedback>
                )}
              </div>
            </div>
            <div className="mt-3 mx-auto">
              <div className="input-group">
                <div className="input-group-text">Image :</div>
                <Form.Control
                  name="image"
                  type="file"
                  onChange={handleInputChange}
                  isInvalid={!!formErrors.image && touchedFields.image}
                />
                {formErrors.image && touchedFields.image && (
                  <Form.Control.Feedback type="invalid">
                    {formErrors.image}
                  </Form.Control.Feedback>
                )}
              </div>
            </div>
          </>
        )}
      </Modal.Body>
      <Modal.Footer className="border-top">
        <Button onClick={() => handleSubmit()}>Update</Button>
        <Button variant="subtle-dark" onClick={() => toggleMethod()}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default MethodEditModal;
