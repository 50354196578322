import BreadCrumb from "Common/BreadCrumb";
import { ListPagination } from "Common/ListPagination";
import Loader from "Common/Loader";
import TableEntry from "Common/TableEntry";
import { useEffect, useState } from "react";
import {
  Container,
  Card,
  Form,
  Button,
  Table,
  Pagination,
  Modal,
  Dropdown,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { authServices } from "Utils/auth/services";
import snackbarUtil from "Utils/snackBarUtil";

const ClosedUser = () => {
  const nav = useNavigate();
  const [showBtn, setBtn] = useState(false);
  const [openUser, setUser] = useState(false);
  const [userId, setUserId] = useState<string | undefined>("");
  const [closeUserData, setCloseUserData] = useState<CloseData[]>([]);
  const [modalType, setModalType] = useState<"user" | "allUsers">("user");
  const [limit, setLimit] = useState<number>(50);
  const [page, setPage] = useState<number>(1);
  const [showList, setList] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [message, setMessage] = useState("")
  const [listTotal, setListTotal] = useState<ProfitLossTotal>({
    total: 0,
    page: 0,
  });

  let user_id = localStorage.getItem("userId") || ''

  const toggleUser = (type?: "user" | "allUsers", ids?: string | undefined) => {
    if (type) {
      setModalType(type);
    }
    setUser(!openUser);
    setUserId(ids);
  };

  const getCloseUserDetails = async (filters: any = {}) => {
    const { response } = await authServices.getCloseUser(user_id, {
      limit: limit,
      page: page,
      ...filters,
    });
    if(response){
      if (response?.status) {
        setIsLoading(false);
        setCloseUserData(response?.data || []);
        setListTotal({
          total: response?.total
            ? response?.total / (filters?.limit || limit)
            : 1,
          page: filters?.page || page,
        });
      }
    }else{
      setIsLoading(false)
    }
    
  };

  const getCloseUser = async (user_id: string | undefined) => {
    const { response } = await authServices.getClose({
      action: 0,
      user_id: user_id || "",
    });
    if (response) {
      if (response?.status) {
        setIsLoading(false);
        snackbarUtil.success(response.msg);
        getCloseUserDetails();
        setUser(false);
      } else {
        setIsLoading(false);
        snackbarUtil.error(response?.msg);
        setUser(false);
      }
    }
  };

  useEffect(() => {
    getCloseUserDetails();
  }, []);

  const handleSubmit = () => {
    setIsLoading(true);
    getCloseUser(userId);
  };

  const handlePageFilter = (page: number, limit: number) => {
    setPage(page);
    setLimit(limit);

    getCloseUserDetails({
      page: page,
      limit: limit,
    });
  };

  const [selectedUser, setSelectedUser] = useState<string>("");
  const [agentList, setAgentList] = useState<CasinoUserAgent[]>([]);

  const toggleList = (name?: string) => {
    setAgentList([]);
    setList(!showList);
    setSelectedUser(name || "");
  };


  const getUserAgent = async (userId: string) => {
    const { response } = await authServices.getShowAgent({
      user_id: userId,
    });
    if (response?.data) {
      setAgentList(response?.data?.agents);
    } else {
      setMessage(response?.msg)
    }
  };

  return (
    <div className="page-content">
      <Container fluid>
        <div className="position-relative mb-2">
          <BreadCrumb title="Closed User" pageTitle="Dashboard" back />
          {showBtn ? (
            <Button
              variant="dark"
              className="position-absolute top-0 end-0"
              onClick={() => toggleUser("allUsers")}
            >
              Open All User
            </Button>
          ) : null}
        </div>
        <Card className="pb-3">
          <div className="table-responsive" style={{ minHeight: '120px' }}>
            <Table className="table-striped">
              <thead>
                <tr>
                  <th>
                    <div className="form-check form-check-dark">
                      <Form.Check
                        type="checkbox"
                        id="openUser"
                        onClick={() => setBtn(!showBtn)}
                      />
                    </div>
                  </th>
                  <th>Closed User Name</th>
                  <th>Parent Name</th>
                  <th>Action</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {closeUserData?.map((items) => (
                  <tr key={items?._id}>
                    <td>
                      <div className="form-check form-check-dark">
                        <Form.Check type="checkbox" id="openUser" />
                      </div>
                    </td>
                    <td>{items?.name}</td>
                    <td>{items?.parent_user_name}</td>
                    <td>
                      <Button
                        variant="subtle-dark"
                        className="btn-sm text-nowrap"
                        onClick={() => toggleUser("user", items?._id)}
                      >
                        <i className="ri-arrow-right-up-line fs-xl align-middle"></i>{" "}
                        Open User
                      </Button>
                    </td>
                    <td className="text-end pe-4">
                      <Dropdown drop="start">
                        <Dropdown.Toggle
                          variant="subtle-dark"
                          className="e-caret-hide btn-sm"
                        >
                          <i className="fs-lg ri-more-2-fill"></i>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item className="border-bottom py-2" onClick={() => nav(`/downline-report/${items?._id}/${items?.name}`)}>
                            <i className="fs-lg me-2 ri-file-list-3-fill align-middle"></i>{" "}
                            Report
                          </Dropdown.Item>
                          <Dropdown.Item className="border-none py-2"
                            onClick={() => {
                              toggleList(items?.name);
                              getUserAgent(items?._id);
                            }}
                          >
                            <i className="fs-lg me-2 ri-parent-fill align-middle"></i>{" "}
                            Parent List
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>

          {/* Pagination */}
          <nav aria-label="Page navigation" className="px-3 d-flex flex-column flex-sm-row align-items-center">
            <TableEntry
              limit={limit}
              handlelimitFilter={(value: number) =>
                handlePageFilter(page, value)
              }
            />
            <ListPagination
              listTotal={listTotal}
              handlePageFilter={(value: number) =>
                handlePageFilter(value, limit)
              }
            />
          </nav>
        </Card>
        {isLoading && <Loader />}
      </Container>

      <Modal show={openUser} onHide={toggleUser} className="zoomIn" scrollable>
        <Modal.Body className="text-center ">
          <Button
            variant="light btn-sm"
            className="position-absolute"
            style={{ right: "1rem" }}
            onClick={() => toggleUser()}
          >
            <i className="ri-close-line fs-xl align-middle"></i>
          </Button>
          <i className="bi bi-exclamation-triangle text-warning display-5"></i>
          <h4>
            {modalType === "allUsers" ? "Open All Selected" : "Open"} User?
          </h4>
          <p className="fs-lg mt-4">
            Are you sure you want to reopen{" "}
            {modalType === "allUsers" ? "All Selected" : "This"} user account?
          </p>
          <div className="mt-4">
            <Button
              variant="light"
              className="mx-2"
              onClick={() => toggleUser()}
            >
              No
            </Button>
            <Button className="mx-2" onClick={handleSubmit}>
              Yes
            </Button>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={showList}
        onHide={() => toggleList()}
        className="zoomIn"
        scrollable
      >
        <Modal.Header className="modal-title fw-bold d-flex justify-content-between pb-1">
          <div className="d-flex align-items-center">
            Parent List of{" "}
            <span className="text-muted fw-normal mx-2">({selectedUser})</span>
          </div>
          <Button variant="light btn-sm" onClick={() => toggleList()}>
            <i className="ri-close-line fs-xl align-middle"></i>
          </Button>
        </Modal.Header>
        {agentList?.length !== 0 ? agentList?.map((item) => {
          return (
            <Modal.Body>
              {item.name}({item.user_name})
            </Modal.Body>
          );
        }) : <Modal.Body>{message}</Modal.Body>}
      </Modal>
    </div>
  );
};

export default ClosedUser;
