import { useEffect, useMemo, useState } from "react";
import { Nav, Tab, Table } from "react-bootstrap";
import { authServices } from "Utils/auth/services";
import { useParams } from "react-router-dom";

interface NetExposureProps {
  filter?: boolean;
  activeTab: string;
  isReport?: boolean;
}

const NetExposure = ({ activeTab }: NetExposureProps) => {
  const { userid } = useParams();
  const [sportsData, setSportsData] = useState<SportData[]>([]);
  const [casinoData, setCasinoData] = useState<any[]>([]);
  const [fullExp, setFullExc] = useState<boolean>(true);
  const [total, setTotal] = useState<number>(0);

  const fetchExposureSport = async () => {
    try {
      const { response } = await authServices.getExposureSport({
        user_id: userid || "",
      });
      const filteredData = response.data.filter(
        (item: SportData) => !("liabilitySum" in item)
      );
      const total = response.data.filter(
        (item: SportData) => ("liabilitySum" in item)
      );
      setTotal(total[0]?.liabilitySum);
      setSportsData(filteredData);
    } catch (error) {
      console.error("Error fetching exposure sport data:", error);
    }
  };
  const fetchExposureSportV1 = async () => {
    try {
      const { response } = await authServices.getExposureSportV1({
        user_id: userid || "",
      });
      const filteredData = response.data.filter(
        (item: SportData) => !("liabilitySum" in item)
      );
      setSportsData(filteredData);
    } catch (error) {
      console.error("Error fetching exposure sport data:", error);
    }
  };
  const fetchExposureCasino = async () => {
    try {
      const { response } = await authServices.getExposureCasino({
        user_id: userid || "",
      });
      setCasinoData(response.data);
    } catch (error) {
      console.error("Error fetching exposure casino data:", error);
    }
  };

  useEffect(() => {
    if (activeTab === "NetExposure") {
      if (fullExp) {
        fetchExposureSport();
      } else {
        fetchExposureSportV1();
      }
    }
  }, [activeTab, fullExp]);

  const totalsCasino = useMemo(() => {
    return casinoData?.reduce(
      (acc, user) => {
        acc.calculateExposure += user.calculateExposure;
        return acc;
      },
      { calculateExposure: 0 }
    );
  }, [casinoData]);

  const renderTableRows = (data: SportData[]) =>
    data.map((item, index) => (
      <tr key={index}>
        <td>{index + 1}</td>
        <td>{item.sport_name}</td>
        <td>{item.match_name}</td>
        <td>{item.event_name}</td>
        <td style={{ color: item.liability < 0 ? "red" : "black" }}>
          {item.liability?.toFixed(2)}
        </td>
      </tr>
    ));


    console.log(sportsData, "sportsDatasportsData")

  return (
    <>
      <Tab.Container defaultActiveKey="sports">
        <Nav
          as="ul"
          variant="tabs"
          className="nav-tabs-custom nav-primary nav-justified mb-3"
        >
          <Nav.Item as="li">
            <Nav.Link eventKey="sports" onClick={fetchExposureSport}>
              Sports
            </Nav.Link>
          </Nav.Item>
          <Nav.Item as="li">
            <Nav.Link eventKey="casino" onClick={fetchExposureCasino}>
              Casino
            </Nav.Link>
          </Nav.Item>
        </Nav>
        <Tab.Content className="text-muted">
          <Tab.Pane eventKey="sports">
            <div className="table-responsive">
              {sportsData?.length === 0 ? (
                <div
                  style={{
                    fontSize: "14",
                    textAlign: "center",
                  }}
                >
                  No Data Found
                </div>
              ) : (
                <Table className="table-striped table-nowrap align-middle mb-0">
                  <thead>
                    <tr>
                      <th>S. No.</th>
                      <th>Sport Name</th>
                      <th>Event Name</th>
                      <th>Market</th>
                      <th>Exposure</th>
                    </tr>
                  </thead>
                  <tbody>
                    {renderTableRows(sportsData)}
                    <tr>
                      <td colSpan={4} className="fw-bold text-center">Total</td>
                      <td className={total <0?"text-danger fw-bold":"fw-bold"}>{total?.toFixed(2)}</td>
                      </tr>
                    </tbody>
                </Table>
              )}
            </div>
          </Tab.Pane>
          <Tab.Pane eventKey="casino">
            {casinoData?.length === 0 ? (
              <div
                style={{
                  fontSize: "14",
                  textAlign: "center",
                }}
              >
                No Data Found
              </div>
            ) : (
              <div className="table-responsive">
                <Table className="table-striped table-nowrap align-middle mb-0">
                  <thead>
                    <tr>
                      <th>S. No.</th>
                      <th>Sport Name</th>
                      <th>Event Name</th>
                      <th>Market</th>
                      <th>Exposure</th>
                    </tr>
                  </thead>
                  <tbody>
                    {casinoData?.map((casino, id: number) => {
                      return (
                        <tr key={id}>
                          <td>{id + 1}</td>
                          <td>{casino?.matchName}</td>
                          <td>{casino?.marketName}</td>
                          <td>{casino?.marketType}</td>
                          <td>
                            <span
                              className={
                                casino?.calculateExposure < 0
                                  ? "text-danger"
                                  : "text-secondary"
                              }
                            >
                              {casino?.calculateExposure}
                            </span>
                          </td>
                        </tr>
                      );
                    })}
                    <tr>
                      <td colSpan={4} className="fw-bolder text-center">
                        Total
                      </td>
                      <td
                        className={`fw-bolder ${
                          totalsCasino?.calculateExposure < 0
                            ? "text-danger"
                            : "text-secondary"
                        }`}
                      >
                        {totalsCasino?.calculateExposure?.toFixed(2)}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            )}
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </>
  );
};

export default NetExposure;
