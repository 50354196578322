import { useMemo, useState } from "react";
import { Button, Modal, Pagination, Table } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { authServices } from "Utils/auth/services";

interface FancyStakeTableProps {
  fancyStakeData: FancyStakeList[];
  total: number;
  parentId: string;
  handleUserDetail: (value: string) => void;
  isStatement?: boolean;
}

const FancyStakeTable = ({
  fancyStakeData,
  total,
  parentId,
  handleUserDetail,
  isStatement,
}: FancyStakeTableProps) => {
  const navigate = useNavigate();
  const { matchid, marketid } = useParams();
  const [showList, setList] = useState(false);
  const [selectedUser, setSelectedUser] = useState<string>("");
  const [agentList, setAgentList] = useState<CasinoUserAgent[]>([]);
  const [sortConfig, setSortConfig] = useState<{
    key: string;
    direction: "asc" | "desc";
  } | null>(null);

  let userDetail = localStorage.getItem("adminDetails");
  let currentUser = JSON.parse(userDetail || "")?.parent_id;

  const toggleList = (name?: string) => {
    setAgentList([]);
    setList(!showList);
    setSelectedUser(name || "");
  };

  const getUserAgent = async (userId: string) => {
    const { response } = await authServices.getShowAgent({
      user_id: userId,
    });
    if (response?.data) {
      setAgentList(response?.data?.agents);
    }
  };

  const handleSort = (key: string) => {
    setSortConfig((prev) => {
      if (prev && prev.key === key && prev.direction === "asc") {
        return { key, direction: "desc" };
      }
      return { key, direction: "asc" };
    });
  };

  const sortedData = [...fancyStakeData].sort((a, b) => {
    if (!sortConfig) return 0;

    const aValue = a[sortConfig.key as keyof typeof a];
    const bValue = b[sortConfig.key as keyof typeof b];

    // Check if the values are numbers or strings
    const isNumeric = !isNaN(Number(aValue)) && !isNaN(Number(bValue));

    if (isNumeric) {
      // Numeric comparison
      if (sortConfig.direction === "asc") {
        return Number(aValue) - Number(bValue);
      } else {
        return Number(bValue) - Number(aValue);
      }
    } else {
      // String comparison (case-insensitive)
      const aString = String(aValue).toLowerCase();
      const bString = String(bValue).toLowerCase();
      if (sortConfig.direction === "asc") {
        return aString.localeCompare(bString);
      } else {
        return bString.localeCompare(aString);
      }
    }
  });

  return (
    <>
      <div className="table-responsive">
        <Table className="table-centered align-middle table-nowrap table-striped">
          <thead className="table-light">
            <tr>
              <th>
                <div className="d-flex">
                  No.{" "}
                  <i
                    className={`ms-1 ri-arrow-up-down-line ${
                      sortConfig?.key != "index" && "text-muted"
                    }  fs-md align-middle cursor-pointer select-none  ${
                      sortConfig?.key === "index" ? "sticky-active" : "sticky"
                    }`}
                    onClick={() => handleSort("index")}
                  ></i>
                </div>
              </th>
              <th>
                <div className="d-flex">
                  Domain Name{" "}
                  <i
                    className={`ms-1 ri-arrow-up-down-line ${
                      sortConfig?.key != "domain_name" && "text-muted"
                    }  fs-md align-middle cursor-pointer select-none  ${
                      sortConfig?.key === "domain_name"
                        ? "sticky-active"
                        : "sticky"
                    }`}
                    onClick={() => handleSort("domain_name")}
                  ></i>
                </div>
              </th>
              <th>
                <div className="d-flex">
                  Name{" "}
                  <i
                    className={`ms-1 ri-arrow-up-down-line ${
                      sortConfig?.key != "user_name" && "text-muted"
                    }  fs-md align-middle cursor-pointer select-none  ${
                      sortConfig?.key === "user_name"
                        ? "sticky-active"
                        : "sticky"
                    }`}
                    onClick={() => handleSort("user_name")}
                  ></i>
                </div>
              </th>
              <th>
                <div className="d-flex">
                  Fancy Stake{" "}
                  <i
                    className={`ms-1 ri-arrow-up-down-line ${
                      sortConfig?.key != "stack" && "text-muted"
                    }  fs-md align-middle cursor-pointer select-none  ${
                      sortConfig?.key === "stack" ? "sticky-active" : "sticky"
                    }`}
                    onClick={() => handleSort("stack")}
                  ></i>
                </div>
              </th>
              <th>
                {parentId && parentId != "null" && currentUser != parentId && (
                  <Button
                    variant="subtle-dark"
                    className="btn-sm"
                    onClick={() => handleUserDetail(parentId)}
                  >
                    Back
                  </Button>
                )}
              </th>
            </tr>
          </thead>
          <tbody>
            {sortedData?.map((item, index) => {
              return (
                <tr>
                  <td>{index < 9 ? `0${index + 1}` : index + 1}</td>

                  <td className="text-info cursor-pointer">
                    {item?.domain_name}
                  </td>
                  <td
                    className="text-info cursor-pointer"
                    onClick={() => {
                      item?.user_type_id != "1" &&
                        handleUserDetail(item?.user_id);
                    }}
                  >
                    <span className="badge bg-info-subtle text-info">
                      {item.user_type_id != "1" ? "A" : "C"}
                    </span>{" "}
                    {item?.user_name}
                  </td>
                  <td className="text-info cursor-pointer">{item?.stack?.toFixed(2)}</td>
                  <td>
                    <Button
                      variant="success"
                      className="btn-sm"
                      onClick={() => {
                        toggleList(item?.user_name);
                        getUserAgent(item?.user_id);
                      }}
                    >
                      P
                    </Button>
                    {item.user_type_id != "1" ? null : (
                      <Button
                        variant="success"
                        className="btn-sm"
                        onClick={() => {
                          isStatement
                            ? navigate(`/reports/Statement/${item.user_id}`)
                            : navigate(
                                `/view-bets/${matchid}/${marketid}/2/${item.user_id}`
                              );
                        }}
                      >
                        {isStatement ? "Statement" : "View Bets"}
                      </Button>
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
          <tfoot>
            <tr>
              <th colSpan={2}></th>
              <th>Total</th>
              <th
                colSpan={2}
                className={`text-${total < 0 ? "danger" : "secondary"}`}
              >
                {total?.toFixed(2) || 0}
              </th>
            </tr>
          </tfoot>
        </Table>
      </div>
      <Modal
        show={showList}
        onHide={() => toggleList()}
        className="zoomIn"
        scrollable
      >
        <Modal.Header className="modal-title fw-bold d-flex justify-content-between pb-1">
          <div className="d-flex align-items-center">
            Parent List of{" "}
            <span className="text-muted fw-normal mx-2">({selectedUser})</span>
          </div>
          <Button variant="light btn-sm" onClick={() => toggleList()}>
            <i className="ri-close-line fs-xl align-middle"></i>
          </Button>
        </Modal.Header>
        {agentList?.map((item) => {
          return (
            <Modal.Body>
              {item.name}({item.user_name})
            </Modal.Body>
          );
        })}
      </Modal>
    </>
  );
};

export default FancyStakeTable;
