import { Card } from "react-bootstrap";
import { FC, useEffect, useMemo, useState } from "react";


import { useParams } from "react-router-dom";

import TableContainer from "Common/TableContainer";

import { settingServices } from "Utils/setting/services";
import moment from "moment";
import TableEntry from "Common/TableEntry";
import { ListPagination } from "Common/ListPagination";

interface Props {
    activeTab: string
}


const ChangePasswordHistory: FC<Props> = ({ activeTab }) => {

    const [limit, setLimit] = useState<number>(50);
    const [page, setPage] = useState<number>(1);
    const [listTotal, setListTotal] = useState<ProfitLossTotal>({
        total: 0,
        page: 0,
    });
    const { userid } = useParams();


    const getPasswordChangedHistory = async (limit:number,page:number ) => {
        const { response } = await settingServices.getPasswordChangedHistory({
            limit,
            page,
            user_id: userid || ""
        });
        if (response) {
            if (response?.data) {
                // setListTotal({
                //     total: response?.data?.metadata[0]?.total /  limit,
                //     page: response?.data?.metadata[0]?.page,
                //   });
            } else {
                setListTotal({
                    total: 0,
                    page: 1,
                });
            }

        }
    };

    useEffect(() => {
        if (activeTab === "ChangePasswordHistory")
            getPasswordChangedHistory( limit, page);
    }, [activeTab,])


    const handlePageFilter = (page: number, limit: number) => {
        setPage(page);
        setLimit(limit);
        getPasswordChangedHistory(limit, page);
    };

    const columns = useMemo(
        () => [
            {
                header: "No.",
                accessorKey: "no",
                enableColumnFilter: false,
            },


            {
                header: "User Name",
                accessorKey: "domain",
                enableColumnFilter: false,
                cell: (cell: any) => {
                    return (
                        <span className="cursor-pointer text-info">
                            {cell?.row?.original?.domainName}
                        </span>
                    );
                },
            },
            {
                header: "Date",
                accessorKey: "date",
                enableColumnFilter: false,
                cell: (cell: any) => {
                    return (
                        <div>
                            <p className="mb-0">
                                {moment(cell?.row?.original?.date).format("DD-MM-YY")}
                            </p>
                            <p className="mb-0">
                                {moment(cell?.row?.original?.date).format(" hh:mm:ss:SSS A")}
                            </p>
                        </div>
                    );
                },
            },
            {
                header: "IP Address",
                accessorKey: "ipAddress",
                enableColumnFilter: false,
                cell: (cell: any) => {
                    return <span>{cell?.row?.original?.ipAddress}</span>;
                },
            },
        ],
        []
    );


    return (
        <>
            <Card>
                <Card.Body>

                    <TableContainer
                        columns={columns || []}
                        data={[]}
                        isBordered={false}
                        customPageSize={50}
                        isPagination={false}
                        tableClass="table-centered align-middle table-nowrap table-striped mb-3"
                        theadClass="text-muted table-light"
                        SearchPlaceholder="Search Products..."
                    />
                    <nav aria-label="Page navigation" className="px-3 d-flex flex-column flex-sm-row align-items-center">
                        <TableEntry
                            limit={limit}
                            handlelimitFilter={(value: number) => handlePageFilter(1, value)}
                        />
                        <ListPagination
                            listTotal={listTotal}
                            handlePageFilter={(value: number) => handlePageFilter(value, limit)}
                        />
                    </nav>

                </Card.Body>
            </Card>
            {/* {isLoading && <Loader />} */}
        </>
    );
};

export default ChangePasswordHistory;
