import { useEffect, useState } from "react";
import Marquee from "react-fast-marquee";
import { authServices } from "Utils/auth/services";

const News = () => {
    const [newsData, setrNewsData] = useState<string>("")
    const fetchNewsData = async()=>{
        const {response} = await authServices.getNews();
        setrNewsData(response?.data[0]?.heading)
    }

    useEffect(()=>{
        fetchNewsData();
    }, [])

    return (
        <div className='p-1 d-none d-lg-block' style={{
            // background: '#f2f2f2'
            width:"40%"
            }}>
            <Marquee speed={45}>{newsData}</Marquee>
        </div>
    );
};

export default News;