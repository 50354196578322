import ToggleSwitch from "Common/ToggleSwitch";
import { useEffect, useState } from "react";
import { Button, Modal, Table } from "react-bootstrap";
import { authServices } from "Utils/auth/services";
import snackbarUtil from "Utils/snackBarUtil";

interface MarketPositionModalProps {
  show: any;
  clickHandler: any;
  marketIds: string;
  marketName: string;
}

interface Total {
  selection_id: number;
  selection_name: string;
  win_loss: number;
}
const MarketPositionModal = ({
  show,
  clickHandler,
  marketIds,
  marketName,
}: MarketPositionModalProps) => {
  const [showMarket, setMarket] = useState(true);
  const [showBack, setBack] = useState(false);

  const toggleMarket = () => {
    setMarket(!showMarket);
  };

  const [showOn, setShowOn] = useState(false);
  const [bookData, setBookData] = useState<any>();
  const [BookFancyData, setBookFancyData] = useState<any>();

  const [userData, setUserData] = useState<string>("");
  const userId = localStorage.getItem("userId");

  useEffect(() => {
    if (userId) {
      setUserData(userId);
    }
  }, [userId, show]);

  const getMarketPosition = async () => {
    const { response } = await authServices.getMarketPosition({
      user_id: userData || "",
      market_id: marketIds,
    });
    if (response) {
      if (response?.data) {
        setBookData(response);
      }
      // else if (!response?.status) {
      //     snackbarUtil.error(response?.msg);
      // }
    }
  };

  useEffect(() => {
    if (marketIds) {
      getMarketPosition();
    }
  }, [userData, marketIds]);

  const normalizeTeamName = (team: string): string => {
    return team.toLowerCase().replace(/ /g, "_");
  };

  useEffect(() => {
    if (!bookData?.data) return;
    const mergedData = bookData.data.users.map((user: any) => {
      const mergedUser = { ...user };

      bookData.data.metadata.teams.forEach((team: string) => {
        const normalizedTeam = normalizeTeamName(team);
        const teamScore = user[normalizedTeam];
        const teamExposure = user[`${normalizedTeam}_total_exposure`];
        mergedUser[team] = {
          win_loss: teamScore || 0,
          total_exposure: teamExposure || 0,
        };

        delete mergedUser[normalizedTeam];
        delete mergedUser[`${normalizedTeam}_total_exposure`];
      });

      return mergedUser;
    });

    setBookFancyData(mergedData);
  }, [bookData]);

  const handleParentShere = (ids: string | null | undefined) => {
    if (ids) {
      setUserData(ids);
    }
  };

  const getWinLossByTeam = (team: string, dataArray: Total[]): number => {
    const teamData = dataArray.find((item) => item.selection_name === team);
    return teamData ? teamData.win_loss : 0;
  };


  return (
    <Modal
      size="lg"
      show={show}
      onHide={clickHandler}
      className="zoomIn"
      scrollable
    >
      <Modal.Header className="modal-title fw-bold d-flex justify-content-between pb-1">
        <div>
          <i
            className="ri-arrow-left-line me-2 cursor-pointer"
            onClick={() => handleParentShere(bookData?.parent_id)}
          ></i>{" "}
          Market Position:{" "}
          <span className="text-muted fw-normal">({marketName})</span>
        </div>
        <Button variant="light btn-sm" onClick={clickHandler}>
          <i className="ri-close-line fs-xl align-middle"></i>
        </Button>
      </Modal.Header>
      <Modal.Body className="fs-md">
        <div className="d-flex align-items-center">
          {showBack ? (
            <span
              onClick={() => setBack(false)}
              className="fs-lg ri-arrow-left-line me-2 cursor-pointer"
            ></span>
          ) : null}
          <strong className="me-3">
            Position of {BookFancyData && BookFancyData[0]?.domain_name}
          </strong>
          <ToggleSwitch
            On="S"
            Off="F"
            onChange={toggleMarket}
            checked={showMarket ? true : false}
          />
        </div>
        <div className="table-responsive">
          <Table className="align-middle table-striped table-nowrap mb-0">
            <thead>
              <tr>
                <th scope="col">Domain Name</th>
                <th scope="col">Account</th>
                {bookData?.data?.metadata?.teams?.map((items: any) => {
                  return <th scope="col">{items}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              {BookFancyData?.map((user: any, id: number) => {
                return (
                  <tr key={id}>
                    <td>{user?.domain_name}</td>
                    <td
                      className="cursor-pointer"
                      onClick={() => handleParentShere(user?.user_id)}
                    >
                      <Button
                        variant="subtle-primary"
                        className="rounded-pill p-1"
                        style={{ width: "30px" }}
                      >
                        {user?.user_type_id == "1" ? "C" : "A"}
                      </Button>
                      <span className="text-primary ms-2">
                        {user?.user_name}
                      </span>
                    </td>

                    {bookData?.data?.metadata?.teams?.map(
                      (team: string, index: number) => {
                        const teamData = user[team];
                        return (
                          <td
                            key={index}
                            className={
                              teamData?.win_loss < 0 ||
                              teamData?.total_exposure < 0
                                ? "text-danger"
                                : "text-secondary"
                            }
                          >
                            {showMarket
                              ? Number(teamData?.win_loss)?.toFixed(2)
                              : Number(teamData?.total_exposure)?.toFixed(2)}
                          </td>
                        );
                      }
                    )}
                  </tr>
                );
              })}

              {showMarket ? (
                <tr>
                  <td></td>
                  <td className="fw-bold">Total</td>
                  {bookData?.data?.metadata?.teams?.map(
                    (team: any, index: number) => {
                      const totalWinLoss = getWinLossByTeam(
                        team,
                        bookData.data.metadata.total
                      );
                      return (
                        <td
                          key={index}
                          className={
                            totalWinLoss < 0 ? "text-danger" : "text-secondary"
                          }
                        >
                          {totalWinLoss?.toFixed(2)}
                        </td>
                      );
                    }
                  )}
                </tr>
              ) : (
                <tr>
                  <td></td>
                  <td className="fw-bold">Total</td>
                  {bookData?.data?.metadata?.teams?.map(
                    (team: string, index: number) => {
                      // Calculate grand total for each team across all users
                      const grandTotal = BookFancyData?.reduce(
                        (acc: number, user: any) => {
                          const teamData = user[team];
                          const value = showMarket
                            ? Number(teamData?.win_loss)
                            : Number(teamData?.total_exposure);
                          return acc + (value || 0);
                        },
                        0
                      );

                      return (
                        <td
                          key={index}
                          className={
                            grandTotal < 0 ? "text-danger" : "text-secondary"
                          }
                        >
                          {grandTotal && grandTotal?.toFixed(2)}
                        </td>
                      );
                    }
                  )}
                </tr>
              )}
              {showMarket && (
                <tr>
                  <td></td>
                  <td className="fw-bold">Own</td>
                  {bookData?.data?.metadata?.teams?.map(
                    (team: any, index: any) => {
                      const ownWinLoss = getWinLossByTeam(
                        team,
                        bookData.data.metadata.own
                      );
                      return (
                        <td
                          key={index}
                          className={
                            ownWinLoss < 0 ? "text-danger" : "text-secondary"
                          }
                        >
                          {ownWinLoss?.toFixed(2)}
                        </td>
                      );
                    }
                  )}
                </tr>
              )}

              {showMarket && bookData?.data?.metadata?.parent?.length > 0 && (
                  <tr>
                    <td></td>
                    <td className="fw-bold">Parent</td>
                    {bookData?.data?.metadata?.teams?.map(
                      (team: any, index: any) => {
                        const ownWinLoss = getWinLossByTeam(
                          team,
                          bookData.data.metadata.parent
                        );
                        return (
                          <td
                            key={index}
                            className={
                              ownWinLoss < 0 ? "text-danger" : "text-secondary"
                            }
                          >
                            {ownWinLoss?.toFixed(2)}
                          </td>
                        );
                      }
                    )}
                  </tr>
                )}
            </tbody>
          </Table>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default MarketPositionModal;
