import { useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";

interface SportSettingData {
  sport: string;
  name: string;
  sport_id: string;
  market_min_stack: number;
  market_max_stack: number;
  market_min_odds_rate: number;
  market_max_odds_rate: number;
  market_bet_delay: number;
  market_max_profit: number;
  market_advance_bet_stake: number;
  session_min_stack: number;
  session_max_stack: number;
  session_bet_delay: number;
  session_max_profit: number;
  _id: string;
  market_bookmaker_max_odds_rate: number;
  market_bookmaker_min_odds_rate: number;
}

interface SelfSettingFormProps {
  data: SportSettingData | undefined;
  handleDataValueChange: (value: React.ChangeEvent<HTMLInputElement>) => void;
}

const SelfSettingForm = ({
  data,
  handleDataValueChange,
}: SelfSettingFormProps) => {
  return (
    <div>
      <div className="mt-3">
        <h5>Market Setting</h5>
        <Row className="g-3">
          <Col md={3}>
            <Form.Label>Min. Stake Amount</Form.Label>
            <Form.Control
              type="number"
              name="market_min_stack"
              value={data?.market_min_stack}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleDataValueChange(e)
              }
            />
          </Col>
          <Col md={3}>
            <Form.Label>Max. Stake Amount</Form.Label>
            <Form.Control
              type="number"
              name="market_max_stack"
              value={data?.market_max_stack}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleDataValueChange(e)
              }
            />
          </Col>
          <Col md={3}>
            <Form.Label>Min. Odds Rate</Form.Label>
            <Form.Control
              type="number"
              name="market_min_odds_rate"
              value={data?.market_min_odds_rate}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleDataValueChange(e)
              }
            />
          </Col>
          <Col md={3}>
            <Form.Label>Max. Odds Rate</Form.Label>
            <Form.Control
              type="number"
              name="market_max_odds_rate"
              value={data?.market_max_odds_rate}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleDataValueChange(e)
              }
            />
          </Col>
          {data?.sport_id === "4" && (
            <>
              <Col md={3}>
                <Form.Label>Bookmaker Min. Odds</Form.Label>
                <Form.Control
                  type="number"
                  name="market_bookmaker_min_odds_rate"
                  value={data?.market_bookmaker_min_odds_rate}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleDataValueChange(e)
                  }
                />
              </Col>
              <Col md={3}>
                <Form.Label>Bookmaker Max. Odds</Form.Label>
                <Form.Control
                  type="number"
                  name="market_bookmaker_max_odds_rate"
                  value={data?.market_bookmaker_max_odds_rate}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleDataValueChange(e)
                  }
                />
              </Col>
            </>
          )}
          <Col md={3}>
            <Form.Label>Match Bets Delay</Form.Label>
            <Form.Control
              type="number"
              name="market_bet_delay"
              value={data?.market_bet_delay}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleDataValueChange(e)
              }
            />
          </Col>
          <Col md={3}>
            <Form.Label>Market Max Profit</Form.Label>
            <Form.Control
              type="number"
              name="market_max_profit"
              value={data?.market_max_profit}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleDataValueChange(e)
              }
            />
          </Col>
          <Col md={3}>
            <Form.Label>Before Inplay Match Stake</Form.Label>
            <Form.Control
              type="number"
              name="market_advance_bet_stake"
              value={data?.market_advance_bet_stake}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleDataValueChange(e)
              }
            />
          </Col>
        </Row>
      </div>

      {data?.sport_id === "4" && (
        <div className="mt-3">
          <h5>Session Setting</h5>
          <Row className="g-3">
            <Col md={3}>
              <Form.Label>Min. Stake Amount</Form.Label>
              <Form.Control
                type="number"
                name="session_min_stack"
                value={data?.session_min_stack}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleDataValueChange(e)
                }
              />
            </Col>
            <Col md={3}>
              <Form.Label>Max. Stake Amount</Form.Label>
              <Form.Control
                type="number"
                name="session_max_stack"
                value={data?.session_max_stack}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleDataValueChange(e)
                }
              />
            </Col>
            <Col md={3}>
              <Form.Label>Session Bet Delay</Form.Label>
              <Form.Control
                type="number"
                name="session_bet_delay"
                value={data?.session_bet_delay}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleDataValueChange(e)
                }
              />
            </Col>
            <Col md={3}>
              <Form.Label>Session Max. Profit</Form.Label>
              <Form.Control
                type="number"
                name="session_max_profit"
                value={data?.session_max_profit}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleDataValueChange(e)
                }
              />
            </Col>
          </Row>
        </div>
      )}
    </div>
  );
};

export default SelfSettingForm;
