import TableContainer from "Common/TableContainer";
import { useMemo, FC } from "react";
import moment from "moment";
import { Button } from "react-bootstrap";

interface casinoProps {
  casinoData: any[];
  setLimit: any;
}

const UniverseCasinoTable: FC<casinoProps> = ({ casinoData, setLimit }) => {
  const columns = useMemo(
    () => [
      {
        header: "No.",
        accessorKey: "no",
        enableColumnFilter: false,
        cell: (cell: any) => {
          return cell?.row?.index + 1;
        },
      },
      {
        header: "Date",
        accessorKey: "date",
        enableColumnFilter: false,
        cell: (cell: any) => {
          return (
            <div>
              <p className="mb-0">
                {moment(cell.row.original?.createdAt).format(
                  "DD-MM-YYYY hh:mm:ss:SSS A"
                )}
              </p>
            </div>
          );
        },
      },
      {
        header: "Event Name",
        accessorKey: "eventName",
        enableColumnFilter: false,
        cell: (cell: any) => {
          return (
            <div>
              <p className="mb-0">{cell.row.original?.eventName || "-"}</p>
            </div>
          );
        },
      },
      {
        header: "Event ID",
        accessorKey: "gameId",
        enableColumnFilter: false,
        cell: (cell: any) => {
          return (
            <div>
              <p className="mb-0">{cell.row.original?.gameId || "-"}</p>
            </div>
          );
        },
      },
      {
        header: "Round ID",
        accessorKey: "roundId",
        enableColumnFilter: false,
      },
      {
        header: "Error",
        accessorKey: "error",
        enableColumnFilter: false,
      },
      {
        header: "Result Message",
        accessorKey: "resultMessage",
        enableColumnFilter: false,
        cell: (cell: any) => {
          return (
            <div>
              <p className="mb-0">{cell.row.original?.resultMessage || "-"}</p>
            </div>
          );
        },
      },
      {
        header: "Action",
        accessorKey: "action",
        enableColumnFilter: false,
        cell: (cell: any) => {
          return (
            <>
              <Button
                className="btn-sm mx-2"
                // onClick={() => handleStatusUpdate(cell?.row?.original?._id)}
                variant="info"
              >
                Status
              </Button>
              <Button
                className="btn-sm"
                variant="info"
              >
                Retry Result
              </Button>
            </>
          );
        },
      },
    ],
    []
  );

  return (
    <>
      <TableContainer
        columns={columns || []}
        data={casinoData || []}
        isBordered={false}
        customPageSize={10}
        isPagination
        tableClass="table-centered align-middle table-nowrap table-striped mb-3"
        theadClass="text-muted table-light"
        SearchPlaceholder="Search Products..."
        setLimit={setLimit}
      />
    </>
  );
};

export default UniverseCasinoTable;
