import { useEffect, useState } from "react";
import { Button, Modal, Row, Col, Form } from "react-bootstrap";
import Select from "react-select";
import snackbarUtil from "Utils/snackBarUtil";
import ToggleSwitch from "Common/ToggleSwitch";
import { debounce } from "lodash";
import { settingServices } from "Utils/setting/services";

export interface AlertSelection {
  min_amount: string | null;
  max_amount: string | null;
  min_bhav: string | null;
  users: FilterList[];
  isAlert: boolean;
}

interface AlertSettingProps {
  show: boolean;
  clickHandler: any;
  limits: AlertSelection | undefined;
  marketName: string;
  setAlertData: (value: AlertSelection) => void;
}

interface FilterList {
  value: string;
  label: string;
}

const AlertSetting = ({
  show,
  clickHandler,
  limits,
  marketName,
  setAlertData,
}: AlertSettingProps) => {
  const [isAlert, setIsAlert] = useState<boolean>(true);
  const [formData, setFormData] = useState<any>({
    min_amount: "",
    max_amount: "",
    min_bhav: "",
  });

  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [usersData, setUsersData] = useState<FilterList[]>([]);
  const [inputValue, setInputValue] = useState<string>("");
  const [selectedOptions, setSelectedOptions] = useState<FilterList[]>([]);

  const toggleAlert = () => {
    setIsAlert(!isAlert);
    setAlertData({
      min_amount: Number(formData?.min_amount) ? formData?.min_amount : null,
      max_amount: Number(formData?.max_amount) ? formData?.max_amount : null,
      min_bhav: Number(formData?.min_bhav) ? formData?.min_bhav : null,
      users: selectedOptions,
      isAlert: !isAlert,
    });
    clickHandler();
    snackbarUtil.success("Alert setting updated successfully.");
  };

  useEffect(() => {
    if (limits) {
      setFormData({
        min_amount: limits.min_amount?.toString() || "",
        max_amount: limits.max_amount?.toString() || "",
        min_bhav: limits.min_bhav?.toString() || "",
      });
      setSelectedOptions(limits.users);
      setIsAlert(limits?.isAlert);
    }
  }, [limits]);

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    const updatedValue = value.trim() === "" ? "" : Number(value);
    setFormData({ ...formData, [name]: updatedValue });
  };

  const getUsresList = async (userName: string) => {
    const { response } = await settingServices.getUserbyUserName({
      user_name: userName?.trim()?.toLowerCase(),
    });
    if (response?.data) {
      let userList = response?.data.map((item: any) => ({
        value: item?._id,
        label: item?.user_name,
      }));

      setUsersData(userList);
    } else {
      setUsersData([]);
    }
  };

  const fetchOptions = debounce(async (input: string) => {
    if (input.length >= 3) {
      getUsresList(input);
    }
  }, 300);

  useEffect(() => {
    fetchOptions(inputValue);
  }, [inputValue]);

  const handleSave = () => {
    setAlertData({
      min_amount: Number(formData?.min_amount) ? formData?.min_amount : null,
      max_amount: Number(formData?.max_amount) ? formData?.max_amount : null,
      min_bhav: Number(formData?.min_bhav) ? formData?.min_bhav : null,
      users: selectedOptions,
      isAlert: isAlert,
    });
    clickHandler();
    snackbarUtil.success("Alert setting updated successfully.");
  };

  return (
    <Modal
      size="xl"
      show={show}
      onHide={clickHandler}
      className="zoomIn"
      scrollable
    >
      <Modal.Header className="modal-title fw-bold d-flex justify-content-between pb-3 pb-md-0">
        <div className="d-flex align-items-center">
          <div className="mr-3" style={{ marginRight: "10px" }}>
            {marketName}
          </div>
          <ToggleSwitch
            On="On"
            Off="Off"
            id="mainAlert"
            onChange={toggleAlert}
            checked={isAlert}
          />
        </div>

        <Button variant="light btn-sm" onClick={clickHandler}>
          <i className="ri-close-line fs-xl align-middle"></i>
        </Button>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Row className="g-3">
            <>
              <h5 className="text-primary mb-0">Alert Setting</h5>

              <Col lg={3}>
                <div className="form-floating">
                  <Select
                    isMulti
                    isClearable
                    placeholder="Select Users..."
                    options={usersData || []}
                    className="customSelect"
                    value={selectedOptions}
                    inputValue={inputValue}
                    onChange={(selected: FilterList[]) => {
                      setSelectedOptions(selected || []);
                    }}
                    onInputChange={(input: any) =>
                      setInputValue(input?.trim()?.toLowerCase())
                    }
                  />
                </div>
              </Col>

              <Col lg={3}>
                <div className="form-floating">
                  <Form.Control
                    name="min_amount"
                    type="number"
                    id="MaxStakeAmount"
                    placeholder="Min. Amount"
                    value={formData.min_amount}
                    onChange={handleInputChange}
                    isInvalid={!!errors.min_amount}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.min_amount}
                  </Form.Control.Feedback>
                  <Form.Label htmlFor="MaxStakeAmount">Min. Amount</Form.Label>
                </div>
              </Col>

              <Col lg={3}>
                <div className="form-floating">
                  <Form.Control
                    name="max_amount"
                    type="number"
                    id="MinOddsRate"
                    placeholder="Max. Amount"
                    value={formData.max_amount}
                    onChange={handleInputChange}
                    isInvalid={!!errors.max_amount}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.max_amount}
                  </Form.Control.Feedback>
                  <Form.Label htmlFor="MinOddsRate">Max. Amount</Form.Label>
                </div>
              </Col>

              <Col lg={3}>
                <div className="form-floating">
                  <Form.Control
                    name="min_bhav"
                    type="number"
                    id="MaxOddsRate"
                    placeholder="Min. Bhav"
                    value={formData.min_bhav}
                    onChange={handleInputChange}
                    isInvalid={!!errors.min_bhav}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.min_bhav}
                  </Form.Control.Feedback>
                  <Form.Label htmlFor="MaxOddsRate">Min. Bhav</Form.Label>
                </div>
              </Col>
            </>
          </Row>
        </Form>
      </Modal.Body>
      <Modal.Footer className="justify-content-center border-top">
        <Button className="mx-2" onClick={handleSave}>
          Save
        </Button>
        <Button variant="light" className="mx-2" onClick={clickHandler}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
export default AlertSetting;
