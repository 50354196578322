import { FC, useEffect, useMemo, useState } from 'react';
import TableContainer from 'Common/TableContainer';
import { Card, Button, Modal } from 'react-bootstrap';
import { authServices } from 'Utils/auth/services';
import { useNavigate, useParams } from 'react-router-dom';
import snackbarUtil from 'Utils/snackBarUtil';

interface Props {
    sportData: GroupedData | undefined;
    sportId: number;
}

export interface sportListProps {
    name: string,
    sport_id: string,
    is_active: boolean,
    providerCode: null,
    is_live_sport: number
}

interface Runner {
    selectionId: number;
    status: string;
    ex: {
        availableToBack: Array<{ size: string; price: string }>;
        availableToLay: Array<{ size: string; price: string }>;
    };
}

export interface Match {
    match_tv_url: any;
    bet_count: any;
    sport_id: string;
    sport_name: string;
    series_id: string;
    series_name: string;
    match_id: string;
    match_name: string;
    market_id: string;
    status: string;
    inplay: boolean;
    match_date: string;
    marketIds: string[];
    is_active: number;
    enable_fancy: number;
    fancy_count: number;
    market_count: number;
    bookmaker_count: number;
    runners: Runner[];
}

export interface GroupedData {
    [sport_id: string]: Match[];
}

const sportName: Record<string, string> = {
    "4": "Cricket",
    "9898": "Share Market",
    "1": "Soccer",
    "2": "Tennis",
    "-100": "Casino",
    "7": "Horse Racing",
    "4339": "Greyhound Racing",
    "QT": "QTech"
}

const SeriesByData: FC = () => {
    const [sportData, setSportData] = useState<GroupedData>();
    const { seriesId, sportId } = useParams<{ seriesId?: string; sportId?: string }>();
    const [updatePaylod, setUpdatePaylod] = useState({
        matchId: "",
        isActive: 0
    });
    const [updatePaylodFancy, setUpdatePaylodFancy] = useState<any>({
        matchId: "",
        enable_fancy: 0,
        keys: ""
    });
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);

    const fetchSportData = async () => {
        const { response } = await authServices.getSportData({
            combine: true
        });

        const groupedData = groupBySportId(response?.data);

        setSportData(groupedData)
    }

    const groupBySportId = (matches: Match[]): GroupedData => {
        return matches.reduce((acc, match) => {
            const { series_id } = match;
            if (!acc[series_id]) {
                acc[series_id] = [];
            }
            acc[series_id].push(match);
            return acc;
        }, {} as GroupedData);
    };

    useEffect(() => {
        fetchSportData();
        const interval = setInterval(fetchSportData, 5000);

        return () => clearInterval(interval); 
    }, []);

    const getEnabaledFancy = async (matchedId: string, enable_fancy: number, keys?: string) => {
        try {
            const { response } = await authServices.getEnabaledFancy({ match_id: matchedId, enable_fancy: enable_fancy === 1 ? 0 : 1 });
            if (response?.status) {
                snackbarUtil.success(response.msg)
                updateSportFancyEnable(matchedId, enable_fancy === 1 ? 0 : 1)
                if (keys) {
                    updateSportFancyEnableName(keys, matchedId, enable_fancy === 1 ? 0 : 1)
                }
                setShowFancy(false)
            } else {
                snackbarUtil.error(response.msg);
            }

        } catch (error) {
            console.error('Error fetching enabled fancy data:', error);
        }
    };
    const updateSportFancyEnable = (matchId: string, lock: number) => {
        if(sportId)
        setSportData((prevData) => {
            if (!prevData || !prevData[sportId]) return prevData;
            return {
                ...prevData,
                [sportId]: prevData[sportId].map((match) =>
                    match.match_id === matchId ? { ...match, enable_fancy: lock } : match
                )
            };
        });
    };

    const updateSportFancyEnableName = (key: string, matchId: string, lock: number) => {
        setSportData((prevData) => {
            if (!prevData || !prevData[key]) return prevData;

            return {
                ...prevData,
                [key]: prevData[key].map((match) =>
                    match.match_id === matchId ? { ...match, enable_fancy: lock } : match
                ),
            };
        });
    };

    const updateMatchStatus = async (updatePaylod: { matchId: string; isActive: number; }) => {
        try {
            const { response } = await authServices.updateMatchStatus({ match_id: updatePaylod.matchId, is_active: updatePaylod.isActive === 1 ? 0 : 1 });
            if (response?.status) {
                snackbarUtil.success(response.msg);
                fetchSportData();
                handleClose();
            } else {
                snackbarUtil.error(response.msg);
            }
        } catch (error) {
            console.error('Error fetching enabled fancy data:', error);
        }
    };
    const updateEvent = async (updatePaylod: { matchId: string; isActive: number; }) => {
        try {
            const { response } = await authServices.updateEvent({
                event: "match", filter: {
                    match_id: updatePaylod.matchId
                }, update: {
                    is_visible: false
                }
            });
            if (response?.status) {
                snackbarUtil.success(response.msg)
                handleClose();
            } else {
                snackbarUtil.error(response.msg)
            }
        } catch (error) {
            console.error('Error fetching enabled fancy data:', error);
        }
    };


    const handleActiveDeactive = () => {
        updateMatchStatus(updatePaylod);
        updateEvent(updatePaylod)
    }


    const [showFancy, setShowFancy] = useState(false);
    const [showF, setShowF] = useState(false);

    const handleCloseF = () => setShowF(false);
    const handleCloseFancy = () => setShowFancy(false);
    const handleActiveDeActive = (matchedId: string, enable_fancy: number) => {
        // getEnabaledFancy(matchedId, enable_fancy);
        setShowFancy(true)
        setUpdatePaylodFancy({
            enable_fancy,
            matchId: matchedId
        })

    };

    const handleEnableFancy = () => {
        getEnabaledFancy(updatePaylodFancy.matchId, updatePaylodFancy?.enable_fancy, updatePaylodFancy?.keys);
    }

    const handleUpdateMatchStatus = (matchedId: string, isActive: number): void => {
        setShow(true)
        setUpdatePaylod({
            matchId: matchedId,
            isActive
        })
    };

    const nav = useNavigate();

    const handleRoute = (matchid: string, sport_id: string, enableFancy: number, matchName: string, isActive: number) => {
        nav(`/match-detail/${matchid}/${sport_id}/${enableFancy}`, { state: matchName });
        localStorage.setItem("isAct", String(isActive));
    }

    const columns = useMemo(() => [
        {
            header: "Match Details",
            accessorKey: "name",
            enableColumnFilter: false,
            enableSorting: false,
            cell: (cell: any) => (
                <div onClick={() => handleRoute(cell?.row?.original?.matchid, cell?.row?.original?.sportId, cell?.row?.original.enableFancy, cell.row.original.matchName, cell.row.original.isActive)}>
                    <div className='text-dark fs-lg cursor-pointer d-inline'>
                        {cell.row.original.matchName}
                        ({cell.row.original.bet_count})
                    </div>
                    <div className='d-flex align-items-center'>
                        <span className='text-success'>{cell.row.original.inplay}</span>
                        {
                            cell.row.original.inplay === "INPLAY" && <span className='mx-2 text-muted'>|</span>
                        }
                        {cell.row.original.date && (
                            <>
                                <span className='text-muted'>{cell.row.original.date}</span>
                                <span className='mx-2 text-muted'>|</span>
                            </>
                        )}
                        <span className='text-primary'>{cell.row.original.seriesName}</span>
                    </div>
                </div>
            ),
        },

        {
            header: "Action",
            accessorKey: "Action",
            enableColumnFilter: false,
            enableSorting: false,
            cell: (cell: any) => (
                <div className='btn-group'>
                    {
                        sportId !== "9898" && sportId == "4" && (
                            <>
                                <Button variant={`${cell?.row?.original?.enableFancy ? "success" : "danger"}  fs-xl px-2 py-1`} onClick={() => handleActiveDeActive(cell?.row?.original?.matchid, cell?.row?.original?.enableFancy)} style={{ minWidth: '35px', marginRight: "2px" }}>F</Button>

                            </>
                        )
                    }
                    {
                        (sportId !== "9898" && cell?.row?.original?.isTv) && <Button variant="subtle-dark px-2 py-1">
                            <i className='ri-tv-line fs-xl'></i>
                        </Button>
                    }

                    <Button variant={`${cell?.row?.original?.isActive ? "success" : "danger"} fs-xl px-2 py-1`} onClick={() => handleUpdateMatchStatus(cell?.row?.original?.matchid, cell?.row?.original?.isActive)} style={{ minWidth: '35px' }}>A</Button>
                </div>
            ),
        },

        {
            header: "Odds",
            accessorKey: "Odds",
            enableColumnFilter: false,
            enableSorting: false,
            cell: (cell: any) => {
                return (
                    <div className='oddsBtn d-inline-flex align-items-center position-relative'>
                        <div className='btn-group'>
                            <Button style={{ width: '55px', height: "38.2px" }} variant='back'>{cell?.row?.original?.odds?.ex?.availableToBack[0]?.price} </Button>
                            <Button style={{ width: '55px', height: "38.2px" }} variant='lay'> {cell?.row?.original?.odds?.ex?.availableToLay[0]?.price} </Button>
                        </div>
                        <div className='btn-group mx-1'>
                            <Button style={{ width: '55px', height: "38.2px" }} variant='back'> 0 </Button>
                            <Button style={{ width: '55px', height: "38.2px" }} variant='lay'> 0 </Button>
                        </div>
                        <div className='btn-group'>
                            <Button style={{ width: '55px', height: "38.2px" }} variant='back'>{cell?.row?.original?.odds?.ex?.availableToBack[2]?.price} </Button>
                            <Button style={{ width: '55px', height: "38.2px" }} variant='lay'>{cell?.row?.original?.odds?.ex?.availableToLay[2]?.price} </Button>
                        </div>
                        {
                            cell.row.original.status !== "OPEN" && <div className='position-absolute w-100 h-100 border rounded-2 bg-white bg-opacity-75 d-flex align-items-center justify-content-center fs-md text-danger border-danger text-uppercase fw-bold'>
                                {cell.row.original.status}
                            </div>
                        }
                    </div>
                )
            },
        },
    ], [sportId, seriesId]);

    const tableData = useMemo(() => {
        if (!sportData || !seriesId || !Array.isArray(sportData[seriesId])) {
            return [];
        }

        return sportData[seriesId].map((items) => ({
            matchName: items?.match_name,
            inplay: items?.inplay ? 'INPLAY' : undefined,
            sport: items?.sport_name,
            sportId: items?.sport_id,
            odds: items?.runners[0],
            status: items?.status,
            matchid: items?.match_id,
            isActive: items?.is_active,
            enableFancy: items.enable_fancy,
            seriesName: items?.series_name,
            bet_count: items?.bet_count,
            isTv: items?.match_tv_url,
            date: items?.match_date
                ? new Date(items.match_date).toLocaleString('en-GB', {
                    day: '2-digit',
                    month: '2-digit',
                    hour: '2-digit',
                    minute: '2-digit',
                    hour12: true,
                })
                : null,
        }));
    }, [sportData, seriesId]);

    return (
        <Card className='mt-3'>
            <Card.Header>
                <h5>{sportName[sportId || ""]} <span className='text-muted'>({sportData && sportData[seriesId || ""]?.length || 0})</span></h5>
            </Card.Header>
            <Card.Body className='pt-1 px-4'>
                <TableContainer
                    isPagination={false}
                    columns={columns}
                    data={tableData}
                    isBordered={false}
                    divClassName="table-responsive table-card mt-0"
                    tableClass="table table-striped align-middle table-nowrap mb-0"
                    theadClass="d-none"
                    tdClass="w-100"
                />
            </Card.Body>
            <Modal show={show}>
                <Modal.Body>
                    <h5 className='lg text-center'>Are you sure want to deactive Match ?</h5>
                </Modal.Body>

                <Modal.Footer>
                    <Button size='sm' variant="secondary" onClick={handleClose}>Cancel</Button>
                    <Button size='sm' variant="primary" onClick={handleActiveDeactive}>Submit</Button>
                </Modal.Footer>
            </Modal>



            <Modal show={showF} onHide={handleCloseF}>
                <Modal.Body>
                    <h5 className='lg text-center'>Are you sure want to deactive Match ?</h5>
                </Modal.Body>

                <Modal.Footer>
                    <Button size='sm' variant="secondary" onClick={handleCloseF}>Cancel</Button>
                    <Button size='sm' variant="primary" onClick={handleActiveDeactive}>Submit</Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showFancy} onHide={handleCloseFancy}>
                <Modal.Body>
                    <h5 className='lg text-center'>Are you sure want to {updatePaylodFancy?.enable_fancy ? "Disable" : "Enable"} Fancy ?</h5>
                </Modal.Body>

                <Modal.Footer>
                    <Button size='sm' variant="secondary" onClick={handleCloseFancy}>Cancel</Button>
                    <Button size='sm' variant="primary" onClick={handleEnableFancy}>Submit</Button>
                </Modal.Footer>
            </Modal>
        </Card>
    );
};

export default SeriesByData;
