import { Dispatch, FC, SetStateAction, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";

interface Props {
    showTvUrl: boolean;
    handleCloseTv: () => void;
    showPassword: boolean;
    setTVPayload: Dispatch<SetStateAction<{
        domain_id: string;
        password: string;
        is_tv_url_premium: string;
    }>>;
    setPassword: Dispatch<SetStateAction<boolean>>;
    tvPaylod: {
        domain_id: string;
        password: string;
        is_tv_url_premium: string;
    };
    getUpdateTV: (data: any) => Promise<void>;
    nameDomain: any;
}

const TvPermission: FC<Props> = ({ nameDomain, showTvUrl, handleCloseTv, showPassword, setTVPayload, setPassword, tvPaylod, getUpdateTV }) => {
    const [passwordError, setPasswordError] = useState<string>("");

    const validatePassword = () => {
        const password = tvPaylod.password;
        if (password.length === 0) {
            setPasswordError("Password is required");
            return false;
        }
        setPasswordError("");
        return true;
    };

    const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setTVPayload((prev) => ({ ...prev, password: e.target.value }));
        if (e.target.value.length > 0) {
            setPasswordError("");
        }
    };

    const handleUpdate = () => {
        if (validatePassword()) {
            getUpdateTV(tvPaylod);
        }
    };

    return (
        <Modal show={showTvUrl} onHide={handleCloseTv} className="zoomIn" scrollable>
            <Modal.Header className="modal-title fw-bold d-flex justify-content-between pb-1">
                <div className="d-flex align-items-center">Update TV URL of {nameDomain}</div>
                <Button variant="light btn-sm" onClick={handleCloseTv}>
                    <i className="ri-close-line fs-xl align-middle"></i>
                </Button>
            </Modal.Header>
            <Modal.Body>
                <Form.Label>TV Type</Form.Label>
                <Row className="form-check d-flex mb-2">
                    <Col lg={6}>
                        <Form.Check 
                            type="radio" 
                            onChange={() => setTVPayload((prev) => ({ ...prev, is_tv_url_premium: "1" }))} 
                            name="tvType" 
                            id="Premimum" 
                            checked={tvPaylod?.is_tv_url_premium === "1"} 
                        />
                        <Form.Label htmlFor="Premimum">Premium</Form.Label>
                    </Col>
                    <Col lg={6}>
                        <Form.Check 
                            type="radio" 
                            onChange={() => setTVPayload((prev) => ({ ...prev, is_tv_url_premium: "0" }))} 
                            name="tvType" 
                            id="Normal" 
                            checked={tvPaylod?.is_tv_url_premium === "0"} 
                        />
                        <Form.Label htmlFor="Normal">Normal</Form.Label>
                    </Col>
                </Row>
                <Form.Group>
                    <Form.Label>Password</Form.Label>
                    <div className="form-icon right">
                        <Form.Control
                            name="password"
                            type={showPassword ? "text" : "password"}
                            placeholder="Enter Password"
                            onChange={handlePasswordChange}
                            value={tvPaylod.password}
                        />
                        <Button
                            variant="link"
                            className="btn-sm position-absolute end-0 top-0 bottom-0 text-decoration-none text-muted password-addon"
                            type="button"
                            id="password-addon"
                            onClick={() => setPassword(!showPassword)}
                        >
                            <i className={`${showPassword ? "ri-eye-fill" : "ri-eye-off-fill"} align-middle`}></i>
                        </Button>
                    </div>
                    {passwordError && (
                        <div className="text-danger mt-2">{passwordError}</div>
                    )}
                </Form.Group>
            </Modal.Body>
            <Modal.Footer className="border-top">
                <Button onClick={handleUpdate}>Update</Button>
                <Button variant="light" onClick={handleCloseTv}>Cancel</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default TvPermission;
