import { ListPagination } from "Common/ListPagination";
import TableContainer from "Common/TableContainer";
import TableEntry from "Common/TableEntry";
import moment from "moment";
import { Dispatch, FC, SetStateAction, useMemo, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { settingServices } from "Utils/setting/services";
import snackbarUtil from "Utils/snackBarUtil";

interface Props {
  matchedRollBack: matchRollBackRes[];
  getOddsRollBack: (market_id: string) => Promise<void>;
  getOddsABD: (market_id: string, rollback: number) => Promise<void>;
  setRollback: Dispatch<SetStateAction<boolean>>;
  showRollback: boolean;
  setLimit: any;
  setPageNo: any;
  totalPage: number;
  limit: number;
  pageNo: number;
  setAbdFancy: Dispatch<any>
  abdFancy: any
  setIsLoading: Dispatch<SetStateAction<boolean>>
}

const ResultRollbackFancyTable: FC<Props> = ({
  setLimit,
  setPageNo,
  matchedRollBack,
  totalPage,
  limit,
  pageNo,
  getOddsRollBack,
  getOddsABD,
  setRollback,
  showRollback,
  setAbdFancy,
  abdFancy,
  setIsLoading
}) => {
  const [marketId, setMarketId] = useState<string>("");
  const [matchedName, setMatchedName] = useState<string>("");
  const [marketName, setMarketName] = useState<string>("");
  const [rollBack, setRollbackData] = useState<number>(0);


 

  const toggleRollback = (
    id: string,
    matchName: string,
    marketName: string,
    rollback: number,
    isResult:boolean
  ) => {
    setRollback(!showRollback);
    setMarketId(id);
    setMatchedName(matchName);
    setMarketName(marketName);
    setRollbackData(rollback);
    setAbdFancy(isResult);
  };

  const handleUpdate = () => {
    setIsLoading(true)
    if(abdFancy){
      getOddsRollBack(marketId);
    }else{
      getOddsABD(marketId, 1)
    }

  };

  const columns = useMemo(
    () => [
      {
        header: "No.",
        accessorKey: "no",
        enableColumnFilter: false,
        cell: (cell: any) => {
          return ((pageNo - 1) * limit) + cell?.row?.index + 1;
        },
      },
      {
        header: "Match Date",
        accessorKey: "createdAt",
        enableColumnFilter: false,
        cell: (cell: any) => {
          return (
            <div>
              <p className="mb-0">
                {moment(cell.row.original?.match_date).format(
                  "DD-MM-YYYY hh:mm:ss:SSS A"
                )}
              </p>
            </div>
          );
        },
      },
      {
        header: "Match Name",
        accessorKey: "match_name",
        enableColumnFilter: false,
      },
      {
        header: "Fancy Name",
        accessorKey: "fancy_name",
        enableColumnFilter: false,
        cell: (cell: any) => {
          return (
            <div>
              <p className="mb-0">{cell.row?.original?.fancy_name}</p>
              <p
                className={`mb-0 ${
                  !cell.row?.original?.is_abandoned
                    ? "text-success"
                    : "text-danger"
                }`}
              >
                {cell.row?.original?.result_status}
              </p>
            </div>
          );
        },
      },
      {
        header: "Settled Time",
        accessorKey: "createdAt",
        enableColumnFilter: false,
        cell: (cell: any) => {
          return (
            <div>
              <p className="mb-0">
                {moment(cell.row.original?.createdAt).format(
                  "DD-MM-YYYY hh:mm:ss:SSS A"
                )}
              </p>
            </div>
          );
        },
      },
      {
        header: "Result",
        accessorKey: "result",
        enableColumnFilter: false,
      },
      {
        header: "Action",
        accessorKey: "action",
        enableColumnFilter: false,
        cell: (cell: any) => {
          const isResult = cell?.row?.original?.status?.toLowerCase() !== "abandoned"
          return (
            <Button
              className="btn-sm"
              variant="info"
              onClick={() =>
                toggleRollback(
                  cell.row?.original?.fancy_id,
                  cell.row?.original?.match_name,
                  cell.row?.original?.fancy_name,
                  cell.row?.original?.is_abandoned,
                  isResult
                )
              }
            >
              {isResult?"Roll Back":"Abandoned"}
            </Button>
          );
        },
      },
    ],
    [pageNo, limit]
  );

  return (
    <>
      <TableContainer
        columns={columns || []}
        data={matchedRollBack || []}
        isBordered={false}
        customPageSize={limit}
        isPagination={false}
        tableClass="table-centered align-middle table-nowrap table-striped mb-3"
        theadClass="text-muted table-light"
        SearchPlaceholder="Search Products..."
        setLimit={setLimit}
        setPageNo={setPageNo}
      />

      <nav aria-label="Page navigation" className="px-3 d-flex flex-column flex-sm-row align-items-center">
        <TableEntry
          limit={limit}
          handlelimitFilter={(value: number) => setLimit(value)}
        />
        <ListPagination
          listTotal={{ total: totalPage / limit, page: pageNo }}
          handlePageFilter={(value: number) => setPageNo(value)}
        />
      </nav>

      <Modal
        show={showRollback}
        onHide={() => setRollback(false)}
        className="zoomIn"
        scrollable
      >
        <Modal.Header className="modal-title fw-bold d-flex justify-content-between">
          <div className="d-flex align-items-center">Roll Back</div>
          <Button variant="light btn-sm" onClick={() => setRollback(false)}>
            <i className="ri-close-line fs-xl align-middle"></i>
          </Button>
        </Modal.Header>
        <Modal.Body>
          <h4>Are you sure you want to {abdFancy?"Rollback":"Abandoned"} result?</h4>
          <p>
            {matchedName} - {marketName}
          </p>
        </Modal.Body>
        <Modal.Footer className="border-top">
          <Button onClick={handleUpdate}>Update</Button>
          <Button variant="subtle-dark" onClick={() => setRollback(false)}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ResultRollbackFancyTable;
