export function getLastTimeOfDay(dateInput: Date) {
  const date = new Date(dateInput);
  date.setUTCHours(23, 59, 59, 999);
  date.setUTCDate(date.getUTCDate());

  return date.toISOString();
}

export function getStartTimeOfDay(dateInput: Date) {
  const date = new Date(dateInput);
  date.setUTCHours(0, 0, 0, 0);
  date.setUTCDate(date.getUTCDate());

  return date.toISOString();
}

export function currentDateTime() {
  const currentTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
// console.log("Time Zone",currentTimeZone);

const formatter = new Intl.DateTimeFormat('en-US', {
  timeZone: currentTimeZone,
  year: 'numeric',
  month: '2-digit',
  day: '2-digit',
  hour: '2-digit',
  minute: '2-digit',
  second: '2-digit',
});

// console.log("Time Zone Current Date Time", new Date(formatter.format(new Date())));
 return new Date(formatter.format(new Date()))
}


export function getDomainWithoutSubdomain(url: string) {
  try {
    const urlParts = new URL(url).hostname.split('.')
    return urlParts
      .slice(0)
      .slice(-(urlParts.length === 4 ? 3 : 2))
      .join('.')
  } catch (error) {
    console.log('55', error)
    return ''
  }
}