
import Loader from "Common/Loader";
import ToggleSwitch from "Common/ToggleSwitch";

import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { authServices } from "Utils/auth/services";
import { settingServices } from "Utils/setting/services";
import snackbarUtil from "Utils/snackBarUtil";

interface SocketModalProps {
  show: boolean;
  clickHandler: () => void;
}

const SocketModal = ({ show, clickHandler }: SocketModalProps) => {
  const [status, setStatus] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showSocketChange, setSocketChange] = useState<any>(false);

  const getSocketStatus = async () => {
    setIsLoading(true);
    const { response } = await authServices.getSocketStatus();
    setIsLoading(false);
    if (response.status) {
      setStatus(response.is_socket);
      setSocketChange(response.is_socket === 0 ? false : true)
    }
  };

  useEffect(() => {
    show && getSocketStatus();
  }, [show]);

  const updateSocketStatus = async () => {
    setIsLoading(true);
    const { response } = await authServices.updateSocketStatus();
    setIsLoading(false);
    if (response.status) {
      clickHandler();
      getSocketStatus();
      setStatus(response.is_socket);
      setSocketChange(response.is_socket === 0 ? false : true);
      snackbarUtil.success(response.is_socket === 0 ? 'Socket OFF Successfully.' : 'Socket ON Successfully.');
    } else {
      const errorMessage =
        response?.msg || "An error occurred. Please try again.";
      snackbarUtil.error(errorMessage);
    }
  };

  const toggleSocketBtn = () => {
    updateSocketStatus();
  }

  return (
    <Modal
      show={show}
      onHide={clickHandler}
      className="zoomIn"
      scrollable
    >
      <Modal.Header className="modal-title fw-bold d-flex justify-content-between pb-1">
        <div>Socket is {status === 1 ? "ON" : "OFF"}</div>
        <Button variant="light btn-sm" onClick={clickHandler}>
          <i className="ri-close-line fs-xl align-middle"></i>
        </Button>
      </Modal.Header>

      <Modal.Body>
          <div className='d-flex align-items-center'>
              <h5 className='mb-0 me-2'>Switch the Button to OFF socket </h5>
              <ToggleSwitch onChange={toggleSocketBtn} checked={showSocketChange ? true : false} />
          </div>
      </Modal.Body>

      <Modal.Footer className="border-top">
        {/* <Button variant="success" onClick={handleSubmit}>
          Clich here to {status === 1 ? "OFF" : "ON"} socket
        </Button> */}
        <Button variant="subtle-dark" onClick={clickHandler}>Close</Button>

      </Modal.Footer>
      {isLoading && <Loader />}
    </Modal>
  );
};

export default SocketModal;
