export const generateOptions = (list: any[] = [], valueKey: string | number, labelKey: string, type?: string,) => {
    return list.map(item => ({
      value: item[valueKey],
      label: item[labelKey],
      type: type && item[type],
    }));
  };


export const updateSearchPayload = (
    type: SearchPayloadType,
    id: string,
    searchPayload: SportsSearchPayloadData
) => {
    const updatedPayload: SportsSearchPayloadData = { ...searchPayload };

    updatedPayload[type] = id;

    switch (type) {
        case "sport_id":
            delete updatedPayload.series_id;
            delete updatedPayload.match_id;
            delete updatedPayload.market_id;
            delete updatedPayload.fancy_id;
            break;
        case "series_id":
            delete updatedPayload.match_id;
            delete updatedPayload.market_id;
            delete updatedPayload.fancy_id;
            break;
        case "match_id":
            delete updatedPayload.market_id;
            delete updatedPayload.fancy_id;
            break;
        case "market_id":
            delete updatedPayload.fancy_id;
            break;
        default:
            break;
    }

    return updatedPayload;
};

// New clear function to reset a specific key and its lower-level keys
export const clearSearchPayload = (
    type: SearchPayloadType,
    searchPayload: SportsSearchPayloadData
) => {
    const updatedPayload: SportsSearchPayloadData = { ...searchPayload };

    switch (type) {
        case "sport_id":
            delete updatedPayload.sport_id;
            delete updatedPayload.series_id;
            delete updatedPayload.match_id;
            delete updatedPayload.market_id;
            delete updatedPayload.fancy_id;
            break;
        case "series_id":
            delete updatedPayload.series_id;
            delete updatedPayload.match_id;
            delete updatedPayload.market_id;
            delete updatedPayload.fancy_id;
            break;
        case "match_id":
            delete updatedPayload.match_id;
            delete updatedPayload.market_id;
            delete updatedPayload.fancy_id;
            break;
        case "market_id":
            delete updatedPayload.market_id;
            delete updatedPayload.fancy_id;
            break;
        case "fancy_id":
            delete updatedPayload.fancy_id;
            break;
        default:
            break;
    }

    return updatedPayload;
};