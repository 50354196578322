import React from "react";

interface TableEntryProps {
  limit: number;
  minLimit?: number;
  handlelimitFilter: (value: number) => void;
}

const TableEntry = ({ limit, minLimit = 0, handlelimitFilter }: TableEntryProps) => {
  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = parseInt(event.target.value, 10);
    handlelimitFilter(selectedValue);
  };

  const options = [50, 100,150, 200];

  return (
    <div className="text-muted mt-3 d-flex align-items-center">
      Item Per Page
      <select className="mx-2 border" value={limit} onChange={handleSelectChange}>
        {options.map((option) =>
          option >= minLimit ? (
            <option key={option} value={option}>
              {option}
            </option>
          ) : null
        )}
      </select>
    </div>
  );
};

export default TableEntry;