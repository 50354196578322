import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Button, Col, Form, Modal, Row, Table } from 'react-bootstrap';
import { settingServices } from 'Utils/setting/services';

interface ResponseData {
    collection: string,
    count: number
}

interface UpdateDomainModalProps {
    show: any;
    clickHandler: any;
    setNameDomain: Dispatch<SetStateAction<string>>,
    nameDomain: string,
    setUpdateDomain: Dispatch<SetStateAction<boolean>>,
    updateDomainNewOld: (old_domain: string, new_domain: string) => Promise<void>
}

const UpdateDomainModal = ({ show, clickHandler, setNameDomain, nameDomain, setUpdateDomain, updateDomainNewOld }: UpdateDomainModalProps) => {
    const [domainCountList, setDomainCountList] = useState<ResponseData[]>([]);
    const [newDomain, setNewDomain] = useState<string>("");

    const [oldDomainError, setOldDomainError] = useState<string>("");
    const [newDomainError, setNewDomainError] = useState<string>("");

    const getDomainCount = async (domain_name: string) => {
        const { response } = await settingServices.getDomainCount({ domain_name });
        setDomainCountList(response?.data);
    };

    useEffect(() => {
        if (nameDomain) {
            getDomainCount(nameDomain);
        }
    }, [nameDomain]);

    // Validation function to check if domains are not empty
    const validateFields = (): boolean => {
        let isValid = true;

        if (!nameDomain) {
            setOldDomainError("Old Domain cannot be empty");
            isValid = false;
        } else {
            setOldDomainError("");
        }

        if (!newDomain) {
            setNewDomainError("New Domain cannot be empty");
            isValid = false;
        } else {
            setNewDomainError("");
        }

        return isValid;
    };

   
    const handleUpdate = async () => {
        if (validateFields()) {
            await updateDomainNewOld(nameDomain, newDomain);
            setNewDomain("");
        }
    };
    const handleClose =  () => {
            setUpdateDomain(false);
            setNewDomain("");
    };



    return (
        <Modal show={show} onHide={handleClose} className="zoomIn" scrollable>
            <Modal.Header className="modal-title fw-bold d-flex justify-content-between pb-1">
                <div>Update Domain: <span className='text-muted fw-normal'>({nameDomain})</span></div>
                <Button variant="light btn-sm" onClick={handleClose}>
                    <i className="ri-close-line fs-xl align-middle"></i>
                </Button>
            </Modal.Header>
            <Modal.Body className='fs-md'>
                <Row className="align-items-center g-3">
                    <Col lg={12}>
                        <Form.Group>
                            <Form.Label>Old Domain</Form.Label>
                            <Form.Control
                                readOnly
                                type="text"
                                value={nameDomain}
                                onChange={(e) => setNameDomain(e.target.value)}
                                placeholder="Enter Old Domain"
                            />
                            {oldDomainError && <div className="text-danger mt-1">{oldDomainError}</div>}
                        </Form.Group>
                    </Col>
                    <Col lg={12}>
                        <Form.Group>
                            <Form.Label>New Domain</Form.Label>
                            <Form.Control
                                value={newDomain}
                                onChange={(e) => setNewDomain(e.target.value)}
                                type="text"
                                placeholder="Enter New Domain"
                            />
                            {newDomainError && <div className="text-danger mt-1">{newDomainError}</div>}
                        </Form.Group>
                    </Col>
                    <Col lg={12}>
                        <Table className="table align-middle table-nowrap table-striped-columns mb-0">
                            <thead className="table-light">
                                <tr>
                                    <th scope="col">Collection</th>
                                    <th scope="col">Count</th>
                                </tr>
                            </thead>
                            <tbody>
                                {domainCountList?.map((domain) => (
                                    <tr key={domain.collection}>
                                        <td>{domain.collection}</td>
                                        <td>{domain.count}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer className='border-top'>
                <Button onClick={handleUpdate}>Update</Button>
                <Button variant='light' onClick={handleClose}>Cancel</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default UpdateDomainModal;
