import BeatLoader from "react-spinners/BeatLoader";

const Loader = () => {
    return (
        <div className="position-fixed top-0 start-0 h-100 w-100 d-flex align-items-center justify-content-center" style={{zIndex: '99999', background: 'rgba(255,255,255,.75)'}}>
            <BeatLoader />
        </div>
    );
};

export default Loader;