import { Container, Tab, Nav } from "react-bootstrap";
import { useEffect, useState } from "react";
import OpenBets from "./OpenBets";
import SettledBets from "./SettledBets";

interface BetListProps {
  filter: boolean;
  currentTab: string;
}
const BetList = ({ filter, currentTab }: BetListProps) => {
  const [activeTab, setActiveTab] = useState<string>("OpenBets");

  const handleNavClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setActiveTab(
      event.currentTarget.getAttribute("data-rr-ui-event-key") || "OpenBets"
    );
  };

  useEffect(()=> {
    if (currentTab === 'BetLIst') {
      setActiveTab("OpenBets");
    } else {
      setActiveTab("");
    }
  }, [currentTab])
  return (
    <div>
      <Container fluid>
        <Tab.Container defaultActiveKey={"OpenBets"} activeKey={activeTab}>
          <Nav
            as="ul"
            variant="pills"
            className="arrow-navtabs nav-secondary mb-3 flex-nowrap overflow-auto text-nowrap"
            style={{ height: "48px" }}
          >
            <Nav.Item as="li" className="mx-2">
              <Nav.Link eventKey="OpenBets" onClick={handleNavClick}>
                Open Bets{" "}
              </Nav.Link>
            </Nav.Item>
            <Nav.Item as="li" className="mx-2">
              <Nav.Link eventKey="SettledBets" onClick={handleNavClick}>
                Settled Bets{" "}
              </Nav.Link>
            </Nav.Item>
          </Nav>

          <Tab.Content className="text-muted">
            <Tab.Pane eventKey="OpenBets">
              <OpenBets filter={filter} activeTab={activeTab} isReport={true} />
            </Tab.Pane>
            <Tab.Pane eventKey="SettledBets">
              <SettledBets filter={filter} activeTab={activeTab} isReport={true} />
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </Container>
    </div>
  );
};

export default BetList;
