import ToggleSwitch from 'Common/ToggleSwitch';
import { useState, useEffect, Dispatch, SetStateAction } from 'react';
import { Button, Form, Modal, Table } from 'react-bootstrap';

interface SocialMediaItem {
    title: string;
    url: string;
    is_active: boolean;
    image_url: string;
    place: string;
    color: string;
}

interface SocialMediaIcons {
    [key: string]: SocialMediaItem;
}

interface SocialMediaModalProps {
    show: boolean;
    clickHandler:any; 
    socialIcon: any;
    domainName: string;
    getCreateSocilaMedia: (data: any) => Promise<void>
}

const siteData: SocialMediaItem[] = [
    {
        title: "Facebook",
        url: "",
        is_active: false,
        image_url: "ri-facebook-circle-fill",
        place: "https://www.facebook.com/",
        color: "#1877f2"
    },
    {
        title: "Telegram",
        url: "",
        is_active: false,
        image_url: "ri-telegram-fill",
        place: "https://www.telegram.com/",
        color: "#0088cc"
    },
    {
        title: "Instagram",
        url: "",
        is_active: false,
        image_url: "ri-instagram-fill",
        place: "https://www.instagram.com/",
        color: "#de44bd"
    },
    {
        title: "Whatsapp",
        url: "",
        is_active: false,
        image_url: "ri-whatsapp-fill",
        place: "https://www.whatsapp.com/",
        color: "#25d366"
    },
    {
        title: "Gmail",
        url: "",
        is_active: false,
        image_url: "mdi mdi-gmail",
        place: "https://www.gmail.com/",
        color: "#ea4335"
    },
    {
        title: "Twitter",
        url: "",
        is_active: false,
        image_url: "ri-twitter-fill",
        place: "https://www.twitter.com/",
        color: "#1da1f2"
    },
];

const SocialMediaModal = ({ show, clickHandler, socialIcon, domainName, getCreateSocilaMedia }: SocialMediaModalProps) => {
    const [localSocialIcons, setLocalSocialIcons] = useState<SocialMediaIcons>(socialIcon || {});

    useEffect(() => {
        if (show) {
            setLocalSocialIcons(socialIcon || {});
        }
    }, [show, socialIcon]);

    const handleUrlChange = (title: string, value: string) => {
        setLocalSocialIcons((prev) => ({
            ...prev,
            [title]: {
                ...prev[title],
                url: value,
            },
        }));
    };

    const handleToggleChange = (title: string, isActive: boolean) => {
        setLocalSocialIcons((prev) => ({
            ...prev,
            [title]: {
                ...prev[title],
                is_active: isActive,
            },
        }));
    };

    const handleUpdate = () => {
        const payload = {
            title: "social",
            slug: `${domainName}-social`,
            description: JSON.stringify(
                Object.fromEntries(
                    Object.entries(localSocialIcons).map(([key, value]) => [
                        key,
                        {
                            title: value.title || key,
                            url: value.url || "",
                            is_active: value.is_active || false,
                            image_url: `${key}.svg`,
                        },
                    ])
                )
            ),
        };

        getCreateSocilaMedia(payload)
        clickHandler(false);
    };

    return (
        <Modal size='lg' show={show} onHide={() => clickHandler(false)} className="zoomIn" scrollable>
            <Modal.Header className="modal-title fw-bold d-flex justify-content-between pb-1">
                <div>Add Social Media of {domainName}</div>
                <Button variant="light btn-sm" onClick={() => clickHandler(false)}>
                    <i className="ri-close-line fs-xl align-middle"></i>
                </Button>
            </Modal.Header>
            <Modal.Body className='fs-md'>
                <div className="table-responsive">
                    <Table className="table align-middle table-nowrap table-striped-columns mb-0">
                        <thead className="table-light">
                            <tr>
                                <th scope="col">Icon</th>
                                <th scope="col">Domain</th>
                                <th scope="col">Url</th>
                                <th scope="col">Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                siteData.map((item) => (
                                    <tr key={item.title}>
                                        <td>
                                            <i className={`fs-5xl ${item.image_url}`} style={{ color: item.color }}></i>
                                        </td>
                                        <td>{domainName}</td>
                                        <td>
                                            <Form.Control
                                                value={localSocialIcons[item.title]?.url || ""}
                                                type='text'
                                                placeholder={item.place}
                                                onChange={(e) => handleUrlChange(item.title, e.target.value)}
                                            />
                                        </td>
                                        <td>
                                            <ToggleSwitch 
                                                On='On' 
                                                Off='Off' 
                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleToggleChange(item.title, e.target.checked)} 
                                                checked={localSocialIcons[item.title]?.is_active || false} 
                                            />
                                        </td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </Table>
                </div>
            </Modal.Body>
            <Modal.Footer className='border-top'>
                <Button onClick={handleUpdate}>Update</Button>
                <Button variant='light' onClick={() => clickHandler(false)}>Cancel</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default SocialMediaModal;
