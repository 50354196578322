import moment from 'moment';
import { useEffect, useState } from 'react';
import { Button, Modal, ListGroup, Nav, Tab } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

interface MatchListProps {
    show: any;
    clickHandler: any;
    sportDataName: any
}

const sportIcon: Record<string, string> = {
    "Cricket": "bx bx-cricket-ball",
    "Soccer": "bx bx-football",
    "Tennis": "bx bx-tennis-ball",
    "Casino": "mdi mdi-poker-chip",
    "Horse Racing": "mdi mdi-horse",
    "Greyhound Racing": "mdi mdi-horse",
    "Share Market": "ri-bar-chart-2-line",
}

const MatchList = ({ show, clickHandler, sportDataName }: MatchListProps) => {
    const [keys, setKeys] = useState("");
    const nav = useNavigate();

    useEffect(() => {
        // Set the first key as the default active tab when `sportDataName` is available
        if (sportDataName && Object.keys(sportDataName).length > 0) {
            setKeys(Object.keys(sportDataName)[0]);
        }
    }, [sportDataName]);

    const handleKeys = (id: string) => {
        setKeys(id);
    }

    const handleRoute = (matchid: string, sport_id: string, enableFancy: number, matchName: string, isActive: number) => {
        nav(`/match-detail/${matchid}/${sport_id}/${enableFancy}`, { state: matchName });
        localStorage.setItem("isAct", String(isActive));
        clickHandler();
    }

    return (
        <Modal size="lg" show={show} onHide={clickHandler} className="zoomIn" scrollable>
            <Modal.Header className="modal-title fw-bold d-flex justify-content-between pb-0">
                <span>Market List</span>
                <Button variant="light btn-sm" onClick={clickHandler}>
                    <i className="ri-close-line fs-xl align-middle"></i>
                </Button>
            </Modal.Header>
            <Modal.Body className='pt-0'>
                <Tab.Container activeKey={keys}>
                    <Nav as="ul" variant='tabs' className="nav-tabs-custom border-bottom-0 mx-0 flex-nowrap overflow-auto w-100" style={{ height: '45px' }}>
                        {sportDataName && Object.keys(sportDataName).map((items) => (
                            <Nav.Item as="li" key={items} onClick={() => handleKeys(items)}>
                                <Nav.Link className='text-nowrap' eventKey={items}><i className={` ${sportIcon[items] || "mdi mdi-poker-chip"} fs-xl align-middle`}></i> {items}</Nav.Link>
                            </Nav.Item>
                        ))}
                    </Nav>
                    <Tab.Content>
                        <Tab.Pane eventKey={keys}>
                            <ListGroup>
                                {sportDataName && sportDataName[keys]?.map((sport: any, index: number) => (
                                    <ListGroup.Item key={index} as='a' className="list-group-item-action d-flex align-items-center mt-2 ">
                                        <div className='cursor-pointer' onClick={() => handleRoute(sport?.match_id, sport?.sport_id, sport?.enable_fancy, sport?.match_name, sport?.is_active)}>
                                            <div className="text-dark fs-md">{sport?.match_name}</div>
                                            <div className="d-flex align-items-center fs-2xs">
                                                {sport?.inplay && (
                                                    <>
                                                        <span className="text-success">INPLAY</span>
                                                        <span className="mx-2 text-muted">|</span>
                                                    </>
                                                )}
                                                <span className="text-muted">{moment(sport?.match_date).format("DD/MM/YYYY, hh:mm:ss:SSS A")}</span>
                                                <span className="mx-2 text-muted">|</span>
                                                <span className="text-primary">{sport?.series_name}</span>
                                            </div>
                                        </div>
                                        {/* <Button onClick={() => handleRoute(sport?.match_id, sport?.sport_id, sport?.enable_fancy, sport?.match_name, sport?.is_active)} variant='subtle-dark btn-sm' className='ms-auto'>
                                            <i className='fs-md ri-arrow-right-up-line'></i>
                                        </Button> */}
                                    </ListGroup.Item>
                                ))}
                            </ListGroup>
                        </Tab.Pane>
                    </Tab.Content>
                </Tab.Container>
            </Modal.Body>
        </Modal>
    );
};

export default MatchList;
