import { ListPagination } from "Common/ListPagination";
import BetListModal from "Common/Modals/BetListModal";
import BetsModal from "Common/Modals/BetsModal";
import MarketPositionModal from "Common/Modals/MarketPositionModal";
import TableContainer from "Common/TableContainer";
import TableEntry from "Common/TableEntry";
import ToggleSwitch from "Common/ToggleSwitch";
import moment from "moment";
import { Dispatch, FC, SetStateAction, useEffect, useMemo, useState } from "react";
import { Button, Dropdown, Form, Modal, Table } from "react-bootstrap";
import { authServices } from "Utils/auth/services";
import { settingServices } from "Utils/setting/services";
import snackbarUtil from "Utils/snackBarUtil";

interface Props{
  getFancyResult:any
  setPage: Dispatch<SetStateAction<number>>
  setLimit: Dispatch<SetStateAction<number>>
  fancyResult: fancyResultRes[]
  page: number
  limit: number
  listTotal: ProfitLossTotal
  setIsLoading: Dispatch<SetStateAction<boolean>>
}

const FancyResultTable:FC<Props> = ({getFancyResult,setIsLoading, setPage, setLimit, fancyResult, page, limit, listTotal}) => {
  const [showResult, setResult] = useState(false);
  const [showAbonded, setAbonded] = useState(false);
  const [showBook, setBook] = useState(false);
  const [showBet, setBet] = useState(false);
 
  const [fancyBook, setFancyBook] = useState<fancyPositionBook[]>([]);
  const [fancyID, setFancyId] = useState<string>("");
  const [matchedId, setMatchedId] = useState<string>("");
  const [showNews, setNews] = useState(false);
  const [newsValues, setNewsValues] = useState<string>("");
  const [matchName, setMatchName] = useState<string>("");
  const [focusedInput, setFocusedInput] = useState<string | null>(null);

 
  const [resultPayload, setResultPayload] = useState({
    sport_id: "",
    sport_name: "",
    series_id: "",
    series_name: "",
    match_id: "",
    match_name: "",
    fancy_id: "",
    fancy_name: "",
    match_date: "",
    result: "",
  });
  const [resultValue, setResultValue] = useState<{ [key: string]: string }>({});
  const [error, setError] = useState("");
  const [showToggle, setShowToggle] = useState(true)

  const toggleResult = (
    sport_id: string,
    sport_name: string,
    series_id: string,
    series_name: string,
    match_id: string,
    match_name: string,
    fancy_id: string,
    fancy_name: string,
    match_date: string
  ) => {
    setResultPayload((prev) => ({
      ...prev,
      sport_id,
      sport_name,
      series_id,
      series_name,
      match_id,
      match_name,
      fancy_id,
      fancy_name,
      match_date,
    }));
    setResult(true);
  };

  const toggleAbonded = (id: string, name: string) => {
    setFancyId(id);
    setMatchName(name);
    setAbonded(!showAbonded);
  };
  const toggleBook = (id: string,  name: string) => {
    setIsLoading(true)
    getFancyPosition(id);
    setMatchName(name);
    setBook(!showBook);
  };
  const toggleBet = (id: string, matchId: string, name: string) => {
    setFancyId(id);
    setMatchedId(matchId);
    setMatchName(name);
    setBet(!showBet);
  };
  const toggleNews = (id: string, name: string) => {
    setFancyId(id);
    setNews(!showNews);
    setMatchName(name);
  };

  

  const getSessionResult = async (data: any) => {
    const { response } = await settingServices.getSessionResult(data);
    if (response?.status) {
      snackbarUtil.success(response?.msg);
      setIsLoading(false)
      getFancyResult();
      setResult(false);
      setResultValue({});
    } else {
      setIsLoading(false)
      snackbarUtil.error(response?.msg);
    }
  };
  const userDetails = localStorage.getItem("adminDetails");
  const userId = userDetails && JSON.parse(userDetails)?.user_id;


  const getSessionAbd = async (fancy_id: string) => {
    const { response } = await settingServices.getSessionAbd({
      fancy_id,
    });
    if (response?.status) {
      setIsLoading(false)
      snackbarUtil.success(response?.msg);
      getFancyResult()
      setAbonded(false);
    } else {
      setIsLoading(false)
      snackbarUtil.error(response?.msg);
    }
  };

  const handleUpdateAbd = () => {
    setIsLoading(true)
    getSessionAbd(fancyID);
  };



  const handlePageFilter = (page: number, limit: number) => {
    setPage(page);
    setLimit(limit);
    setIsLoading(true)
    getFancyResult({
      page: page,
      limit: limit,
    });
  };

  const handleUpdateResult = (fancy_id: string) => {
    const value = resultValue[fancy_id];
    const updatedPayload = { ...resultPayload, result: value };
    setError("");
    setIsLoading(true)
    getSessionResult(updatedPayload);
    setResult(false);
  };

  const handleInputChange = (fancy_id: string, value: string) => {
    setResultValue((prev) => ({
      ...prev,
      [fancy_id]: value,
    }));
  };

  const getUpdateEventNews = async (data: any) => {
    const { response } = await authServices?.updateEvent({
      event: data?.eventName,
      filter: {
        [data?.sportName]: data?.sportId,
      },
      update: {
        news: newsValues,
      },
    });
    if (response?.status) {
      setIsLoading(false)
      snackbarUtil.success(response?.data);
      setNews(false);
    } else {
      setIsLoading(false)
      snackbarUtil.error(response?.msg);
    }
  };
  const getFancyPosition = async (fancy_id: string) => {
    const { response } = await settingServices?.getFancyPosition({
      fancy_id,
    });
    setIsLoading(false)
    setFancyBook(response?.data?.fancy_position);
  };

  const handleUpdateNews = () => {
    setIsLoading(true)
    const data = {
      eventName: "fancy",
      sportName: "fancy_id",
      sportId: fancyID,
    };
    getUpdateEventNews(data);
  };

  const handleFocus = (fancy_id: string) => {
    setFocusedInput(fancy_id);
  };
  
  const handleBlur = () => {
    setFocusedInput(null);
  };

  const columns = useMemo(
    () => [
      {
        header: "No.",
        accessorKey: "no",
        enableColumnFilter: false,
        cell: (cell: any) => {
          return ((page - 1) * limit) + cell.row.index + 1;
        },
      },
      {
        header: "Match Date",
        accessorKey: "matchDate",
        enableColumnFilter: false,

        cell: (cell: any) => {
          return (
            <div>
              {moment(cell.row?.original?.createdAt).format(
                "DD-MM-YYYY, hh:mm:ss:SSS, A"
              )}
            </div>
          );
        },
      },
      {
        header: "Match Name",
        accessorKey: "match_name",
        enableColumnFilter: false,
      },
      {
        header: "Fancy Name",
        accessorKey: "fancy_name",
        enableColumnFilter: false,
      },
      {
        header: "Status",
        accessorKey: "status",
        enableColumnFilter: false,
        cell: (cell: any) => {
          const fancy_id = cell.row?.original?.fancy_id;
        const value = resultValue[fancy_id] || '';
          return (
            <div className="d-flex align-items-center">
              <Form.Control
                name="result"
                type="number"
                className="form-control-sm me-2"
                style={{ maxWidth: "99px", minWidth: '60px' }}
                id={fancy_id}
                value={resultValue[fancy_id]}
                onFocus={() => handleFocus(fancy_id)} // Set focus state
              onBlur={handleBlur} // Clear focus state
              autoFocus={focusedInput === fancy_id} 
                onChange={(e) => handleInputChange(fancy_id, e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                  }
                }}
              />

              <div className="d-flx">
                <Button disabled={!value.trim()} className="btn-sm me-1" onClick={() =>
                  toggleResult(
                    cell.row?.original?.sport_id,
                    cell.row?.original?.sport_name,
                    cell.row?.original?.series_id,
                    cell.row?.original?.series_name,
                    cell.row?.original?.match_id,
                    cell.row?.original?.match_name,
                    cell.row?.original?.fancy_id,
                    cell.row?.original?.fancy_name,
                    cell.row?.original?.match_date
                  )
                }>Result</Button>
                <Button variant="subtle-danger" className="btn-sm me-1"
                  onClick={() => toggleAbonded(cell.row?.original?.fancy_id, cell.row?.original?.fancy_name)}
                >Abonded</Button>
                <Button variant="info" className="btn-sm me-1"
                  onClick={() => toggleBook(cell.row?.original?.fancy_id, cell.row?.original?.fancy_name)}
                >Book</Button>
                <Button variant="subtle-primary" className="btn-sm me-1" onClick={() =>
                  toggleBet(
                    cell.row?.original?.fancy_id,
                    cell.row?.original?.match_id,
                    cell?.row.original.fancy_name,
                   
                  )
                }>Bet</Button>
                <Button variant="subtle-dark" className="btn-sm me-1" onClick={() => toggleNews(cell.row?.original?.fancy_id, cell?.row.original.fancy_name)}>News</Button>
              </div>
            </div>
          );
        },
      },
    ],
    [page, limit, resultValue, focusedInput]
  );
  return (
    <>
      <TableContainer
        columns={columns || []}
        data={fancyResult || []}
        isBordered={false}
        customPageSize={limit}
        isPagination={false}
        sorting={true}
        tableClass="table-centered align-middle table-nowrap table-striped mb-3"
        theadClass="text-muted table-light"
        SearchPlaceholder="Search Products..."
      />

      <nav aria-label="Page navigation" className="px-3 d-flex flex-column flex-sm-row align-items-center">
        <TableEntry
          limit={limit}
          handlelimitFilter={(value: number) => handlePageFilter(page, value)}
        />
        <ListPagination
          listTotal={listTotal}
          handlePageFilter={(value: number) => handlePageFilter(value, limit)}
        />
      </nav>

      <Modal
        show={showResult}
        onHide={() => setResult(false)}
        className="zoomIn"
        scrollable
      >
        <Modal.Header className="modal-title fw-bold d-flex justify-content-between pb-1">
          <span>Update Result of {resultPayload?.fancy_name}</span>
          <Button variant="light btn-sm" onClick={() => setResult(false)}>
            <i className="ri-close-line fs-xl align-middle"></i>
          </Button>
        </Modal.Header>
        <Modal.Body className="fs-md">
          <p className="fs-lg">
            Are you sure you want to update <strong>Result</strong>?
          </p>
        </Modal.Body>
        <Modal.Footer className="border-top">
          <Button
            className="ms-2"
            onClick={() => handleUpdateResult(resultPayload.fancy_id)}
          >
            Update
          </Button>
          <Button variant="light" onClick={() => setResult(false)}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={showAbonded}
        onHide={() => toggleAbonded("", "")}
        className="zoomIn"
        scrollable
      >
        <Modal.Header className="modal-title fw-bold d-flex justify-content-between pb-1">
          <span>Match Abond of {matchName}</span>
          <Button variant="light btn-sm" onClick={() => toggleAbonded("", "")}>
            <i className="ri-close-line fs-xl align-middle"></i>
          </Button>
        </Modal.Header>
        <Modal.Body className="fs-md">
          <p className="fs-lg">
            Are you sure you want to update <strong>Abonded</strong>?
          </p>
        </Modal.Body>
        <Modal.Footer className="border-top">
          <Button className="ms-2" onClick={handleUpdateAbd}>
            Update
          </Button>
          <Button variant="light" onClick={() => toggleAbonded("", "")}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={showBook}
        onHide={() => setBook(false)}
        className="zoomIn"
        scrollable
      >
        <Modal.Header className="modal-title fw-bold d-flex justify-content-between pb-1">
          <span>Fancy Position of {matchName}  <ToggleSwitch On='S' Off='F' id='fancy_book' onChange={() => setShowToggle(!showToggle)} checked={showToggle} /></span>
          <Button variant="light btn-sm" onClick={() => setBook(false)}>
            <i className="ri-close-line fs-xl align-middle"></i>
          </Button>
        </Modal.Header>
        <Modal.Body className="fs-md">
          <div className="table-responsive">
            <Table className="align-middle table-nowrap mb-0">
              <thead>
                <tr>
                  <th scope="col">Run</th>
                  <th scope="col">P&L</th>
                </tr>
              </thead>
              <tbody>
                {fancyBook?.map((items) => {
                  return (
                    <tr>
                      <td>{items?.key}</td>
                      <td
                        className={
                          Number(items?.value) < 0 || Number(items?.valueFull) < 0
                            ? "text-danger"
                            : "text-secondary"
                        }
                      >
                        {showToggle? items?.value:items?.valueFull}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        </Modal.Body>
      </Modal>
      <BetsModal matchedId={matchedId} marketName={matchName} show={showBet} clickHandler={toggleBet} keys={"fancy_id"} mrkrtId={fancyID} />
      <Modal
        show={showNews}
        onHide={() => toggleNews("", "")}
        className="zoomIn"
        scrollable
      >
        <Modal.Header className="modal-title fw-bold d-flex justify-content-between pb-1">
          <span>Update News of {matchName}</span>
          <Button variant="light btn-sm" onClick={() => toggleNews("", "")}>
            <i className="ri-close-line fs-xl align-middle"></i>
          </Button>
        </Modal.Header>
        <Modal.Body className="fs-md">
          <div className="form-floating">
            <Form.Control
              onChange={(e) => setNewsValues(e.target.value)}
              value={newsValues}
              as="textarea"
              rows={5}
              id="Content"
              style={{ height: "auto" }}
              placeholder="Content"
            />
            <Form.Label htmlFor="Content">Content</Form.Label>
          </div>
        </Modal.Body>
        <Modal.Footer className="border-top">
          <Button variant="light" onClick={() => toggleNews("", "")}>
            Cancel
          </Button>
          <Button className="ms-2" onClick={handleUpdateNews}>
            Update
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default FancyResultTable;
