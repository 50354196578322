import React, { useState, useEffect, Dispatch, SetStateAction } from 'react';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';

interface UploadLogoModalProps {
    show: boolean;
    clickHandler:any;
    updateLogo: (title: string, slug: string, logo: FormData) => Promise<void>;
    nameDomain: string;
    setUploadLogo: Dispatch<SetStateAction<boolean>>
}

const UploadLogoModal = ({ show, clickHandler, updateLogo, nameDomain, setUploadLogo }: UploadLogoModalProps) => {
    const [title, setTitle] = useState('');
    const [slug, setSlug] = useState('');
    const [logo, setLogo] = useState<Blob | null>(null);
    const [errors, setErrors] = useState({
        title: '',
        slug: '',
        logo: ''
    });

    const validateForm = () => {
        const newErrors: any = {};
        if (!title) newErrors.title = 'Title is required';
        if (!slug) newErrors.slug = 'Slug is required';
        if (!logo) newErrors.logo = 'Logo file is required';
        return newErrors;
    };

    const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setTitle(e.target.value);
        if (e.target.value) setErrors((prevErrors) => ({ ...prevErrors, title: '' }));
    };

    const handleSlugChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSlug(e.target.value);
        if (e.target.value) setErrors((prevErrors) => ({ ...prevErrors, slug: '' }));
    };

    const handleLogoChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files?.length) {
            const file = e.target.files[0];
            if (file.type.startsWith("image/")) {
                setLogo(file);
                setErrors((prevErrors) => ({ ...prevErrors, logo: '' }));
            } else {
                setErrors((prevErrors) => ({ ...prevErrors, logo: 'Selected file must be an image' }));
                setLogo(null); 
            }
        }
    };

    const handleUpdate = async () => {
        const validationErrors = validateForm();
        if (Object.keys(validationErrors).length === 0) {
            try {
                const formData = new FormData();
                formData.append('logo', logo!);
                await updateLogo(title, slug, formData);
                resetForm();
            } catch (error) {
                setErrors((prev) => ({ ...prev, logo: 'Error uploading logo. Please try again.' }));
            }
        } else {
            setErrors(validationErrors);
        }
    };

    const resetForm = () => {
        setTitle('');
        setSlug('');
        setLogo(null);
        setErrors({ title: '', slug: '', logo: '' });
    };

    useEffect(() => {
        if (!show) {
            resetForm();
        }
    }, [show]);

    return (
        <Modal show={show} onHide={()=>setUploadLogo(false)} className="zoomIn" scrollable>
            <Modal.Header className="modal-title fw-bold d-flex justify-content-between pb-1">
                <div>Upload Logo of {nameDomain}</div>
                <Button variant="light btn-sm" onClick={()=>setUploadLogo(false)}>
                    <i className="ri-close-line fs-xl align-middle"></i>
                </Button>
            </Modal.Header>
            <Modal.Body className='fs-md'>
                <Row className="align-items-center g-3">
                    <Col lg={12}>
                        <Form.Group>
                            <Form.Label>Title</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder='Enter Title'
                                value={title}
                                onChange={handleTitleChange}
                                isInvalid={!!errors.title}
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors.title}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col lg={12}>
                        <Form.Group>
                            <Form.Label>Slug</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder='like as user.domain.com'
                                value={slug}
                                onChange={handleSlugChange}
                                isInvalid={!!errors.slug}
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors.slug}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col lg={12}>
                        <Form.Group>
                            <Form.Label>Logo</Form.Label>
                            <Form.Control
                                accept="image/*"
                                type="file"
                                onChange={handleLogoChange}
                                isInvalid={!!errors.logo}
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors.logo}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer className='border-top'>
                <Button onClick={handleUpdate}>Update</Button>
                <Button variant='light' onClick={()=>setUploadLogo(false)}>Cancel</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default UploadLogoModal;
