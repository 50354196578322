import BreadCrumb from "Common/BreadCrumb";
import FraudBetTable from "Common/Tables/FraudBetTable";
import { useEffect, useMemo, useState } from "react";
import { Container, Button, Card, Form, Row, Col, Tab, Nav } from "react-bootstrap";
import Select from "react-select";
import Flatpickr from "react-flatpickr";
import { useLocation } from "react-router-dom";
import { authServices } from "Utils/auth/services";
import { currentDateTime, getStartTimeOfDay } from "Utils/datefilter";
import OpenBetsTable from "Common/Tables/OpenBetsTable";
import {
  clearSearchPayload,
  generateOptions,
  updateSearchPayload,
} from "Utils/searchFilter";
import OpenBets from "pages/Reports/OpenBets";
import FraudBetComponent from "Common/FraudBetComponent";

const FraudBet = () => {
  let location = useLocation();
  
  const [activeTab, setActiveTab] = useState<string>("OpenBets");
  const [showFilter, setFilter] = useState(true);
  const [fraudBetType, setFraudBetType] = useState<number>(0);

  const [page, setPage] = useState<number>(1);
 

  const handleNavClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setActiveTab(
      event.currentTarget.getAttribute("data-rr-ui-event-key") || "OpenBets"
    );
  };

  const getHeading = () => {
    switch (location.pathname) {
      case "/fraud-bet":
        return "Fraud Bet";
      case "/delete-bet":
        return "Delete Bet";
      case "/void-bet":
        return "Void Bet";
      default:
        return "Fraud Bet";
    }
  };

  const handleBetType = (value: number) => {
    setFraudBetType(value);
    setPage(1);
   
  };

  return (
    <div className="page-content">
      <Container fluid>
        <div className="position-relative mb-2 d-flex justify-content-between align-items-center">
          <BreadCrumb title={activeTab == "FraudBets" ? "Fraud Bets" : "Active Bets"} pageTitle="Dashboard" back />
          <div>
          <div>
            {activeTab == "FraudBets" ? (
              <div className="d-flex">
                <div className="form-check me-3">
                  <Form.Check
                    type="radio"
                    id="all"
                    name="fraudBet"
                    checked={fraudBetType === 0}
                    onChange={() => handleBetType(0)}
                  />
                  <Form.Label className="form-check-label" htmlFor="all">
                    All
                  </Form.Label>
                </div>
                <div className="form-check me-3">
                  <Form.Check
                    type="radio"
                    id="sameIP"
                    name="fraudBet"
                    checked={fraudBetType === 1}
                    onChange={() => handleBetType(1)}
                  />
                  <Form.Label className="form-check-label" htmlFor="sameIP">
                    Same IP
                  </Form.Label>
                </div>
                <div className="form-check">
                  <Form.Check
                    type="radio"
                    id="trading"
                    name="fraudBet"
                    checked={fraudBetType === 2}
                    onChange={() => handleBetType(2)}
                  />
                  <Form.Label className="form-check-label" htmlFor="trading">
                    Trading
                  </Form.Label>
                </div>
              </div>
            ) : (
              <Button
                variant="subtle-dark"
                onClick={() => setFilter(!showFilter)}
              >
                <i className="fs-lg align-middle ri-filter-3-line"></i> Filter
              </Button>
            )}
          </div>
          </div>
        </div>

        
        <Card>
          <Card.Body>
          <Tab.Container defaultActiveKey={activeTab || "OpenBets"}
          activeKey={activeTab}>
              <Nav
                as="ul"
                variant="tabs"
                className="nav-tabs-custom nav-primary nav-justified mb-3"
              >
                <Nav.Item as="li">
                  <Nav.Link eventKey="OpenBets" onClick={handleNavClick}>Active Bets</Nav.Link>
                </Nav.Item>
                <Nav.Item as="li">
                  <Nav.Link eventKey="FraudBets" onClick={handleNavClick}>Fraud Bets</Nav.Link>
                </Nav.Item>
              </Nav>
              <Tab.Content className="text-muted">
                <Tab.Pane eventKey="OpenBets">
                  <OpenBets
                    filter={showFilter}
                    activeTab={activeTab}
                    isReport={false}
                  />
                </Tab.Pane>
                <Tab.Pane eventKey="FraudBets">
                  <FraudBetComponent fraudBetType={fraudBetType} activeTab={activeTab} />
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </Card.Body>
        </Card>
      </Container>
    </div>
  );
};

export default FraudBet;
