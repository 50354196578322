import { ListPagination } from "Common/ListPagination";
import TableContainer from "Common/TableContainer";
import TableEntry from "Common/TableEntry";
import { useMemo, useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import snackbarUtil from "Utils/snackBarUtil";

interface Props {
  transactionData: TransactionData[];
  page: number;
  limit: number;
  listTotal: ProfitLossTotal;
  handlePageFilter: (value: number, limit: number) => void;
}

const TransactionTable = ({
    transactionData,
  page,
  limit,
  listTotal,
  handlePageFilter,
}: Props) => {
  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    setCopied(true);
    snackbarUtil.success("Copy Successfull");
    setTimeout(() => {
      setCopied(false);
    }, 3000);
  };

  function formatDateTime(dateString: string): string {
    const date = new Date(dateString);
    const options: Intl.DateTimeFormatOptions = {
      month: "short",
      day: "numeric",
      year: "numeric",
      hour: "numeric",
      minute: "2-digit",
      second: "2-digit",
      hour12: true,
    };

    return new Intl.DateTimeFormat("en-US", options).format(date);
  }

  const transaction = useMemo(() => {
    if (!transactionData || !Array.isArray(transactionData)) {
      return [];
    }
    return transactionData.map((items, index: number) => ({
      id: items?._id,
      no: (page - 1) * limit + index + 1,
      parentName: items?.parent_user_name,
      username: items?.user_name,
      type: items?.statement_type,
      amount: items?.amount,
      create: formatDateTime(items?.created_at),
      update: formatDateTime(items?.updatedAt),
      remark: items?.remark,
      status: items?.status,
    }));
  }, [transactionData, page, limit]);

  const columns = useMemo(
    () => [
      {
        header: "No.",
        accessorKey: "no",
        enableColumnFilter: false,
      },
      {
        header: "Username",
        accessorKey: "username",
        enableColumnFilter: false,
        cell: (cell: any) => {
          return (
            <>
              <span>{cell?.row?.original?.username}</span>
              <CopyToClipboard
                text={cell?.row?.original?.username}
                onCopy={handleCopy}
              >
                <i className="ms-1 align-middle ri-file-copy-line cursor-pointer"></i>
              </CopyToClipboard>
            </>
          );
        },
      },
      {
        header: "Type",
        accessorKey: "type",
        enableColumnFilter: false,
      },
      {
        header: "Parent Name",
        accessorKey: "parentName",
        enableColumnFilter: false,
      },
      {
        header: "Amount",
        accessorKey: "amount",
        enableColumnFilter: false,
        cell: (cell: any) => {
            return (
              <span
                className={`text-${
                  cell?.row?.original?.type === 'WITHDRAW_REQUEST'
                    ? "danger"
                    : "secondary"
                }`}
              >
                {cell?.row?.original?.amount}
              </span>
            );
          },
      },
      {
        header: "Request Time",
        accessorKey: "create",
        enableColumnFilter: false,
      },
      {
        header: "Updated Time",
        accessorKey: "update",
        enableColumnFilter: false,
      },
      {
        header: "Remark",
        accessorKey: "remark",
        enableColumnFilter: false,
      },
      {
        header: "Status",
        accessorKey: "status",
        enableColumnFilter: false,
      },
    ],
    []
  );

  return (
    <>
      <TableContainer
        columns={columns || []}
        data={transaction || []}
        isBordered={false}
        customPageSize={limit}
        isPagination={false}
        tableClass="table-centered align-middle table-nowrap table-striped mb-3"
        theadClass="text-muted table-light"
        SearchPlaceholder="Search Products..."
      />

      <nav aria-label="Page navigation" className="px-3 d-flex">
        <TableEntry
          limit={limit}
          handlelimitFilter={(value: number) => handlePageFilter(page, value)}
        />
        <ListPagination
          listTotal={listTotal}
          handlePageFilter={(value: number) => handlePageFilter(value, limit)}
        />
      </nav>
    </>
  );
};

export default TransactionTable;
