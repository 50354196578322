import React, { Dispatch, SetStateAction } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Col, Row } from 'react-bootstrap';

interface BreadCrumbProps {
    title: string;
    pageTitle: string;
    back?: boolean;
    userDataBreadCrumb?: any;
    agentStatus?: boolean
    setUserId?: Dispatch<SetStateAction<string>>;
}

const BreadCrumb = ({ title, pageTitle, back, userDataBreadCrumb,agentStatus, setUserId }: BreadCrumbProps) => {
    const navigate = useNavigate();
    const user_Detail = localStorage.getItem("adminDetails");
    let user_type_id = JSON.parse(user_Detail || '')?.user_type_id;
    const breadcrumbs =agentStatus? userDataBreadCrumb?.breadcrumbs:userDataBreadCrumb?.parent_level_ids;
    const handleBack = () => {
        if ( breadcrumbs?.length > 1) {
            const secondLastItem = breadcrumbs[breadcrumbs?.length - 1];
            console.log(breadcrumbs[breadcrumbs?.length -1], "secondLastItemsecondLastItem")

            if (secondLastItem && setUserId) {
                setUserId(secondLastItem?.user_id);
            }
        } else if (breadcrumbs?.length === 1) {
            const secondLastItem = breadcrumbs[0];

            if (secondLastItem && setUserId) {
                setUserId(secondLastItem?.user_id);
            }
        } else {
            navigate(-1);
        }
    };

    const handleNavigate = (user_id: string) => {

       
        if (user_id && setUserId) {
            setUserId(user_id);
        }
    };

    return (
        <React.Fragment>
            <Row>
                <Col xs={12}>
                    <div className="page-title-box d-sm-flex align-items-center">
                        <div className='d-flex align-items-center'>
                            {back ? (
                                <Button variant='sm' className='py-0' onClick={handleBack}>
                                    <i className='fs-xl align-middle ri-arrow-left-line'></i>
                                </Button>
                            ) : null}
                            <h4 className="mb-0">{title}</h4>
                        </div>

                        <div className="page-title-right d-none d-sm-block ms-3">
                            {breadcrumbs && (
                                <ol className="breadcrumb m-0">
                                    {breadcrumbs?.map((items: any, index: number) => {
                                        // const isLastItem = index === userDataBreadCrumb.length - 1;
                                        const handleClick = () => {
                                            if (user_type_id === 0) {
                                                handleNavigate(items?.user_id)
                                            } else if (user_type_id >= items?.user_type_id && items?.user_type_id != 0) {
                                                handleNavigate(items?.user_id)
                                            }
                                        }

                                        return (
                                            <li
                                                key={index}
                                                className={`breadcrumb-item }`}
                                                aria-current={"page"}
                                            >

                                                <Link to="#" onClick={() => handleClick()}>
                                                    {items.user_name}
                                                </Link>

                                            </li>
                                        );

                                    })}
                                    <li className='breadcrumb-item'>{userDataBreadCrumb?.user_name}</li>
                                </ol>
                            )}
                        </div>
                    </div>
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default BreadCrumb;
