import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import BreadCrumb from 'Common/BreadCrumb';
import SeriesByData from 'Common/SeriesByData';


const SeriesData = () => {
    

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    {/* <BreadCrumb title="" pageTitle="Steex" /> */}
                    <SeriesByData />
                    
                </Container>
            </div>
            
        </React.Fragment>
    );
};

export default SeriesData;
