import ToggleSwitch from 'Common/ToggleSwitch';
import { Dispatch, SetStateAction, useState } from 'react';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';

interface PopupImageModalProps {
	show: any;
	clickHandler: any;
    setNameDomian: Dispatch<SetStateAction<string>>,
    nameDomain:string,
    setHostName: Dispatch<SetStateAction<string>>,
    hostName:string,
    updateUpUp: (data: any) => Promise<void>
}

const PopupImageModal = ({show, clickHandler, setNameDomian, nameDomain, setHostName, hostName, updateUpUp} : PopupImageModalProps) => {
    const [showToggle, setToggle] = useState(false);
    const [selectedImage, setSelectedImage] = useState<File | null>(null);

    const [imageError, setImageError] = useState<string>("");
    const [toggleError, setToggleError] = useState<string>("");

    const toggleSwitch = () => {
        setToggle(!showToggle);
        if (showToggle) setToggleError(""); 
    };

  
    const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0]; 
        if (file) {
            setSelectedImage(file);      
            setImageError("");          
        }
    };


    const validateFields = (): boolean => {
        let isValid = true;

       
        if (!selectedImage) {
            setImageError("Please select an image.");
            isValid = false;
        }

        return isValid;
    };

  
    const handleUpdate = async () => {
        if (!validateFields()) {
            return; 
        }

       
        const formData = new FormData();
        formData.append('website', nameDomain);               
        formData.append('content_type', 'Popup');                   
        formData.append('is_active', showToggle ? 'true' : 'false'); 
        formData.append('image', selectedImage as Blob);            

        await updateUpUp(formData);
    };

    return (
        <Modal size='lg' show={show} onHide={clickHandler} className="zoomIn" scrollable>
            <Modal.Header className="modal-title fw-bold d-flex justify-content-between pb-1">
                <div>Update Popup Image of {hostName}</div>
                <Button variant="light btn-sm" onClick={clickHandler}>
                    <i className="ri-close-line fs-xl align-middle"></i>
                </Button>
            </Modal.Header>
            <Modal.Body className='fs-md'>
                <Row className="align-items-center g-3">
                    <Col lg={6}>
                        <Form.Group>
                            <Form.Label>Website Name</Form.Label>
                            <Form.Control value={hostName} readOnly type="text" />
                        </Form.Group>
                    </Col>
                    <Col lg={6}>
                        <Form.Group>
                            <Form.Label>Site Title Data</Form.Label>
                            <Form.Control value={nameDomain} readOnly type="text" />
                        </Form.Group>
                    </Col>
                    <Col lg={6}>
                        <Form.Group>
                            <Form.Label>Image</Form.Label>
                            <Form.Control accept="image/*"  name="image" type="file" onChange={handleImageChange} />
                            {imageError && <div className="text-danger mt-1">{imageError}</div>} 
                        </Form.Group>
                    </Col>
                    <Col lg={6}>
                        <Form.Group className='d-flex align-items-center pt-3'>
                            <Form.Label className='mb-0 me-3'>Status</Form.Label>
                            <ToggleSwitch On='On' Off='Off' onChange={toggleSwitch} checked={showToggle} />
                           
                        </Form.Group>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer className='border-top'>
                <Button onClick={handleUpdate}>Update</Button>
                <Button variant='light' onClick={clickHandler}>Cancel</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default PopupImageModal;
